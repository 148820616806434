import { Canvas } from "@react-three/fiber"
import { memo, Suspense, useEffect, useState } from "react"
import { BuildingInterface, characterInterface, LightInterface, npcInterface } from "./enum/SpaceInterface"
import BuildingModel from "./models/BuildingModel"
import { Environment, OrbitControls, useProgress } from "@react-three/drei"
import * as THREE from "three";
import CharacterModelGLTF from "./models/CharacterModelGLTF"
import CharacterModelFBX from "./models/CharacterModelFBX"
import { getAssetSetFolder, removeExtension } from "../utilities/Functions"
import { imageSquidexInterface, imageSquidexInterfaces } from "./enum/SquidexInterfaces"
import { animationsNameDef, itemsFolderId, peopleId } from "../constants"
import SquidexService from "../service/SquidexService"
import _, { capitalize } from "lodash"
import { Euler, Vector3 } from "three"
import { EntitiesListInterface, InstanceInterface } from "./enum/InstancesInterfaces"

interface CanvasSpaceViewIn {
    building:BuildingInterface,
    // npcs?:npcInterface[]
    npcs?:characterInterface[],
    instances?:EntitiesListInterface,
} 

const CanvasSpaceView = ({building, npcs=[], instances}:CanvasSpaceViewIn)=>{
    let url = (`https://space.fromlabs.com${building.assetPath}`.substring(`https://space.fromlabs.com${building.assetPath}`.lastIndexOf('/')+1).toLowerCase().replaceAll(/_|%20/g,"-"))
    // const squdiexsService = new SquidexService();
    // const [npcsDatas, setNpcsDatas] = useState<characterInterface[]>([])
    // const [ isLoading, setIsLoading] = useState<boolean>(true)

    const setLights = (lights:LightInterface[]) =>{
        return lights.map((light:LightInterface)=>{
            if(light.type == "AmbientLight")
                return(<ambientLight color={light.color} intensity={light.intensity}/>)
            if(light.type == "HemisphereLight"){
                const vector = new THREE.Vector3(light.position.x, light.position.y, light.position.z)
                return(<hemisphereLight color={light.color} groundColor={light.subColor || "#000000"} intensity={light.intensity} position={vector}/>)
            }
            if(light.type == 'DirectionalLight'){
                const vector = new THREE.Vector3(light.position.x, light.position.y, light.position.z)
                const shadow = new THREE.DirectionalLight(light.color, light.intensity)
                shadow.shadow.mapSize = new THREE.Vector2(light.mapSize, light.mapSize)
                shadow.shadow.bias = light.bias;
                return (<directionalLight
                    castShadow={light.castShadow || false} 
                    color={light.color} 
                    position={vector} 
                    intensity={light.intensity} 
                    shadow={shadow.shadow}/>)
            }
            return (<></>)
        })
    }

    // const displayInstances = (instance:InstanceInterface) =>{
    //     // if(instance.squidexTemplate!.errorCode!){
    //     //     // setItemChanged(instance)
    //     //     return(<></>)
    //     // }
    //     if(instance.squidexTemplate!.asset?.iv?.length>0){
    //         return (
    //             <BuildingModel
    //             modelFilePath={instance.squidexTemplate!.asset.iv[0].url}
    //             position={instance.position}
    //             modelScale={instance.worldScale}
    //             rotation={instance.rotation}
    //             />
    //             // <CharacterModelGLTF
    //             //     scale={instance.worldScale}
    //             //     position={instance.position}
    //             //     rotation={instance.rotation}
    //             //     modelFilePath={instance.squidexTemplate!.asset.iv[0].url}
    //             // />
    //         )
    //     }
    //     // setItemChanged(instance)
    //     return (<></>)
    //     // return (
    //     //     <CharacterModelGLTF
    //     //     scale={building.characterScale || instance.scale}
    //     //         position={instance.position}
    //     //         rotation={instance.rotation}
    //     //         modelFilePath={instance.template.assetPath}
    //     //         />
    //     // )
    // }

    const displayCharacter = (npcData:characterInterface)=>{
        let position = new Vector3();
        position.copy(npcData.position);
        let rotation = new Euler()
        rotation.set(npcData.rotation.x, npcData.rotation.y, npcData.rotation.z, "XYZ");
        if(npcData.name.includes('.fbx')){
            return(
                <CharacterModelFBX
                scale={building.characterScale || npcData.scale}
                position={position}
                rotation={rotation}
                textureFilePath={npcData.texture?._links?"https://content-sea.fromlabs.com"+npcData.texture?._links?.content?.href!:""}
                animationFilePath={npcData.anim?._links?"https://content-sea.fromlabs.com"+npcData.anim?._links?.content?.href!:""}
                modelFilePath={"https://content-sea.fromlabs.com"+npcData.modelPath?._links?.content?.href!}
                />
            )
        }
        if(npcData.name.includes('.gltf')){
            return (
                <CharacterModelGLTF
                scale={building.characterScale || npcData.scale}
                position={position}
                rotation={rotation}
                modelFilePath={"https://content-sea.fromlabs.com"+npcData.modelPath?._links?.content?.href!}
                />
            )
        }
    }

    return(
        <div className={`w-100 h-100 relative`} style={{backgroundColor:'#ccc', minHeight:500}}>
            <Canvas dpr={[1, 2]}  camera={{position: [0, 3, 12], fov: 45}} style={{minHeight:500}}>
                {building.lights?.length> 0 && setLights(building.lights)}
                <Suspense fallback={null}>
                    <BuildingModel
                        modelFilePath={'https://content-sea.fromlabs.com/api/assets/virtualspace/'+url}
                        modelScale={building.scale}
                        instances={instances!.list || []}
                        // textureFilePath={}
                    />
                    {npcs?.length>0 && npcs.map((npc)=>{return displayCharacter(npc)})}
                    <Suspense fallback={null}>
                        {/* {instances?.list && instances.list.length > 0 && instances.list.map(instance=>displayInstances(instance))} */}
                    </Suspense>
                    <OrbitControls />
                    <Environment preset="sunset" background={true} />
                </Suspense>
                {/* <gridHelper /> */}
            </Canvas>
        </div>
    )
}

export default memo(CanvasSpaceView)