import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { assetsSetOptions, gender, SQUIDEX_URL } from "../../constants";
import CustomerService from "../../service/CustomerService";
import SquidexService from "../../service/SquidexService";
import { getAssetSetFolder, instanceOfImageSquidexInterface, removeExtension } from "../../utilities/Functions";
import CanvasCharacterView from "../CanvasCharacterView";
import { folderInterFace, imageSquidexInterface, imageSquidexInterfaces, ObjImgageSquidex } from "../enum/SquidexInterfaces";
import { UserRealmForm, userSkinInfo } from "../enum/userForm";

import styles from './userStyles.module.scss';

type editUserProps = {
    data: UserRealmForm,
}

export default function EditUserRealm ({data}:editUserProps) {

    const [userInfo, setUserInfo] = useState<userSkinInfo>({
        gender:gender[0].value,
        name:"",
        assetSet: data?.assetSet || localStorage.getItem("assetSet") || "",
        assetPath: data?.assetPath || "",
        texturePath: data?.texturePath || "",
    })
    const toast = useRef<Toast>(null);
    const customersService = new CustomerService();
    const squdiexsService = new SquidexService();
    // const [getUserError, setGetUserError] = useState();
    // const [ assetPaths, setAssetPaths ] = useState<Array<folderInterFace>>();
    const [models, setModels] = useState<imageSquidexInterface>()
    const [selectedAssetPreview, setSelectedAssetPreview] = useState<imageSquidexInterface>();
    const [assetPreviews, setAssetPreviews] = useState<Array<imageSquidexInterface>>([])
    const [assetPreviewsFilter, setAssetPreviewsFilter] = useState<Array<imageSquidexInterface>>([])
    const [selectedTexturePreview, setSelectedTexturePreview] = useState<imageSquidexInterface>();
    const [texturesPreviews, setTexturesPreviews] = useState<Array<imageSquidexInterface>>([])
    const [texturesPreviewsFilter, setTexturesPreviewsFilter] = useState<Array<ObjImgageSquidex>>([])
    // const [selected, setSelected] = useState()


    useEffect(()=>{
        (async()=>{
            let assetSetFolder = await getAssetSetFolder()
            let assetsFoldersRes:imageSquidexInterfaces={};
            await squdiexsService.getAssetsFolders(assetSetFolder.id).then(folders=>{
            // console.log(folders)
            // let asstesRes = assets?.items?.map((asset:imageSquidexInterface)=>{
                //                 return asset
                //             })
                //             return asstesRes;
                assetsFoldersRes = {
                    previews: _.find(folders?.items, (item)=>{
                        return item.folderName == "previews"
                    }),
                    textures:_.find(folders?.items, (item)=>{
                        return item.folderName == "textures"
                    })
                }
                // return 
            })
            let assetsFinal:Array<imageSquidexInterface> = assetsFoldersRes?.previews?.id ? await squdiexsService.getAssetsByFolderId(assetsFoldersRes.previews.id, "type eq 'Image'").then(assets=>{
                return assets?.items;
            }): [{
                id: "",
                parentId: "",
                fileName: "",
                slug: "",
            }]
        
            setAssetPreviews(assetsFinal)

            let textureRes = assetsFoldersRes?.textures?.id ? await squdiexsService.getAssetsByFolderId(assetsFoldersRes.textures.id, "type eq 'Image'").then(assets=>{
                return assets?.items;
            }): [{
                id: "",
                parentId: "",
                fileName: "",
                slug: "",
            }]
            setTexturesPreviews(textureRes)
            textureRes.length>0 && setSelectedTexturePreview(textureRes[0])
        })()
    },[])

    useEffect(()=>{
        // (async()=>{
            // console.log(userInfo.gender)
        let assetsFilter:Array<imageSquidexInterface> = [] as any
        (userInfo?.gender && assetPreviews?.length > 0) && assetPreviews.map((item:imageSquidexInterface)=>{
            if(_.find(item.fileName.toLowerCase().split("_"),i=>i==(userInfo.gender || gender[0].value)) ){
                assetsFilter.push(item)
            }
        })
        setAssetPreviewsFilter(assetsFilter!)
        setSelectedAssetPreview(assetsFilter[0])
        // })()
    },[userInfo.gender, assetPreviews])

    useEffect(()=>{
        let textureFilter:Array<ObjImgageSquidex> = [] as any
        (selectedAssetPreview && texturesPreviews?.length > 0) && texturesPreviews.map((item:imageSquidexInterface)=>{
            
            let nameArr = item.fileName.toLowerCase().split("_")
            if(_.find(nameArr,i=>selectedAssetPreview.fileName.toLowerCase().split("_").includes(i)) ){
                textureFilter.push({ value:item, label: item.fileName})
            }

        })
        setTexturesPreviewsFilter(textureFilter)
    },[texturesPreviews, selectedAssetPreview])

    useEffect(()=>{
        (async()=>{
            let assetSetFolder = await getAssetSetFolder();
            if(selectedAssetPreview){
                let fileName = removeExtension(selectedAssetPreview.fileName)
                // console.log(selectedAssetPreview)
                let assetsFinal:imageSquidexInterface = assetSetFolder?.id ? await squdiexsService.getAssetsByFolderId(assetSetFolder.id).then(res=>{
                
                    return res?.items?.length>0 && _.find(res.items, i=>i.fileName.includes(fileName))
                }): {
                    id: "",
                    parentId: "",
                    fileName: "",
                    slug: "",
                }
                setModels(assetsFinal)
            }
        })()
    },[selectedAssetPreview])

    const setValue = (key:string, value:any) =>{
        if(typeof value == "object" && instanceOfImageSquidexInterface(value)){
            if(key == "texture"){
                setSelectedTexturePreview(value)
                setUserInfo({...userInfo, "texturePath":`/assets/people/${localStorage.getItem("assetSet")}/${value.fileName}`})
            }else if(key == "asset"){
                setSelectedAssetPreview(value)
                setUserInfo({...userInfo, "assetPath":`/assets/people/${localStorage.getItem("assetSet")}/${value.fileName}`})
            }
        }else{
            setUserInfo({...userInfo, [key]:value})
        }
    }

    // const selectedModel =(item:imageSquidexInterface) => {
    //     console.log(item)
    // }
    
    return(<>
        <div className={`card flex flex-wrap justify-content-between px-5 pb-5 ${styles.createSpaceForm}`}>
            <form>
                <div className={`m-2 flex flex-column ${styles.formField}`}>
                    <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                        <label className="p-label block">Gender</label>
                        <div className="p-inputgroup">
                            <Dropdown required name="gender" options={gender} value={userInfo.gender} onChange={(e:{value:any})=>{{
                                setValue("gender", e.value)
                            }}}/>
                        </div>
                    </div>
                    {(userInfo?.assetPath || userInfo?.gender )&& assetPreviewsFilter?.length > 0 && 
                    <div className={`flex flex-wrap`}>
                        <div className={`flex-1 grid justify-content-center align-items-center`}>
                            <div className={`col-12 md:col-10 grid`}>
                                {assetPreviewsFilter.map(item=>{
                                    return(
                                        item?._links?.content && 
                                        <div
                                        className={`col-12 md:col-3 p-0 m-0`} 
                                        style={{
                                            position:'relative', 
                                            borderColor:'#404040', 
                                            borderWidth: selectedAssetPreview == item ? 2 : 0, 
                                            borderStyle:'solid', 
                                            overflow:"hidden",
                                        }}
                                        onClick={()=>{
                                            // setSelectedAssetPreview(item)
                                            setValue("asset",item)
                                            // selectedModel(item)
                                        }}>
                                            {selectedAssetPreview == item && <div
                                            style={{
                                                position:"absolute",
                                                width:'100%',
                                                height:'100%',
                                                top:0,
                                                left:0,
                                                zIndex:1,
                                                backgroundColor:'black',
                                                opacity:0.4,
                                            }}></div>}
                                            <Image src={`${SQUIDEX_URL}${item._links.content.href}`}/>
                                        {/* {`${SQUIDEX_URL}${item._links.content.href}`} */}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={`col-12 md:col-2 field align-items-center justify-content-center `}>
                                <label className="p-label block">Variant: </label>
                                <div className="p-inputgroup">
                                    <Dropdown required name="variants" options={texturesPreviewsFilter} value={selectedTexturePreview} onChange={(e:{value:any})=>{{
                                        // setUserInfo({...userInfo, var:e.value})
                                        setValue("texture",e.value)
                                    }}}/>
                                </div>
                            </div>
                        </div>
                        <div className={`flex-1`}>
                            {models && selectedTexturePreview && 
                            <CanvasCharacterView 
                            imageFile={models!} 
                            texturePath={selectedTexturePreview?._links?.content?.href!}
                            gender={userInfo.gender=="female"?userInfo.gender:""}
                            />}
                        </div>
                    </div>}
                    {/* <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                        <label className="p-label block">Texture Path</label>
                        <div className="p-inputgroup">
                            <InputText id="texturePath" type={'text'} value={userInfo.texturePath} name="texturePath" onChange={(e) => {
                                setUserInfo({...userInfo, texturePath: e.target.value})
                            }}/>
                        </div>
                    </div> */}
                </div>
            </form>
        </div>
    </>)

}