
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setTimeout } from 'timers';
import CustomerService from '../../service/CustomerService';
import { checkStrings } from '../../utilities/Functions';
import { errorsUserInterface, UserForm, UserRealmForm } from '../enum/userForm';
import UsersManagementForm from '../form/UserManagementForm';
import styles from './userStyles.module.scss';

type editUserProps = {
    data: UserRealmForm,
    userId:string,
    realmId:string,
}

const EditUser = ({data, realmId, userId}:editUserProps) =>{
    const [userInfo, setUserInfo] = useState<UserRealmForm>(data)
    const toast = useRef<Toast>(null);
    const customersService = new CustomerService();
    const [getUserError, setGetUserError] = useState();
    const [errors, setErrors] = useState<errorsUserInterface>({
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        emailVerified: "",
        password:"",
        repassword:"",
        role:"",
        avatar:"",
      })
    useEffect(()=>{
        setUserInfo(data)
    },[data])

    const handleSubmit = (inputData: UserForm)=>{
        let error = [];
        let err = {
            username: "",
            firstName: "",
            lastName: "",
            email: "",
            emailVerified: "",
            password:"",
            repassword:"",
            role:"",
            avatar:"",
        }
        let nameErr = checkStrings(inputData.username, 'username', true);
        err = {...err, username: nameErr || ""}
        if(nameErr!==null){
            error.push(nameErr)
        }
        let emailErr = checkStrings(inputData.email, 'email', true);
        if(emailErr!==null){
            error.push(emailErr)
            err = {...err, email: emailErr || ""}
        }

        setErrors(err)
        if(error.length>0){
            toast.current?.show(error.map(i=>{
                return{severity:'error', summary:'Error Message', detail:i, life: 3000}
            }));
            return
        }

        const FormData = global.FormData;
        let formData = new FormData()
        if(typeof inputData.avatar == 'string' ){
            inputData?.avatar && formData.append('profilePic', inputData.avatar )
        }else{
            inputData?.avatar && formData.append('profilePic', inputData.avatar!, typeof inputData.avatar == 'string' ? undefined : inputData.avatar?.name)
        }
        
        formData.append('email', inputData.email)
        formData.append('firstName', inputData.firstName || "")
        formData.append('username', inputData.username)
        formData.append('lastName', inputData.lastName || "")
        formData.append('emailVerified', inputData.emailVerified!.toString())
        formData.append('role', inputData.role)
        if(!inputData.emailVerified){
            formData.append('requiredActions', 'VERIFY_EMAIL')
        }

        customersService.updateUserByRealmID(realmId, userId, formData).then(res=>{
        // customersService.updateUser(userId, formData).then(res=>{
            if(res?.status?.code != 200){
              toast.current?.show({severity:'error', summary: 'Error Message', detail:res.status?.message, life: 3000, closable:false})
            }else if(res?.status?.code == 200){
            //   const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
            //     window.location.href="/#/users"
            //     }, 3500);
              toast.current?.show({severity:'success', summary: 'Update User Successfull', detail:res.status?.message, life: 3000, closable:false})
            //   clearTimeout(timer);
            }
        }).catch(err=>{
            console.log(err)
            toast.current?.show({severity:'error', summary: 'Error Message', detail:err.status?.message, life: 3000, closable:false})
        })
        
    }

    return(<>
    <div className={`mx-5 mb-5 ${styles.createSpaceForm}`}>
        <Toast ref={toast} />
        {userInfo?.user && <UsersManagementForm data={{...userInfo.user!, role:userInfo?.role?._id}} handleSubmit={(inputData:UserForm)=>{handleSubmit(inputData)}} isCreated={false} errors={errors}/>}
    </div>
    </>)
}

export default EditUser;