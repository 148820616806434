import { Button } from "primereact/button";
import { useParams } from "react-router-dom";
import { RouteErrorParams } from "./enum/routerParams";

const ErrorPages = ()=>{
    const accessToken = localStorage.getItem("token")
    let {errorCode} = useParams<RouteErrorParams>();
    const displayError = () => {
        let err = Number(errorCode)
        if (err === 400) {
            return "User is not authorized to access this page.";
        } else if (err === 403) {
            return "User is forbidden to access this page.";
        } else if (err === 404) {
            return "Page Not Found";
        }else if(!err){
            return errorCode;
        }
    }
    return (
        <div>
            <div className="flex flex-wrap mb-5 align-content-center align-items-center justify-content-center">
                <i className="pi pi-exclamation-triangle mr-5" style={{'fontSize': '2em'}}/>
                <h1>Error</h1>

            </div>
            <div className="flex flex-wrap mb-5 align-content-center align-items-center justify-content-center">
                <h4>{displayError()}</h4>
            </div>
            <div className="flex flex-wrap mb-5 align-content-center align-items-center justify-content-center">
                <Button label="Return to home page" onClick={()=>{
                    window.location.href = '/'
                }} />
            </div>
        </div>
    )
}
export default ErrorPages;