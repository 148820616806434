import _ from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams, withRouter } from "react-router-dom";

import globalStyles from '../pageStyle.module.scss';

interface BreadcrumbComponentInterface {
    name:string
}

export const CustomBreadcrumb = () => {
    const {name} = useParams<BreadcrumbComponentInterface>();
    const location = useLocation();
    const history = useHistory();
    const [routeDisplay, setRouteDisplay] = useState<string[]>([]);
    useEffect(()=>{
        let pathNames = location.pathname.split("/").filter(i=>{
            console.log((name && name !== i && i))
            return (!name && i) || (name && name !== i && i)
        })
        console.log(pathNames)
        setRouteDisplay(pathNames)
    },[location.pathname])
    return (
        <div className={`col-12 ${globalStyles.breadcrumbs}`}>
            <div className={`${globalStyles.items}`}>
                {/* <li>{location.pathname}</li> */}
                {routeDisplay.map((i)=>{
                    return(
                        <div className={`${globalStyles.item}`}>{_.capitalize(i)}</div>
                    )
                })}
            </div>
        </div>
    );
}

export default withRouter(CustomBreadcrumb);