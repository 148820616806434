import React from 'react';

const AppFooter = () => {

    return (
        <div className="layout-footer clearfix">
            <a href="/">
                <img alt="logo-colored" src="assets/layout/images/logo-colored.png" />
            </a>
            <span className="footer-text-right">
                <span>All Rights Reserved</span>
            </span>
        </div>
    );

}

export default AppFooter;