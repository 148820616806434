import _ from "lodash"
import { Button } from "primereact/button"
import { useEffect, useLayoutEffect, useState } from "react"
import { itemsFolderId, peopleId } from "../../constants"
import { EntitiesServices } from "../../service/EntitiesServices"
import SpacesService from "../../service/SpacesService"
import SquidexService from "../../service/SquidexService"
import { getAssetSetFolder, removeExtension } from "../../utilities/Functions"
import CanvasSpaceView from "../CanvasSpaceView"
import { EntitiesListInterface, InstanceInterface } from "../enum/InstancesInterfaces"
import { RouteIdParams } from "../enum/routerParams"
import { BuildingInterface, characterInterface, npcDataInterface, npcInterface, SpaceForm } from "../enum/SpaceInterface"
import { imageSquidexInterface, imageSquidexInterfaces } from "../enum/SquidexInterfaces"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import styles from '../SpacesManagement.module.scss'
import tableStyles from '../tableStyle.module.scss';

type space3d={
    file: string,
}

type ResNPCS={
    npcs:npcDataInterface,
}

export const Space3DView = ({id}: RouteIdParams) => {
    const [space, setSpace] = useState<BuildingInterface>()
    const [instances, setInstances] = useState<EntitiesListInterface>()
    const [instancesChanged, setInstancesChanged] = useState<InstanceInterface[]>()
    const spaceService = new SpacesService();
    const squdiexsService = new SquidexService();
    // const entitesService = new EntitiesServices();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [npcs, setNPCs] = useState<characterInterface[]>([])

    const setCharacter = async (npc:npcInterface)=>{
        // if(npc.assetPath.includes(".fbx")){
        let strArr = npc.assetPath.split('/')
            
        let assetSetFolder={id:""};
            if(npc.assetPath.includes("people")){
            
            assetSetFolder = await getAssetSetFolder(peopleId, npc.assetSet)
        }
        let assetsFoldersRes:imageSquidexInterfaces = await squdiexsService.getAssetsFolders(assetSetFolder?.id !== "" ? assetSetFolder.id: itemsFolderId).then(folders=>{
            // console.log(assetSetFolder?.id !== "" ? assetSetFolder.id: itemsFolderId, folders)
            return {
                anims: _.find(folders?.items, (item)=>{
                    return item.folderName == "anims"
                }),
                textures: _.find(folders?.items, (item)=>{
                    return item.folderName == "textures"
                }),
            }
        })

        let fileName = removeExtension(strArr[strArr.length-1])
        let assetsFinal:imageSquidexInterface = await squdiexsService.getAssetsByFolderId(assetSetFolder?.id !== "" ? assetSetFolder.id: itemsFolderId).then(res=>{
            return res?.items?.length>0 && _.find(res.items, i=>i.fileName.includes(fileName))
        })

        let textureName = npc.texturePath.split('/')
        let textureNameRemoveExt = removeExtension(textureName[textureName.length-1])
        let textureRes:imageSquidexInterface = assetsFoldersRes?.textures?.id ? await squdiexsService.getAssetsByFolderId(assetsFoldersRes.textures.id, "type eq 'Image'").then(assets=>{
            return assets?.items?.length>0 && _.find(assets.items, i=>i.fileName.includes(textureNameRemoveExt));
        }): {
            id: "",
            parentId: "",
            fileName: "",
            slug: "",
        }


        let animRes:imageSquidexInterface = assetsFoldersRes?.anims?.id ? await squdiexsService.getAssetsByFolderId(assetsFoldersRes.anims.id).then(assets=>{
            return assets?.items?.length>0 && _.find(assets.items, i=>i.fileName == "Idle.fbx");
        }): {
            id: "",
            parentId: "",
            fileName: "",
            slug: "",
        }
        return {
            modelPath:assetsFinal,
            texture:textureRes,
            anim:animRes,
            name:strArr[strArr.length-1],
            ...npc
        }
        // }

    }

    useEffect(()=>{
        (async()=>{
            let npcsDatas:npcInterface[] = []
            await spaceService.getSpaceById(id).then(async (res:SpaceForm)=>{
                // if(res.building)
                setSpace(res.building)
                // setInstances(res.instances)
                let spaceInstance:EntitiesListInterface = await spaceService.getSpaceInstanceByInstanceID(res.instances?._id||"")
                setInstances(spaceInstance)
                let instanceChanged:InstanceInterface[] = _.filter(spaceInstance.list,(item:InstanceInterface)=>{
                    return item.template.assetPath !== item.squidexTemplate!.asset.iv[0].url ||item.squidexTemplate!.errorCode == undefined
                })
                setInstancesChanged(instanceChanged)
                if(res.building){
                    await EntitiesServices.getBuildingNPCByBuildingId(res.building?._id!).then(({npcs}:ResNPCS)=>{
                        // setNPCs(npcs.npcData)
                        npcsDatas = npcs.npcData;
                    })

                }
            })
            if(npcsDatas?.length>0){
                let res:characterInterface[] = []
                for (let i = 0; i < npcsDatas.length; i++) {
                    const element:characterInterface = await setCharacter(npcsDatas[i]);
                    res.push(element)
                }
                setNPCs(res)
            }
            setIsLoading(false)
        })()
    },[])
    return(
        <div className={`flex-1 grid`}>
            {!isLoading && space ? <>
            {/* <div className={`col-12 md:col-4 grid`}>
                <div className={`col-12 md:col-9 grid-nogutter`}>
                </div>
                <div className={`col-12 md:col-3`}>
                    <Button className="w-full" label="Add" icon={"pi pi-angle-double-right"} iconPos={'right'}/>
                </div>
            </div>
            <div className={`col-12 md:col-8`}> */}
            <div className={`col-12`}>
                 <CanvasSpaceView building={space!} npcs={npcs} instances={instances}/>
            </div>
            {instancesChanged && instancesChanged.length>0 && <div className={`col-12 ${styles.spaceManagementTable}`}>    
                <div className={`col-12`}>
                    <h2>Assets Error List</h2>
                </div>
                <DataTable
                rows={10}
                className={`${styles.spaces}`}
                paginatorClassName={`grid ${tableStyles.paginator}`}
                removableSort={true}
                paginator={true}
                responsiveLayout="scroll"
                value={instancesChanged}
                paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
                currentPageReportTemplate={"Showing {first} to {last} of {totalRecords}"}
                >
                <Column header= "Name" field="name" sortable/>
                <Column header= "Error" body={(item:InstanceInterface) => {
                    return item.squidexTemplate!.errorCode == 404 ? "Asset Not Found": "Asset Changed Path"
                }}/>
                </DataTable>
            </div>}
            </>:
            <></>}
        </div>
    )
}