import { FAQ, FaqItems, FaqSection } from "../components/enum/FaqInterface";
import { API_BASE_URL } from "../constants";
import { axiosClient } from "../utilities/CustomAxios";

let token = localStorage.getItem("token")
export default class FAQService {
  getFaqFromSpace(spaceID: string){
    return axiosClient.get(`${API_BASE_URL}/space/${spaceID}/faqs`, {
      headers: {
        "Authorization" : `Bearer ${token}`
      }
    }).then(res => res.data)
  }

  updateFaqFromFaqId(faqID: string, faqData: Partial<FAQ>){
    return axiosClient.put(`${API_BASE_URL}/spaces/faq/update/${faqID}`, {
      "title": faqData.title,
      "entityId": faqData.entityId,
    }).then(res => res.data)
  }

  createNewSection(faqID: string, sectionData: Partial<FaqSection>) {
    return axiosClient.post(`${API_BASE_URL}/spaces/faq/${faqID}/section/save`, {
      "section": sectionData.section,
      "sectionId": sectionData.sectionId,
    }).then(res => res.data)
  }

  createQuestionAndAnswer(sectionID: string, QuestionData: Partial<FaqItems>) {
    return axiosClient.post(`${API_BASE_URL}/spaces/faq/${sectionID}/question/save`, {
      "question": QuestionData.question,
      "answer": QuestionData.answer,
      "number": QuestionData.number,
    }).then(res => res.data)
  }

  createNewFaq(spaceId: string, faqData: Partial<FAQ>) {
    return axiosClient.post(`${API_BASE_URL}/spaces/${spaceId}/faq/save`, {
      "title": faqData.title,
      "entityId": faqData.entityId,
    }).then(res => res.data)
  }
}