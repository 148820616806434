import { memo, useEffect } from "react";
import * as THREE from "three";
import { useGLTF, useTexture } from "@react-three/drei";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { BackSide, DoubleSide, FrontSide, MeshBasicMaterial, MeshPhongMaterial } from "three";
import { MESH_SIDE, MESH_TYPE } from "../enum/meshes";
import { InstanceInterface } from "../enum/InstancesInterfaces";
import { GLTFLoader } from "three-stdlib";

type ModelProps = {
    modelFilePath:string;
    textureFilePath?:string;
    modelScale?:number;
    rotation?:{
        x:number,
        y:number,
        z:number
    };
    position?:{
        x:number,
        y:number,
        z:number
    };
    instances:InstanceInterface[]
}

const changeMaterial=(mesh:THREE.Mesh, newMat: THREE.Material)=>{
    var prevMaterial = mesh.material as THREE.Material;
    mesh.material = newMat
    THREE.MeshBasicMaterial.prototype.copy.call( mesh.material, prevMaterial! );
    return mesh
}

const addMesh = (child: THREE.Object3D)=>{
    let mesh = child as THREE.Mesh;
    if ( mesh?.isMesh ) {
        let material = mesh.material as THREE.MeshStandardMaterial
        changeMaterial(mesh,new MeshPhongMaterial())
        material.side = FrontSide;
        material.metalness = 0;
        if (material.userData.side) {
            switch (material.userData.side) {
                case MESH_SIDE.DOUBLE:
                    material.side = DoubleSide;
                    break;
                case MESH_SIDE.BACK:
                    material.side = BackSide;
                    break;
                case MESH_SIDE.FRONT:
                default:
                    material.side = FrontSide;
                    break;
            }
        }
        if (child.userData.proxy) {
            changeMaterial(mesh, new MeshBasicMaterial())
            // ent.changeMaterial(c, new MeshBasicMaterial());
            child.receiveShadow = false;
            child.castShadow = false;
            material.wireframe = true;
            material.visible = false;
            // ent.proxies.push(c);
        } else if (!child.userData.meshType) {
            if (!child.userData.noShadow)
                child.castShadow = true;
            child.receiveShadow = true;
        }
        if (child.userData.meshType) {
            switch (child.userData.meshType) {
                // case MESH_TYPE.FLOOR:
                //     child.matrixAutoUpdate = false;
                //     break;
                // case MESH_TYPE.MOUNT:
                //     child.matrixAutoUpdate = false;
                //     break;
                case MESH_TYPE.ZONE:
                    changeMaterial(mesh, new MeshBasicMaterial());
                    child.receiveShadow = false;
                    child.castShadow = false;
                    material.wireframe = true;
                    material.visible = false;
                    child.matrixAutoUpdate = false;
                    break;

                case MESH_TYPE.CLIPPLANE:
                    changeMaterial(mesh, new MeshBasicMaterial());
                    child.receiveShadow = false;
                    child.castShadow = false;
                    material.wireframe = true;
                    material.visible = false;
                    break;
                case MESH_TYPE.FLOOR:
                case MESH_TYPE.MOUNT:
                case MESH_TYPE.BUTTON:
                default:
                    child.matrixAutoUpdate = false;
                    break;
            }
        }
    }
    return child;
}

// const getObj3DByPath = (modelFilePath:string)=>{
//     const loader = new GLTFLoader();
//     let scene:THREE.Scene = new THREE.Scene();
//     loader.load( modelFilePath, function ( gltf ) {

//         scene.add( gltf.scene );
    
//     }, undefined, function ( error ) {
    
//         console.error( error );
    
//     } );
//     return scene;
// }

const BuildingModel = ({modelFilePath, textureFilePath, modelScale = 1, rotation={x:0,y:0,z:0}, position={x:0,y:0,z:0}, instances=[]}:ModelProps) =>{
    // let url = modelFilePath.substring(modelFilePath.lastIndexOf('/')+1);
    // const texture:THREE.Texture = useTexture(textureFilePath)
    // let gltf = useGLTF('https://content-sea.fromlabs.com/api/assets/virtualspace/stasiun-mangga.gltf?version=0')
    // const gltf = useLoader(GLTFLoader, 'assets/space/stasiun_mangga')
    // const gltf = useGLTF('https://content-sea.fromlabs.com/api/assets/virtualspace/57a6ce36-aef8-4581-82c9-25ad2be3f176/gallery-painting.gltf?version=0', true, undefined, (loader) => {
    const gltf = useGLTF(modelFilePath, true, undefined, (loader) => {
        // loader.manager.setURLModifier((url:string)=>{
        //     let testUrl = url.substring(url.lastIndexOf('/')+1);
        //     console.log("https://content-sea.fromlabs.com/api/assets/virtualspace/" + testUrl.toLowerCase().replaceAll(/_|%20/g,"-"))
        //     if([".bin", ".png", '.jpg'].some(element=>testUrl.includes(element))){
        //         return "https://content-sea.fromlabs.com/api/assets/virtualspace/" + testUrl.toLowerCase().replaceAll(/_|%20/g,"-")
        //     }
        //     return url
        // })
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('/draco-gltf/')
        loader.setDRACOLoader(dracoLoader)
    })


    // instances.map((instance:InstanceInterface)=>{
    //     const loader = new GLTFLoader();
    //     const dracoLoader = new DRACOLoader();
    //     dracoLoader.setDecoderPath( '/draco-gltf/' );
    //     loader.setDRACOLoader( dracoLoader );

    //     loader.load( instance.squidexTemplate!.asset.iv[0].url, function ( gltfNew ) {
    //         console.log("ASDASD")
    //         gltf.scene.add(gltfNew.scene)
    //         // scene.add( gltf.scene );
    //     },undefined, function ( error ) {
    //         console.error( error );
    //     } );
    //     console.log("AAAAAA")
    // })

    useEffect(()=>{

        gltf.scene.scale.set(modelScale * gltf.scene.scale.x, modelScale * gltf.scene.scale.y, modelScale * gltf.scene.scale.z)
        gltf.scene.rotateX(rotation.x)
        gltf.scene.rotateY(rotation.y)
        gltf.scene.rotateZ(rotation.z)
        gltf.scene.position.set(position.x, position.y, position.z)

        gltf.scene.traverse((child:THREE.Object3D)=>{
            
            addMesh(child)
                // console.log(child, mesh.material)
        })

        for(let index = 0; index<instances.length; index++) {
            let instance = instances[index]
            const loader = new GLTFLoader();
            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderPath('/draco-gltf/')
            loader.setDRACOLoader(dracoLoader)
            loader.load( instance.squidexTemplate!.asset.iv[0].url, function ( gltfNew ) {
                console.log("ASDASD")
                gltfNew.scene.scale.set(instance.scale * gltfNew.scene.scale.x, modelScale * gltfNew.scene.scale.y, modelScale * gltfNew.scene.scale.z)
                gltfNew.scene.rotateX(instance.rotation.x)
                gltfNew.scene.rotateY(instance.rotation.y)
                gltfNew.scene.rotateZ(instance.rotation.z)
                gltfNew.scene.position.set(instance.position.x, instance.position.y, instance.position.z)

                gltfNew.scene.traverse((node:THREE.Object3D)=>{
                    let nodeRes = addMesh(node)
                    gltf.scene.add(nodeRes)
                })
                
                // scene.add( gltf.scene );
            },undefined, function ( error ) {
                console.error( error );
            } );
        }
    },[])
    
    return (
        <group dispose={null}>
            <primitive object={gltf.scene} />
        </group>
    )
}


export default memo(BuildingModel);