import { Card } from "primereact/card";
import React, { useEffect, useRef, useState } from "react";
import { RealmInterface, RealmsUsersInterface } from "../components/enum/realmInterface";
import RealmsService from "../service/RealmsServcie";
import { Image } from "primereact/image";
import { SQUIDEX_APP, SQUIDEX_URL } from "../constants";
import CustomerService from "../service/CustomerService";
import { useDispatch, useSelector } from "react-redux";
import { getSpaceInfoByRealm } from "../redux/spaces";
import { AppDispatch, RootState } from "../redux/store";
import { Toast } from 'primereact/toast';
interface Props {
    // userId: String | undefined;
    isAuthenticate: boolean;
    setRealmId: React.Dispatch<React.SetStateAction<String | undefined>>
}
const RealmsSelector: React.FC<Props> = ({ isAuthenticate, setRealmId }) => {
    if (!isAuthenticate) {
        window.location.href = `/#/login/`
    }

    const [orgs, setOrgs] = useState<Array<RealmsUsersInterface>>()
    const realmsService = new RealmsService()
    // const accessToken = localStorage.getItem('token');
    const userId = localStorage.getItem('user');
    const customerService = new CustomerService();
    const dispatch = useDispatch<AppDispatch>();
    const toast = useRef<any>(null);

    useEffect(() => {
        realmsService.getRealmsByUser(userId!).then((res: Array<RealmsUsersInterface>) => {
            if (res.length == 1) {
                setRealmId(res[0].realm._id)
                localStorage.setItem('realmId', res[0].realm._id?.toString()!);
                localStorage.setItem('assetSet', res[0]?.assetSet?.toString() || "default");
                window.location.href = '/'
            }
            setOrgs(res)
        })
    }, [])

    const selectOrg = (org: any) => {
        customerService.getUserByRealm(org.realm._id, userId!).then(res => {
            if (res.role.permissions.length > 0) {
                setRealmId(res.realm)
                localStorage.setItem('realmId', res.realm._id);
                localStorage.setItem('assetSet', org.realm.assetSet || "default");
                dispatch(getSpaceInfoByRealm(res.realm))
                window.location.href = '/'
            } else {
                window.location.href = '/#/error-page/403'
            }

        })
        // setRealmId(org?.id)
        // // console.log(org)
        // localStorage.setItem('realmId', org.realm._id);
        // localStorage.setItem('assetSet', org.realm.assetSet || "default");
        // window.location.href='/'
    }

    const header = (item: RealmInterface) => {
        return (
            item?.banner && <Image
                // alt={"Card"}
                src={`${SQUIDEX_URL}/api/assets/${SQUIDEX_APP}/${item.banner}`}
            // onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} 
            />
        )
    };

    const showError = (msg: string) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="login-body w-full flex flex-wrap flex-column justify-content-center align-items-center">
			<div className="p-fluid w-9 h-full flex flex-wrap flex-column my-5">
				<div className="flex flex-wrap justify-content-center py-2">
                    <img src="assets/layout/images/our3d-logo-1.png" alt="serenity-react"
                    width={50}/>
                </div>
                <div className="px-4 py-5 flex-1">
                    <div className="grid p-0 m-auto align-items-center overflow-auto" style={{maxHeight:'23rem'}}>
						{/* <div className="col-12 flex flex-wrap p-0 w-full" style={{backgroundColor:'yellow'}}>
                            <div className="flex-1 card text-center mx-1" style={{backgroundColor:'red'}}>A</div>
                            <div className="flex-1 card text-center mx-1" style={{backgroundColor:'red'}}>A</div>
                            <div className="flex-1 card text-center mx-1" style={{backgroundColor:'red'}}>A</div>
                        </div> */}
                            {orgs?.map((org: RealmsUsersInterface, index: number) => {
                                return (
                                    <div key={`realms-${org.realm.name}-index`} className="col-12 md:col-6 lg:col-4" onClick={() => {
                                        selectOrg(org);
                                    }}>
                                        <Card style={{
                                            padding: 5,
                                        }} title={org.realm.name} header={() => header(org.realm)}>{org.realm.name}</Card>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default React.memo(RealmsSelector)