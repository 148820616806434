
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setTimeout } from 'timers';
import RolesService from '../../service/RolesService';
import { roleInterface } from '../enum/roleInterface';
import { RouterNameParams } from '../enum/routerParams';
import RolesManagementForm from '../form/RoleManagementForm';
import styles from './userStyles.module.scss';
import globalStyles from '../pageStyle.module.scss';
import BreadcrumbComponent from '../items/BreadcrumbComponent';

const EditRole = () =>{
    const [roleInfo, setRoleInfo] = useState<roleInterface>({
        name:'',
        permissions: []
    })
    const roleServices = new RolesService();
    const toast = useRef<Toast>(null);
    let {name} = useParams<RouterNameParams>();
    
    useLayoutEffect(()=>{
        roleServices.getRoleById(name).then(res=>{
            setRoleInfo(res);
        }).catch(err=>{
            window.location.href = '/#/error-page/'+ (err.response?.status || err.message)
        })
    },[])

    const handleSubmit = (inputData: roleInterface)=>{

        roleServices.updateRole(name, {...inputData, permissions:[...inputData.permissions]}).then(res=>{
            toast.current?.show({severity:'success', summary: 'Update Role Successfull', detail:res.status?.message, life: 3000, closable:false})
        }).catch(err=>{
            toast.current?.show({severity:'error', summary: 'ERROR', detail:"Error occusr when edited!", life: 3000, closable:false})
            
        });
    }

    return(<>
    <Toast ref={toast}/>
    <div className={`grid ${styles.header} mx-1 mb-5 ${globalStyles.header}`}>
        <div className={`col-12`}>
            <Button onClick={()=>{window.location.href = '/#/create-new-space'}} label="Back to Roles List"/>
        </div>
        <div className={`col-12`}>
          <h1>ROLES</h1>
          <p>You can manage all the Roles data in this page easier.</p>
        </div>
        <BreadcrumbComponent/>
    </div>
    <div className={`${globalStyles.editBodyWrapper}`}>
        <div className={`card ${styles.createSpaceForm} ${globalStyles.content}`}>
            <div className={`flex justify-content-between flex-wrap ${styles.header} mx-5`}>
                <h3 className=" align-items-center justify-content-center font-bold">Edit Role Data</h3>
                {/* <div className={`flex flex-wrap align-items-center justify-content-end`}>
                    <Button onClick={()=>{window.location.href = '/#/create-new-space'}} label="Back to Roles List"/>
                </div> */}
                {/* {!getUserError && <Button type="button" label="Back to user list" className={`align-items-center justify-content-center ${styles.button}`} onClick={()=>{window.location.href="/#/users"}}/>} */}
            </div>
            <RolesManagementForm data={roleInfo} handleSubmit={handleSubmit} isCreated={false}/>
            {/* <UsersManagementForm data={userInfo} handleSubmit={handleSubmit} isCreated={false}/> */}
        </div>
    </div>
    </>)
}

export default EditRole;