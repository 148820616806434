import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Axios from 'axios';
import { API_BASE_URL } from "../constants";

const accessToken = localStorage.getItem("token")
// const realmId = localStorage.getItem("realmId")

export const getSpaceInfoByRealm = createAsyncThunk(
  'realm/getSpaceByRealm',
  async (realmId: String) => {
    return Axios.get(`${API_BASE_URL}/realms/${realmId}/spaces`,
    { headers: {"Authorization" : `Bearer ${accessToken}`} }
    ).then((res) => {return res}).catch(error => console.log(error))
  }
)

interface SpaceList {
  spaces: object[];
  status: string | null;
}

const initialState: SpaceList = {
  spaces: [],
  status: null,
}

export const modelSlice = createSlice({
  name: 'spacesInfo',
  initialState,
  reducers: {
    getSpacebyRealm: (state) => {
      console.log(state.spaces)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSpaceInfoByRealm.pending, (state,) => {
      state.status = 'loading'
    })
    builder.addCase(getSpaceInfoByRealm.fulfilled, (state, {payload, meta}) => {
      if (payload){
        localStorage.setItem('stateSpaceInfo', payload.data.spaces);
        state.spaces = payload.data.spaces
      }
      state.status = 'success'
    })
    builder.addCase(getSpaceInfoByRealm.rejected, (state) => {
      state.status = 'failed'
    })
  }
})

export const { getSpacebyRealm } = modelSlice.actions

export default modelSlice.reducer