import { Button } from "primereact/button"
import { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client"
import styles from "./SpaceStreaming.module.scss";
import Peer, { SignalData } from "simple-peer";
import { EntitiesServices } from '../../service/EntitiesServices'
import tableStyles from './tableStyle.module.scss';
import globalStyles from '../pageStyle.module.scss';

interface RouteParams { id: string };

interface PeerRef {
  peer: Peer.Instance,
  peerID: string
}
interface SocketPayload {
  signal: string | SignalData,
  callerID: string | undefined,
  id: string | undefined
}

interface Screen {
  name: string,
  template: {
    _id: string,
    name: string,
    assetPath: string,
    type: string
  }
}

const socket = io(process.env.REACT_APP_SOCKET_URL!, {
  withCredentials: true,
  transports: ['websocket', 'polling'],
});

export const SpaceStreaming = () => {
  let roomID = useParams<RouteParams>()

  const [isStreaming, setIsStreaming] = useState<boolean>(false)
  const [screenLists, setScreenLists] = useState<Array<Screen>>();

  const peerRefList = useRef<Array<PeerRef>>([]);
  const userVideo = useRef<HTMLVideoElement>();
  const [streamTrack, setStreamTrack] = useState<MediaStream>();
  // const screenID = "vidScreen_1_F3_KCI";

  // socket join room
  useEffect(() => {
    socket.on('setId', (data) => {
      socket.emit('joinRoom', roomID.id);
    });

    socket.on('roomJoined', (payload) => {
      payload.forEach((userID: string) => {
        const peer = createPeer(userID, socket.id)
        peerRefList.current.push({
          peerID: userID,
          peer
        })
      });
    });

    socket.on('gotCalled', (payload: SocketPayload) => {
      const item = peerRefList.current.find(p => p.peerID === payload.callerID);
      if (item === undefined) {
        const peer = addPeer(payload.signal, payload.callerID!);
        peerRefList.current.push({
          peerID: payload.callerID!,
          peer: peer,
        });
      } else {
        item.peer.signal(payload.signal);
      }
    });

    socket.on('gotReturnedSignal', (payload: SocketPayload) => {
      const item = peerRefList.current.find(p => p.peerID === payload.id);
      try {
        item?.peer.signal(payload.signal);
      } catch (err) {
        console.log(err);
      }
    })
  }, []);

  useEffect(() => {
    EntitiesServices.getStreamableScreenFromRoomID(roomID.id).then(res => {
      setScreenLists(res);
    })
  }, [])

  function createPeer(toUser: string | SignalData, fromUser: string) {
    const peer = new Peer({
      initiator: true,
      trickle: true,
    });

    console.log(`Calling user: ${toUser}`);
    
    peer.on('signal', (signal: SignalData) => {
      socket.emit('sendingSignal', { userToSignal: toUser, callerID: fromUser, signal: signal })
    })

    peer.on("connect", () => {
      peer.send('Connected peer new user side! ')
    })

    peer.on('data', data => {
      //console.log(`got a message from ${peer.id}:  + ${data}`);
    });

    peer.on('close', function () {
      peer.destroy();
      peerRefList.current = peerRefList.current.filter(p => p.peer === peer)
    });

    return peer
  }

  function addPeer(incomingSignal: string | SignalData, callerID: string) {
    const peer = new Peer({
      initiator: false,
      trickle: true,
    });

    console.log('Adding User: ' + callerID);

    peer.on('signal', (signal: SignalData) => {
      socket.emit('returningSignal', { signal, callerID })
    })

    peer.signal(incomingSignal);

    peer.on('connect', () => {
      peer.send(`Connected peer old user side!`);
    });

    peer.on('data', data => {
      //console.log(`got a message from ${peer.id}:  + ${data}`);
    });

    peer.on('close', function () {
      peer.destroy();
      peerRefList.current = peerRefList.current.filter(p => p.peer === peer)
    });

    return peer;
  }

  function HandleStopStream() {
    if (userVideo) {
      const stream = streamTrack
      const track = stream!.getTracks();
      track.forEach((track) => {
        track.stop();
      });
      const video = document.getElementById(stream!.id)
      if (video) {
        video.remove();
      }
      setIsStreaming(false)
    }
  }

  function HandleStartStream() {
    navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        sampleRate: 44100
      }
    }).then(
      stream => {
        setStreamTrack(stream)
        setIsStreaming(true)
        const video = document.createElement('video');
        video.id = stream.id
        video.style.width = "100%";
        video.style.height = "100%";
        video.srcObject = stream!;
        userVideo.current = video;

        const element = document.querySelector("#self-sharing")
        element?.appendChild(video)
        video.addEventListener("loadedmetadata", e => {
          video.play();
        }, false)

        screenLists?.forEach((screen: Screen) => {
          socket.emit('shareScreen', { screenID: screen.name, streamID: stream.id })
        })
        setTimeout(() => {
          peerRefList.current.forEach(peer => {            
            try {
              peer.peer.addStream(stream);
            } catch (error) {
              console.log(error);
            }
          });
        }, 3000);
      }
    ).catch(err => {
      setIsStreaming(false)
    });
  }

  return (
    <>
      <div className={`${globalStyles.header}`}>
          <div className={``}>
            <h1>Streaming</h1>
            <p></p>
          </div>
        </div>
      <div className={`card ${styles.SpaceStreaming}`}>
        <form>
          {/* <div className={`flex justify-content-between flex-wrap ${styles.header}`}>
            <h3>Streaming</h3>
          </div> */}
          <div className={`${styles.videoFeed}`}>
            <div className={`${styles.videoPanel}`} id="self-sharing"></div>
          </div>
          <div className={`flex justify-content-end flex-wrap save ${styles.streamBtn}`}>
            {
              isStreaming ?
                <Button type="submit" label="Stop Streaming" className={`${styles.buttonStopStream}`} onClick={HandleStopStream} />
                :
                <Button type="submit" label="Start Streaming" className={`${styles.buttonStartStream}`} onClick={HandleStartStream} />
            }
          </div>
        </form>
      </div>
    </>
  )
}