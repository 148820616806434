import { Environment, OrbitControls, useProgress } from "@react-three/drei"
import { Canvas } from "@react-three/fiber"
import _, { capitalize } from "lodash"
import { Button } from "primereact/button"
import { memo, Suspense, useEffect, useState } from "react"
import SquidexService from "../service/SquidexService"
import { getAssetSetFolder } from "../utilities/Functions"
import CharacterModelFBX from "./models/CharacterModelFBX"
import { imageSquidexInterface, imageSquidexInterfaces } from "./enum/SquidexInterfaces"
import { animationsNameDef } from "../constants"

type CanvasCustomType={
    imageFile:imageSquidexInterface,
    texturePath:string,
    gender?:string
}


const CanvasCharacterView = ({imageFile, texturePath, gender=""}:CanvasCustomType) => {
    const [ animations, setAnimations] = useState<imageSquidexInterface[]>([])
    const squdiexsService = new SquidexService();
    const [selectedAnimation, setSelectedAnimation] = useState<imageSquidexInterface>()
    
    useEffect(() => {
        (async ()=>{
            let assetSetFolder = await getAssetSetFolder()
            let assetsFoldersRes:imageSquidexInterfaces={};
            await squdiexsService.getAssetsFolders(assetSetFolder.id).then(folders=>{
            // console.log(folders)
            // let asstesRes = assets?.items?.map((asset:imageSquidexInterface)=>{
                //                 return asset
                //             })
                //             return asstesRes;
                assetsFoldersRes = {
                    anims: _.find(folders?.items, (item)=>{
                        return item.folderName == "anims"
                    }),
                }
                // return 
            })
            if(assetsFoldersRes){
                let assetsFinal:Array<imageSquidexInterface> =[]
                assetsFoldersRes?.anims?.id && await squdiexsService.getAssetsByFolderId(assetsFoldersRes.anims.id, "tags eq 'type/fbx'").then(assets=>{
                    assetsFinal = assets?.items?.length>0 && assets?.items.map((i:imageSquidexInterface)=>{
                        return animationsNameDef.some(name=>i.fileName.replaceAll("."+i.fileType,"") == ((gender && name=="Idle") ? name + capitalize(gender) : name)) && i
                         
                    }).filter((i:imageSquidexInterface)=>i);
                })
                if(assetsFinal?.length>0){
                    setAnimations(assetsFinal)
                    setSelectedAnimation(_.find(assetsFinal, (i:imageSquidexInterface)=>{
                        // console.log(i.fileName.replaceAll("."+i.fileType,"") == "Idle"+capitalize(gender))
                        return i.fileName.replaceAll("."+i.fileType,"") == "Idle"+capitalize(gender)
                    }) || assetsFinal[0])
                }
            }
            // await squdiexsService.get
        })()
    },[gender, imageFile])
    // function Loader() {
    //     const { progress } = useProgress()
    //     return <div className={`flex flex-1 justify-content-center`}>{progress} % loaded</div>
    // }
    return(
    <div className={`w-100 h-100 relative`} style={{backgroundColor:'#ccc', minHeight:500}}>
        <div className={`absolute z-5 flex flex-column h-full`} style={{
            top:10, 
            left:10,
        }}>
            {/* <Button icon="pi pi-check" tooltip={"Idle"} className="p-button-rounded p-button-outlined my-1" aria-label="Dance" />
            <Button icon="pi pi-check" tooltip={"Greeting"} className="p-button-rounded p-button-outlined my-1" aria-label="Dance" />
            <Button icon="pi pi-check" tooltip={"Dance"} className="p-button-rounded p-button-outlined my-1" aria-label="Dance" /> */}
            {animations?.length>0 && animations?.map(ani=>{
                // console.log(ani)
                return(
                    <Button
                    label={ani.fileName.replaceAll("."+ani.fileType,"")}
                    className={`p-button-rounded p-button-outlined my-1`}
                    aria-label={ani.fileName.replaceAll("."+ani.fileType,"")}
                    disabled={selectedAnimation?.id==ani.id}
                    onClick={()=>{
                        setSelectedAnimation(ani)
                    }}
                    />
                )
            })}

        </div>
        <Canvas dpr={[1, 2]}  camera={{position: [0, 3, 12], fov: 45}} style={{minHeight:500}}>
            <color attach="background" args={["lightgrey"]} />
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 5]} />
            <pointLight position={[-10, -10, -10]} />
            <Suspense fallback={()=><></>}>
                {/* {console.log(gender, selectedAnimation)} */}
                {imageFile && texturePath && <CharacterModelFBX
                pose={4}
                position={[0, 0, 0]}
                modelFilePath={"https://content-sea.fromlabs.com"+imageFile?._links?.content?.href!}
                textureFilePath={'https://content-sea.fromlabs.com'+texturePath}
                animationFilePath={'https://content-sea.fromlabs.com'+selectedAnimation?._links?.content?.href!}
                />}
                
                <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.5, 0]} receiveShadow>
                    <planeBufferGeometry attach="geometry" args={[20, 20]} />
                    <meshBasicMaterial attach="material" color="#cccccc" />
                </mesh>
                <OrbitControls />
                {/* <Environment preset="sunset" background={true} /> */}
            </Suspense>
            <gridHelper />
        </Canvas>
    </div>
    )
}
export default memo(CanvasCharacterView);