import axios from 'axios'
import { API_BASE_URL } from '../constants';
import { axiosClient } from '../utilities/CustomAxios';
import { UserForm } from '../components/enum/userForm';
// type userForm = {
//     username:string;
//     firstName:string;
//     lastName:string;
//     email:string;
//     status:boolean;
//     profilePic?: Blob;

// }

type createUserForm ={
    username:string;
    firstName:string;
    lastName:string;
    email:string;
    status:boolean;
    profilePic?: Blob;
    password: string;
}
export default class CustomerService {
    getUserAuthenticate(token: string) {
        // return axios.get('http://192.168.11.193:8080/api/users/auth', 
        return axiosClient.get(`${API_BASE_URL}/users/auth`, 
        // { headers: {"Authorization" : `Bearer ${token}`} }
        ).then(res => res.data);
    }

    postUserLogin(username: string, password: string){
        // return axios.post('http://192.168.11.193:8080/api/users/auth',
        return axios.post(`${API_BASE_URL}/users/auth`,
            {
                username: username,
                password: password,
            }
        ).then(res => res.data)
    }

    getCustomers(){
        return axiosClient.get(`${API_BASE_URL}/users/login/recent`).then(res=>res.data)
    }

    getCustomersList(realmId:string){
        return axiosClient.get(`${API_BASE_URL}/realms/${realmId}/users/list`)
        .then(res=>{
            return res.data})
        .catch(err=>{
            console.log(err)
        })
    }

    getCustomersSmall() {
        return axios.get('assets/demo/data/customers-small.json').then(res => res.data.data);
    }

    getCustomersMedium() {
        return axios.get('assets/demo/data/customers-medium.json').then(res => res.data.data);
    }

    getCustomersLarge() {
        return axios.get('assets/demo/data/customers-large.json').then(res => res.data.data);
    }

    getCustomersXLarge() {
        return axios.get('assets/demo/data/customers-xlarge.json').then(res => res.data.data);
    }

    getUser (userId:string){
        return axiosClient.get(`${API_BASE_URL}/users/${userId}`).then(res=>res.data)
    }
    
    getUserByRealm (realmId:string, userId:string){
        return axiosClient.get(`${API_BASE_URL}/realms/${realmId}/users/${userId}/info`).then(res=>res.data)
    }

    searchUsers(searchStr: string){
        return axiosClient.get(`${API_BASE_URL}/users/search/${searchStr}`).then(res=>{return res.data});
    }

    searchUsersInRealm(searchStr: string, realmId:String){
        return axiosClient.get(`${API_BASE_URL}/realms/${realmId}/users/search/${searchStr}`).then(res=>{return res.data});
    }

    // createUser (user:createUserForm) {
    //     let form = new FormData()
    //     Object.keys(user).map(item=>{
    //         user[item as keyof createUserForm] && form.set(item, user[item as keyof createUserForm]?.toString()!)
    //     })
    //     for(const item of form){
    //         console.log(item)
    //     };
    //     console.log(form)
    //     return axiosClient.post(`${API_BASE_URL}/users/register`,{
    //         form
    //         // ...user
    //     },
    //     {
    //         headers:{
    //             'Content-Type':'multipart/form-data'
    //         }
    //     }).then(res=>res.data);
    // }

    createUser(form:FormData){
        return axiosClient.post(`${API_BASE_URL}/users/register`,
            form
        ,
        {
            headers:{
                "Content-Type": "multipart/form-data",
                'Accept': 'application/json'
            }
        })
        .then(res=>res.data);
    }

    createUserInRealm(realmId:string, formData:FormData, token:string = localStorage.getItem('token')!){
        return axiosClient.post(`${API_BASE_URL}/realms/${realmId}/users/register`,
            formData
        ,
        {
            headers:{
                "Content-Type": "multipart/form-data",
                'Accept': 'application/json',
                "authorization": "Bearer "+token,
            }
        })
        .then(res=>res.data);
    }

    updateUser (userId: string, form: FormData){
        return axiosClient.post(`${API_BASE_URL}/users/${userId}/update`,
        form,
        {
            headers:{
                "Content-Type": "multipart/form-data",
                'Accept': 'application/json'
            }
        }).then(res=>res.data); 
    }

    updateUserByRealmID(realmId:string, userId:string, form:FormData){
        return axiosClient.post(`${API_BASE_URL}/realms/${realmId}/users/${userId}/update`,
        form,
        {
            headers:{
                "Content-Type": "multipart/form-data",
                'Accept': 'application/json'
            }
        }).then(res=>res.data); 
    }
    
    deleteUser(userId:string, forceDelete:boolean = false){
        return axiosClient.delete(`${API_BASE_URL}/users/${userId}/delete/${forceDelete}`).then(res=>res.data)
    }

    deleteUserInRealm(realmId:string, userId:string){
        return axiosClient.delete(`${API_BASE_URL}/realms/${realmId}/users/${userId}/delete`).then(res=>res.data)
    }

    getUserNotInRealm(realmId:string){
        return axiosClient.get(`${API_BASE_URL}/realms/${realmId}/users/invitation-list`).then(res=>res.data)
    }

    sendUserInvitations(realmId:string, users?:{id:string,email:string}[]){
        return axiosClient.post(`${API_BASE_URL}/realms/${realmId}/invitate-users`,{
            users
        }).then(res=>res.data);
    }

    updateInvitationLetter(realmId:string, squidexId:string, status:boolean){
        return axiosClient.post(`${API_BASE_URL}/realms/${realmId}/invitation-update`,{
            squidexId,
            status,
        }).then(res=>res.data).catch(err=>{
            console.log(err);
        });
    }
}
