import { roleInterface } from '../components/enum/roleInterface';
import { API_BASE_URL } from '../constants';
import { axiosClient } from '../utilities/CustomAxios';

export default class RolesService {
    getAllRoles(){
        return axiosClient.get(`${API_BASE_URL}/roles`,{
            headers: {
                //'Authorization': `Bearer ${localStorage.getItem('token')}`,
                //'x-api-token': `Bearer ${localStorage.getItem('token')}`Bearer ${localStorage.getItem('token')}`
            }
        })
        .then((response) => response.data);
    }
    getAllRolesByRealm(realmId: string){
        return axiosClient.get(`${API_BASE_URL}/realms/${realmId}/roles`,{
            headers: {
                //'Authorization': `Bearer ${localStorage.getItem('token')}`,
                //'x-api-token': `Bearer ${localStorage.getItem('token')}`Bearer ${localStorage.getItem('token')}`
            }
        })
        .then((response) => response.data);
    }
    getRoleById(id: string){
        return axiosClient.get(API_BASE_URL + '/roles/id/' + id, {
            headers: {
                //'Authorization': `Bearer ${localStorage.getItem('token')}`,
                //'x-api-token': `Bearer ${localStorage.getItem('token')}`Bearer ${localStorage.getItem('token')}`
            }
        })
        .then((response) => response.data);
    }
    getRolesByName(name: string){
        return axiosClient.get(API_BASE_URL + '/roles/name/' + name, {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + localStorage.getItem('token'),
                // 'x-api-token': 'Bearer ' + localStorage.getItem('token'),
            },
        }).then((response) => response.data);
    }

    updateRole(name:string, inputData: roleInterface){
        return axiosClient.put(`${API_BASE_URL}/roles/${name}/edit`,inputData,{
            headers:{
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + localStorage.getItem('token'),
                // 'x-api-token': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then((response) => response.data);
    }

    createRole(inputData:roleInterface){
        return axiosClient.post(`${API_BASE_URL}/roles/create`,inputData,{
            headers:{
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + localStorage.getItem('token'),
                // 'x-api-token': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then((response) => response.data);
    }

    deleteRole(roleName:string){
        return axiosClient.delete(`${API_BASE_URL}/roles/${roleName}/delete`,{
            headers:{
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + localStorage.getItem('token'),
                // 'x-api-token': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then((response) => response.data);
    }

    deleteRoleInRealm(realmId:string , roleId:string){
        return axiosClient.delete(`${API_BASE_URL}/realms/${realmId}/roles/${roleId}/delete`,{
            headers:{
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + localStorage.getItem('token'),
                // 'x-api-token': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then((response) => response.data);
    }

    searchRole(realmId: string, searchStr:string){
        return axiosClient.get(`${API_BASE_URL}/realms/${realmId}/roles/search/${searchStr}`).then(res=>res.data);
    }

    createRoleInRealm(realmId:string,inputData:roleInterface){
        return axiosClient.post(`${API_BASE_URL}/realms/${realmId}/roles/create`,inputData,{
            headers:{
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + localStorage.getItem('token'),
                // 'x-api-token': 'Bearer ' + localStorage.getItem('token'),
            }
        }).then((response) => response.data);
    }
}
