import axios from "axios";
import { API_BASE_URL } from "../constants";
import { axiosClient } from "../utilities/CustomAxios";
// import { axiosClient } from "../utilities/CustomAxios";

export var EntitiesServices = {
    getSpaceInstances: (spaceId: string) => {
        return axios.get(`${API_BASE_URL}/instances/space/${spaceId}`).then(res => res.data);
    },

    getSpaceInstancesFromRoomID: (roomID: string) => {
        return axios.get(`${API_BASE_URL}/instances/room/${roomID}`).then(res => res.data);
    },

    getStreamableScreen: (spaceId: string) => {
        return axios.get(`${API_BASE_URL}/instances/space/${spaceId}/streamable`).then(res => res.data);
    },

    getStreamableScreenFromRoomID: (roomId: string) => {
        return axios.get(`${API_BASE_URL}/instances/room/${roomId}/streamable`).then(res => res.data);
    },

    getBuildingNPCByBuildingId: (buidlingId:string)=>{
        return axiosClient.get(`${API_BASE_URL}/buildings/${buidlingId}/npcs`).then(res=>res.data)
    },
}