import axios from 'axios';
import { peopleId, SQUIDEX_APP, SQUIDEX_URL } from '../constants';
import _ from 'lodash';

export default class SquidexService {
    getSquidexSpaceById(id:string){
        return axios.get(`${SQUIDEX_URL}/api/content/${SQUIDEX_APP}/spaces/`,{
            params:{
                "$search":`"${id}"`
            }
        }).then(res=>{
            return res.data
        })
    }

    getSquidexUserById(id: string){
        return axios.get(`${SQUIDEX_URL}/api/content/${SQUIDEX_APP}/user`)
        .then( res => {
            return _.find(res.data.items, {data: {id: {iv: id}}})
        })
    }

    //---------------------------ASSETS------------------------

    getAssetsFolders(id: string){
        return axios.get(`${SQUIDEX_URL}/api/apps/${SQUIDEX_APP}/assets/folders`,{
            params: {
                parentId:id,
            }
        }).then(res=>{
            return res.data;
        })
    }

    getAssetsByFolderId(folderId:string, filterType:string=""){
        return axios.get(`${SQUIDEX_URL}/api/apps/${SQUIDEX_APP}/assets`,{
            params:{
                parentId:folderId,
                // $filter:"type eq '"+filterType+"'",
                $filter:filterType
            }
        }).then(res=>{
            return res.data;
        })
    }

    //---------------------------BANNERS------------------------
    getBannersByTags(tagString: string){
        return axios.get(`${SQUIDEX_URL}/api/content/virtualspace/banners/`,{
            params: {
                $filter: "data/space/iv eq '" + tagString + "'",
            }
        }).then(res=>res.data)
    }

    //-------------------------CHARACTER------------------------
    getCharactersList(){
        return axios.get(`${SQUIDEX_URL}/api/content/virtualspace/characters`,{
            // params: {
            //     $filter:"data/gender/iv eq 'Image'",
            // }
        }).then(res=>res.data)
    }
}