import React, { useState, useCallback, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import { classNames } from 'primereact/utils';
import { CSSTransition } from 'react-transition-group';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import styles from './App.module.scss'

const AppSubmenu = (props: any) => {
	const locationStr = useLocation();
	const [activeIndex, setActiveIndex] = useState(null);
	const [activeIndexs, setActiveIndexs] = useState<Number[]>([]);

	const onClick = (index:Number ) =>{
		let _selectedIndexs = [...activeIndexs];
		if(_selectedIndexs.includes(index)){
			for (let i = 0; i < _selectedIndexs.length; i++) {
				const selectedField = _selectedIndexs[i];

				if (selectedField === index) {
					_selectedIndexs.splice(i, 1);
					break;
				}
			}
			setActiveIndexs([..._selectedIndexs])
		}else{
			setActiveIndexs([..._selectedIndexs, index])
		}
	}

	const onMenuItemClick = (event: any, item: any, index: any) => {
		//avoid processing disabled items
		if (item.disabled) {
			event.preventDefault();
			return true;
		}

		if (props.root && props.onRootItemClick) {
			props.onRootItemClick({
				originalEvent: event,
				item: item
			});
		}

		//execute command
		if (item.command) {
			item.command({ originalEvent: event, item: item });
			event.preventDefault();
		}

		if (item.items) {
			setActiveIndex(activeIndex === index ? null : index);
		}

		if (props.onMenuItemClick) {
			props.onMenuItemClick({
				originalEvent: event,
				item: item
			});
		}

		
	}

	const onKeyDown = (event: any, item: any, index: any) => {
		if (event.key === 'Enter') {
			onMenuItemClick(event, item, index);
		}
	}

	const onMenuItemMouseEnter = (index: any) => {
		if (props.root && props.menuActive && isHorizontal() && !isMobile()) {
			setActiveIndex(index)
		}
	}

	const isHorizontalOrSlim = useCallback(() => {
		return (props.layoutMode === 'horizontal' || props.layoutMode === 'slim');
	}, [props.layoutMode]);

	const isMobile = useCallback(() => {
		return window.innerWidth < 1025;
	}, []);

	useEffect(()=>{
		let indexs = [...activeIndexs]
		if(props.items.length>0){
			props.items.forEach((item:{items:[]}, index:number) =>{
				item?.items?.map((i:{to:string|String})=>{
					if(i.to.toString() === locationStr.pathname){
						indexs.push(index);
					}
				})
			})
		}
		setActiveIndexs(indexs)
	},[locationStr])
	useEffect(() => {
		if (!props.menuActive && isHorizontalOrSlim() && !isMobile()) {
			setActiveIndex(null);
		}
	}, [props.menuActive, isHorizontalOrSlim, isMobile]);

	const isHorizontal = () => {
		return (props.layoutMode === 'horizontal');
	}

	const isSlim = () => {
		return props.layoutMode === 'slim';
	}

	const renderLinkContent = (item: any) => {
		let submenuIcon = item.items && <i className="pi pi-angle-down layout-submenu-toggler"></i>;
		let badge = item.badge && <Badge value={item.badge} className="menuitem-badge"/>

		return (
			<React.Fragment>
				<i className={classNames(item.icon, 'layout-menuitem-icon')}></i>
				<span className="menuitem-text">{item.label}</span>
				{badge}
				{submenuIcon}
			</React.Fragment>
		);
	}

	const renderLink = (item: any, i: number) => {
		let content = renderLinkContent(item);
		let linkStyle = classNames(item.className, 'p-ripple');
		if (item.to) {
			return (
				<NavLink 
				activeClassName='router-link-active'
				to={item.to}
				onClick={(e) => onMenuItemClick(e, item, i)} exact role="menuitem"
				target={item.target} 
				onMouseEnter={(e) => onMenuItemMouseEnter(i)}
				className={`${linkStyle} ${styles.navLink} ${locationStr.pathname == item.to && styles.active}`}>{content}
					<Ripple />
				</NavLink>
			)
		} else {
			return (
				<a className={linkStyle} role="menuitem" href={item.url} tabIndex={item.url ? undefined : 0}
					onClick={(e) => onMenuItemClick(e, item, i)} target={item.target} onKeyDown={(e) => onKeyDown(e, item, i)}
					onMouseEnter={(e) => onMenuItemMouseEnter(i)}
					>
					{content}
					<Ripple />
				</a>
			);

		}
	}

	let items = props.items && props.items.map((item: any, i: number) => {
		let active = activeIndex === i;
		let styleClass = classNames(item.badgeStyleClass, { 'active-menuitem': active }, { 'layout-root-menuitem': props.root });
		let tooltip = props.root && <div className={`layout-menuitem-root-text ${styles.menuItemMain}`} style={{ textTransform: 'uppercase', color: 'white' }}>
			{!props.menuActive && props.layoutMode !== 'static' && !isMobile() && <i className="pi pi-ellipsis-h"></i>}
			{item.label}
			{!(!props.menuActive && props.layoutMode !== 'static' && !isMobile()) && (activeIndexs.includes(i) ? <i className="pi pi-angle-up"></i> : <i className="pi pi-angle-down"></i>)}
		</div>;
		return <li className={`${styleClass} ${styles.layoutRootMenuItem}`} key={i} role="none"
		onClick={()=>{
			onClick(i)
		}}>
			{renderLink(item, i)}
			{tooltip} 
			{activeIndexs.includes(i) && <CSSTransition classNames="layout-submenu-container" timeout={{ enter: 400, exit: 400 }} in={item.items && (props.root && !((isHorizontal() || isSlim()) && !isMobile && (!isSlim() || (isSlim() && activeIndex !== null))) ? true : active)} unmountOnExit>
				<AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick}
					layoutMode={props.layoutMode}
					menuActive={props.menuActive} parentMenuItemActive={active} />
			</CSSTransition>}
		</li>
	});

	return <ul role="menu" className={`${props.className} ${styles.submenu}`}>{items}</ul>;
}

const AppMenu = (props: any) => {

	return (
		<AppSubmenu items={props.model} className={`layout-menu ${styles.layoutMenu}`} menuActive={props.active}
			onMenuItemClick={props.onMenuItemClick} onRootItemClick={props.onRootMenuItemClick}
			root={true} layoutMode={props.layoutMode} parentMenuItemActive={true} />
	);

}

export default AppMenu;
