import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText, InputTextProps } from "primereact/inputtext";
import styles from './userSpaceForm.module.scss'
import React, { useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import internal from "stream";

interface editForm {
  name: string,
  space_name: string,
  room_code: string,
  status: string,
}

export const FormTest = () => {
  const [form, setForm] = React.useState<Partial<editForm>>({})
  const [checkFilled, setCheckFilled] = React.useState<boolean>(true)

  const statusOption = [
    {label: 'Active', value: 'Active'},
    {label: 'UnActive', value: 'UnActive'}
  ]

  const handleSubmit = () => {
    // call UpdateUserSpace API
    console.log(form)
  }

  return (
    <div className={`${styles.userSpaceForm}`}>
        <h3>User Space Edit</h3>
        <form onSubmit={handleSubmit}>
          <div className={`${styles.formField}`}>
            <div className={`${styles.inputField} field`}>
              <label className="p-label block">User name *</label>
              <div className="p-inputgroup">
                <InputText required id="username" value={form.name} onChange={(e) => setForm({...form, name: e.target.value})}/>
              </div>
            </div>
            <div className={`${styles.inputField} field`}>
              <label className="p-label block">Space Name *</label>
              <div className="p-inputgroup">
                <InputText required id="spacename" value={form.space_name} onChange={(e) => setForm({...form, space_name: e.target.value})}/>
              </div>
            </div>
            <div className={`${styles.inputField} field`}>
              <label className="p-label block">Room Code *</label>
              <div className="p-inputgroup">
                <InputText required id="roomcode" value={form.room_code} onChange={(e) => setForm({...form, room_code: e.target.value})}/>
                <Button icon="pi pi-replay" className="p-button-warning"/>
              </div>
            </div>
            <div className={`${styles.dropDownField} field`}>
              <label className="p-label block">Status *</label>
              <div className="p-inputgroup">
                <Dropdown required value={form.status} options={statusOption} onChange={(e) => setForm({...form, status: e.target.value})} optionLabel="label" editable />
              </div>
            </div>
          </div>
          <div className={`${styles.buttonGroup} p-d-flex justify-content-end `}>
            <Button style={{color:'var(--text-color)'}} label="Cancel" className="p-button-outlined" />
            <Button type="submit" style={{backgroundColor:'var(--blue-400)'}} label="Add User Data" className="p-button-secondary" />
          </div>
        </form>
    </div>
  )
}