import _ from "lodash";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { DataView } from "primereact/dataview";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { assetsSetOptions, gender, peopleId, SQUIDEX_URL } from "../../constants";
import CustomerService from "../../service/CustomerService";
import SquidexService from "../../service/SquidexService";
import { instanceOfImageSquidexInterface } from "../../utilities/Functions";
import { folderInterFace, imageSquidexInterface } from "../enum/SquidexInterfaces";
import { UserForm, UserRealmForm, userSkinInfo } from "../enum/userForm";

import tableStyles from '../tableStyle.module.scss';

import styles from './userStyles.module.scss';

type UserOptions = {
    label: string,
    value: UserForm,
}

type squidexInvitationUser = {
    realmID: {
        iv: string
    },
    status: {
        iv: string
    },
    toRealm: {
        iv: string
    },
    userEmail: {
        iv: string
    },
    userId: {
        iv: string
    },
}

export default function InviteUser () {

    const realmId = localStorage.getItem("realmId");
    const customersService = new CustomerService;
    const [users, setUsers] = useState<Array<UserOptions>>([]);
    const [filterUsers, setFilterUsers] = useState<Array<UserOptions>>([]);
    const [selectedUsers, setSelectedUsers] = useState<Array<UserForm>>([]);
    const [numRows, setNumRows] = useState<number>(10);

    const toast = useRef<Toast>(null);
    const rowNumSelections = [
        10,
        20, 
        50,
        100,
    ]
    useEffect(()=>{
        (async()=>{
            customersService.getUserNotInRealm(realmId!).then(res=>{
                setUsers(res.map((i:UserForm)=>{
                    return{
                        value: i,
                        label: i.display_name || i.username
                    }
                }))
                setFilterUsers(res.map((i:UserForm)=>{
                    return{
                        value: i,
                        label: i.display_name || i.username
                    }
                }))
            })
        })()
    },[])


    const userTemplate = (option:UserOptions) => {
        return (
            <div className="flex flex-1">
                {/* <img alt={option.name} src="images/flag/flag_placeholder.png" onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className={`flag flag-${option.code.toLowerCase()}`} /> */}
                <div className={`flex-1`}>{option.label}</div>
            </div>
        );
    }

    const panelFooterTemplate = () => {
        const selectedItems = selectedUsers;
        const length = selectedItems ? selectedItems.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    }

    const onFilter = (filterE:any)=>{
        let filterString = filterE?.filter;
        let usersFiltered:Array<UserOptions>=[];
        if(filterString){
            users.map((user:UserOptions)=>{
                if(user.label.includes(filterString) || user.value.display_name?.includes(filterString) || user.value.username.includes(filterString))
                    usersFiltered.push(user)
            })
            setFilterUsers(usersFiltered || [])
        }else{
            setFilterUsers(users)
        }
    }
    const renderHeaderTable = () => {
        return (
            <div className="flex flex-wrap align-content-center justify-content-between align-items-center">
                <div className={`col-12 md:col-10`}>
                    <h2>User Invitations List</h2>

                </div>
                <div className={`col flex flex-wrap align-items-center align-content-center`}>
                    <p className={`flex-initial flex align-items-center justify-content-center align-content-center`}>
                    Show
                    <Dropdown className={`mx-1 flex-initial flex align-items-center justify-content-center align-content-center `} options={rowNumSelections} value={numRows} onChange={(e:{value:any})=>setNumRows(e.value)}/>
                    Entries
                    </p>
                </div>
            </div>
        )
    }

    const onInvite =() =>{
        if(selectedUsers.length>0){
            let userSendInfo = selectedUsers.map((i:UserForm)=>{return {id:i.id!, email:i.email}})
            customersService.sendUserInvitations( realmId!,userSendInfo).then(res=>{
                let arrRes = [];
                // res.map((item:{data?:squidexInvitationUser,statusCode?:number})=>{
                //     if(item?.statusCode){
                //         let foundUsers = _.find(selectedUsers, user=>user.id == item)
                //         // arrRes.push()
                //     }
                // })
                let notInvite:UserForm[] = selectedUsers.filter(user=>{
                    return _.findIndex(res, (i:{data?:squidexInvitationUser,statusCode?:number})=> i?.data?.userId?.iv == user.id) < 0 && user
                }) || []
                if(notInvite?.length>0){
                    toast.current?.show({severity:'error', summary: 'Send Invitation Letter Failed', life: 3000, closable:false})
                }
                toast.current?.show({severity:'success', summary: 'Send Invitation Letter Successfully', life: 3000, closable:false})
                setSelectedUsers(notInvite)
            }).catch(err=>{
                console.log(err)
                toast.current?.show({severity:'error', summary: 'Send Invitation Letter Failed', life: 3000, closable:false})
            })
        }
    }

    const onRemove = (user:UserForm) =>{
        let arr = [...selectedUsers];
        var indexOfUser = arr.indexOf(user);
        if(indexOfUser>-1){
            arr.splice(indexOfUser, 1)
            setSelectedUsers(arr)
        }
    }
    
    return(<>
        <div className={`card ${styles.createSpaceForm}`}>
        <Toast ref={toast}/>
            <div className={`grid ${styles.formField} flex-1`}>
                <div className={`col-12 md:col-10 justify-content-center ${styles.inputField}`}>
                    <MultiSelect
                    value={selectedUsers}
                    options={filterUsers}
                    onChange={(e) => setSelectedUsers(e.value)}
                    // optionLabel="Invite User"
                    placeholder="Select User's invitation"
                    filter
                    showClear
                    resetFilterOnHide
                    onFilter={onFilter}
                    // className="w-100"
                    selectedItemsLabel={'{0} users selected'}
                    style={{
                        width:'100%',
                    }}
                    itemTemplate={userTemplate}
                    maxSelectedLabels={1} 
                    // selectedItemTemplate={""}
                    panelFooterTemplate={panelFooterTemplate}
                    />
                </div>
                <Button className={`col-12 md:col-2 my-2`} 
                    label={'Invite'}
                    onClick={()=>{
                        onInvite()
                    }}
                />
            </div>
            {selectedUsers && selectedUsers.length > 0 &&
            <div className={`my-4`}>
                {/* <div
                className="flex-1">
                    <h2>User Invitations List</h2>
                </div> */}
                <DataTable
                header={renderHeaderTable()}
                value={selectedUsers}
                paginator
                rows={numRows}
                paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
                currentPageReportTemplate={"Showing {first} to {last} of {totalRecords}"}
                responsiveLayout="scroll"
                emptyMessage=""
                className={`spaces ${tableStyles.table}`}
                >
                    <Column header= "Username" field="username" sortable/>
                    <Column header= "First Name" field="firstName" sortable/>
                    <Column header= "Last Name" field="lastName" sortable/>
                    <Column header= "E-mail" field="email" sortable/>
                    <Column header="" style={{textAlign: 'center'}} body={(item:UserForm) => {
                        return (
                            <Button label={'Remove'} className={`mr-2 mb-2 p-button-text ${tableStyles.btn}`} onClick={()=>onRemove(item)}/>
                        )
                    }}
                    />
                </DataTable>
            </div>}
        </div>
    </>)

}