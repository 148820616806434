import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import SpacesService from "../../service/SpacesService";
import styles from "./createSpace.module.scss"
import { ObjectType } from "typescript";
import CustomerService from "../../service/CustomerService";
import { SpaceForm } from "../enum/SpaceInterface";
import globalStyles from '../pageStyle.module.scss';

export const CreateSpace = () => {
  const accessToken = localStorage.getItem("token")
  const spacesService = new SpacesService();
  const customerService = new CustomerService();
  const [buildingInfo, setBuildingInfo] = React.useState<any[]>([])
  const [ownerInfo, setOwnerInfo] = React.useState<any[]>([])
  const [spaceCreateForm, setSpaceCreateForm] = React.useState<Partial<SpaceForm>>({})
  const [ownerId, setOwnerId] = React.useState<string>("default")
  const fileUploadRef = useRef(null);

  const roomOption = buildingInfo.map((e) => {
    return {
      label: e._id, value: e
    }
  })

  const ownerOption = ownerInfo.map((e) => {
    return {
      label: e.username, value: e
    }
  })
  
  const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
  const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
  const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

  useEffect(() => {
    if (accessToken)
    spacesService.getBuildingInfo(accessToken).then(res => setBuildingInfo(res.buildings))
    customerService.getCustomers().then(res => setOwnerInfo(res))
  },[])

  const handleSubmit = () => {
    spacesService.creatNewSpace(spaceCreateForm, ownerId).then(res => console.log(res))
    window.location.href = "#/spaces-management/"
  }
  

  return (
    <div className={`${styles.createSpaceForm}`}>
      <div className={`${globalStyles.header}`}>
        <div className={`col`}>
          <h1>Space</h1>
          <p>You can manage all the space data in this page easier</p>
        </div>
      </div>
      <div className={`${globalStyles.bodyWrapper}`}>
        <div className={`card ${globalStyles.content}`}>
        <div className={`flex justify-content-between flex-wrap ${styles.header}`}>
        <h3 className=" align-items-center justify-content-center font-bold">Add New Space</h3>
        <Button type="button" label="Back to space list" className={`align-items-center justify-content-center ${styles.button}`} 
          onClick={()=>{window.location.href = "#/spaces-management/"}}
        />
      </div>
      <form onSubmit={handleSubmit}>
        <div className={`flex flex-column ${styles.formField}`}>
          <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
            <label className="p-label block">Space Name *</label>
            <div className="p-inputgroup">
              <InputText placeholder="eg: Chick-fil-A" className={`${styles.inputText}`} required id="spacename" value={spaceCreateForm.space_name} onChange={(e) => setSpaceCreateForm({...spaceCreateForm, space_name: e.target.value})}/>
            </div>
          </div>
          <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
            <label className="p-label block">Description *</label>
            <div className="p-inputgroup">
            <InputText placeholder="eg: Chicken nugget" className={`${styles.inputText}`} required id="description" value={spaceCreateForm.description} onChange={(e) => setSpaceCreateForm({...spaceCreateForm, description: e.target.value})}/>
            </div>
          </div>
          <div className={`field align-items-center justify-content-center my-3 ${styles.dropDownField}`}>
            <label className="p-label block">Room *</label>
            <div className="p-inputgroup">
              <Dropdown placeholder="Select a room" className={`${styles.inputText}`} value={spaceCreateForm.room} options={roomOption} 
              onChange={(e) => 
                {
                  setSpaceCreateForm({...spaceCreateForm, room: e.target.value, room_code: e.target.value.code});
              }} optionLabel="label" editable />
            </div>
          </div>
          <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
            <label className="p-label block">Room Code *</label>
            <div className="p-inputgroup">
              <InputText className={`${styles.inputText}`} disabled id="roomcode" value={spaceCreateForm.room_code}/>
            </div>
          </div>
          <div className={`field align-items-center justify-content-center my-3 ${styles.dropDownField}`}>
            <label className="p-label block">Set Owner *</label>
            <div className="p-inputgroup">
              <Dropdown placeholder="Select Owner" className={`${styles.inputText}`} value={spaceCreateForm.owner} options={ownerOption} 
              onChange={(e) => 
                {
                  setSpaceCreateForm({...spaceCreateForm, owner: e.target.value});
                  console.log(e.target.value.id)
                  setOwnerId(e.target.value.id)
              }} optionLabel="label" editable />
            </div>
          </div>
          <div className={`field align-items-center justify-content-center my-3 ${styles.uploadFile}`}>
            <label className='p-label block'>Banner Picture *</label>
            <FileUpload ref={fileUploadRef} multiple accept="image/*" maxFileSize={1000000} customUpload={true} className={`${styles.fileUpload}`}
            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} uploadHandler={(e) => {setSpaceCreateForm({...spaceCreateForm, banner: e})}} />
          </div>
        </div>
        <div className={`${styles.buttonGroup} flex justify-content-end flex-wrap`}>
          <Button label="Cancel" className={`p-button-outlined align-items-center justify-content-center m-2 ${styles.cancelButton}`} />
          <Button type='submit' label="Add User Data" className={`p-button-secondary align-items-center justify-content-center m-2 ${styles.saveButton}`}/>
        </div>
      </form>
        </div>
      </div>
    </div>
  )
}