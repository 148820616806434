export const API_BASE_URL = process.env.REACT_APP_SERVER_PATH
export const specialCharTester = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
export const emailTester =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const SQUIDEX_URL = 'https://content-sea.fromlabs.com'
export const SQUIDEX_APP = 'virtualspace'
export const SQUIDEX_ACC_ACCESS_TOKEN= 'eyJhbGciOiJSUzI1NiIsImtpZCI6IlR1QzBfRnhzNmF5Ny1PUW1jb1NsM1EiLCJ0eXAiOiJhdCtqd3QifQ.eyJzdWIiOiI2MGEyMTE0MWQyN2JjYWFjMTAyMmJkNzkiLCJuYW1lIjoidHJhbi5kYW5nQGZyb21sYWJzLmNvbSIsImVtYWlsIjoidHJhbi5kYW5nQGZyb21sYWJzLmNvbSIsIm9pX3Byc3QiOiJzcXVpZGV4LWZyb250ZW5kIiwib2lfYXVfaWQiOiJkODg2YzRlNi1jODViLTRlNjEtYThhNS1hY2Q4ZjI2Mjk0MjciLCJjbGllbnRfaWQiOiJzcXVpZGV4LWZyb250ZW5kIiwib2lfdGtuX2lkIjoiNmEzN2UyNjctZjNlNS00YTFmLWIyNDktOGY1MTYzMDM2NTljIiwiYXVkIjoic2NwOnNxdWlkZXgtYXBpIiwic2NvcGUiOiJzcXVpZGV4LWFwaSBvcGVuaWQgcHJvZmlsZSBlbWFpbCBwZXJtaXNzaW9ucyIsImV4cCI6MTY2NjIzODcyMCwiaXNzIjoiaHR0cHM6Ly9jb250ZW50LXNlYS5mcm9tbGFicy5jb20vaWRlbnRpdHktc2VydmVyIiwiaWF0IjoxNjYzNjQ2NzIwfQ.KIYgw5xJOgx_1KMXAR5OzO5Zo8foQ6Aa6xpYZAyV4meHD5JF0feOvDtFBt_iDnJfpoeTbEjPWetxteH9P7sZ5fEWhvduenriZaaXm5U7nZQrZdAjSCCc5K5NBU21GoM_LOJ9wxmw9VSE1oQZJ2dHIivontTudYIgNLiCLKoaVtJSTrLPcM3yluHBoiVXk4Kz19sOqHoUUj4rMOsubDScNJJb1hHLSNW1FEfYChwp8sFuv8Ibjgc6CKrvU9mIzDRp_TfuNgtYEoqpmo-8yUjzaN_oWDf99ka_uFrtfWy34F_FDrvDMdoqp0vzptl9zQ-LDN7vzOlB11f75GC6LLr0GA'

export const assetsSetOptions = [
  'default',
  'surge'
]

export const gender = [
  {
    value:'female',
    label:'Female'

  },
  {
    value:'male',
    label:'Male'

  },
]

export const peopleId = '211f983b-c67e-4218-9ea9-8d7a7ca3084a'
export const itemsFolderId = "622f7eac-6cfb-4e64-a37c-54626e190dcd"

export const animationsNameDef=["Idle", "StandingGreeting","TwistDance"]