import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import CustomerService from '../service/CustomerService';
import { useEventListener } from 'usehooks-ts';
import SquidexService from '../service/SquidexService';

interface Props {
	setIsAuthenticate: React.Dispatch<React.SetStateAction<boolean>>
	setAccessToken: React.Dispatch<React.SetStateAction<String | undefined>>
	setUserId: React.Dispatch<React.SetStateAction<String | undefined>>
}

export const Login: React.FC<Props> = ({ setIsAuthenticate, setUserId, setAccessToken }) => {
	const customerService = new CustomerService()

	const [userLoginInfo, setUserLoginInfo] = useState({
		username: '',
		password: '',
	})

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.currentTarget.value;
		const title = e.target.title
		setUserLoginInfo({ ...userLoginInfo, [title]: newValue });
	}

	const history = useHistory();
	const handleLogin = async () => {
		try {
			const user = await customerService.postUserLogin(userLoginInfo.username, userLoginInfo.password)

			if(user.token) {
				setAccessToken(user.token)
				setIsAuthenticate(true);
				setUserId(user.userId)
				localStorage.setItem('token', user.token)
				localStorage.setItem('user', user.userId)
				localStorage.setItem('username', user.username)
				if (user?.realms?.length >= 2) {
					// localStorage.setItem('realmId', id);
					history.push("/realm-selection")
				} else if (user?.realms?.length === 1) {
					history.push("/")
				} else {
					history.push('/error-page/403')
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEventListener('keyup', function (event: KeyboardEvent) {
		if (event.key === "Enter") {
			event.preventDefault();
			document.getElementById("loginBtn")?.click();
		}
	})

	// window.addEventListener("keyup", function (event) {
	// 	if (event.key === "Enter") {
	// 		event.preventDefault();
	// 		this.document.getElementById("loginBtn")?.click();
	// 	}
	// })

	return (
		<div className="login-body">
			<div className="login-panel p-fluid">
				<div className="login-panel-header">
					<img src="assets/layout/images/our3d-logo.png" alt="serenity-react" />
				</div>
				<div className="login-panel-content">
					<div className="grid">
						<div className="col-12">
							<h1>Sign-in to Serenity Network</h1>
						</div>
						<div className="col-12">
							<span className="p-float-label">
								<InputText
									title='username'
									onChange={handleOnChange}
								/>
								<label>Username</label>
							</span>
						</div>
						<div className="col-12">
							<span className="p-float-label">
								<InputText
									type={'password'}
									title='password'
									onChange={handleOnChange}
								/>
								<label>Password</label>
							</span>
						</div>
						<div className="col-12">
							<Button label="Sign In" id='loginBtn' onClick={handleLogin} style={{ width: '100%' }} />
						</div>
						<div className="col-12">
							Don't have an account? <a href="/#">Sign Up</a> now.
						</div>
					</div>
				</div>
			</div>
			{/* <button onClick={() => handleLogin()}>Login</button> */}
		</div>
	)

}
