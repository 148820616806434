
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setTimeout } from 'timers';
import RolesService from '../../service/RolesService';
import { roleInterface } from '../enum/roleInterface';
import { RouterNameParams } from '../enum/routerParams';
import RolesManagementForm from '../form/RoleManagementForm';
import styles from './userStyles.module.scss';
import globalStyles from '../pageStyle.module.scss';
import AppBreadcrumb from '../../AppBreadcrumb';
import CustomBreadcrumb from '../items/BreadcrumbComponent';

const CreateRole = () =>{
    const [roleInfo, setRoleInfo] = useState<roleInterface>({
        name:'',
        permissions: []
    })
    const roleServices = new RolesService();
    const toast = useRef<Toast>(null);
    let {name} = useParams<RouterNameParams>();
    const realmId = localStorage.getItem("realmId"); 
    
    // useLayoutEffect(()=>{
    //     roleServices.getRolesByName(name).then(res=>{
    //         setRoleInfo(res);
    //     })
    // },[])

    const handleSubmit = (inputData: roleInterface)=>{

        roleServices.createRoleInRealm(realmId!,{...inputData, permissions:[...inputData.permissions]}).then(res=>{
            const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
                window.location.href="/#/roles"
              }, 3500);
              toast.current?.show({severity:'success', summary: 'Create Role Successfull', detail:res.status?.message, life: 3000, closable:false})
              clearTimeout(timer);
        }).catch(err=>{
            toast.current?.show({severity:'error', summary: 'ERROR', detail:"Error occur when created!", life: 3000, closable:false})
            
        });
    }

    return(<>
    <Toast ref={toast}/>
    <div className={`grid mx-1 mb-5 ${globalStyles.header} ${styles.header}`}>
        <div className={`col-12 p-0 m-0`}>
            <Button type="button" label="Back to users list" className={`align-items-center justify-content-center mx-0 ${styles.returnButton}`} 
                onClick={()=>{window.location.href = "#/users/"}}
            />
        </div>
        <div className={`col-12`}>
          <h1>ROLES</h1>
          <p>You can manage all the Roles data in this page easier.</p>
          <CustomBreadcrumb/>
        </div>
    </div>
    <div className={`${globalStyles.editBodyWrapper}`}>
        <div className={`card ${globalStyles.content} ${styles.createSpaceForm}`}>
            <div className={`flex justify-content-between flex-wrap ${styles.header}`}>
                <h3 className=" align-items-center justify-content-center font-bold">Add Role Data</h3>
                {/* {!getUserError && <Button type="button" label="Back to user list" className={`align-items-center justify-content-center ${styles.button}`} onClick={()=>{window.location.href="/#/users-management"}}/>} */}
            </div>
            <RolesManagementForm data={roleInfo} handleSubmit={handleSubmit} isCreated={true}/>
            {/* <UsersManagementForm data={userInfo} handleSubmit={handleSubmit} isCreated={false}/> */}
        </div>
    </div>
    </>)
}

export default CreateRole;