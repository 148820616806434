import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import CustomerService from "../service/CustomerService";
import Filter from "./form/Filter";
// import * as style from "./"
import './SpacesManagement.module.scss';
import tableStyles from './tableStyle.module.scss';
import '../../node_modules/primeflex/primeflex.css'
import { Dropdown } from "primereact/dropdown";
import Search from "./form/Search";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { UserForm } from "./enum/userForm";
import SpacesUsersService from "../service/SpacesUsersService";

const SpacesUsersManagement = () => {
  const accessToken = localStorage.getItem("token")
  const spaceUserService = new SpacesUsersService();
  const [spacesUsersInfo, setSpacesUsersInfo] = useState([])
  const toast = useRef<Toast>(null);
  
  const [selectedSearch, setSelectedSearch] = useState('')

  const EntriesOptions = [
    10,
    50,
    100,
  ]
  const [selectedEntries, setSelectedEntries] = useState(10)
  const [visibleFilter, setVisibleFilter] = useState(false)
  useEffect(() => {
    if (accessToken){
        
    }
  },[])

  const onClear = () => {
    setSelectedSearch('')
  }

  useEffect(()=>{
    if(selectedSearch!=''){
      
    }else{
      
    }
  },[selectedSearch])

  const renderHeaderTable = () => {
    return (
        <div className="flex flex-wrap align-content-center justify-content-between align-items-center">
          <div className={`col flex flex-wrap align-items-center align-content-center`}>
            <p className={`flex-initial flex align-items-center justify-content-center align-content-center ${tableStyles.entries}`}>
              Show
              <Dropdown className={`mx-1 flex-initial flex align-items-center justify-content-center align-content-center `} options={EntriesOptions} value={selectedEntries} onChange={(e:{value:any})=>setSelectedEntries(e.value)}/>
              Entries
            </p>
          </div>
          <Search onSearch={setSelectedSearch} searchValue={selectedSearch}/>
        </div>
    )
  }

  return(
    <>
      <div className={`grid ${tableStyles.header} mx-1 mb-5`}>
        <ConfirmDialog className={`${tableStyles.dialog}`}/>
        <div className={`col`}>
          <h1>SPACES-USERS</h1>
          <p>You can manage all the spaces-users data in this page easier.</p>
        </div>
        <div className={`flex flex-wrap align-items-center justify-content-end`}>
          <Button onClick={()=>{window.location.href = '/#/space-user/create'}} label="New spaces Users" icon="pi pi-plus" iconPos="right"/>
        </div>
      </div>
      <div className={`card ${tableStyles.content}`}>
        <Toast ref={toast}/>
        <div className={`flex justify-content-between flex-wrap ${tableStyles.header}`}>
          <h3 className=" align-items-center justify-content-center font-bold m-2">Spaces users List</h3>
          <div className={`flex justify-content-between flex-wrap card-container purple-container ${tableStyles.buttonGroup}`}>
            <Button style={{ color: 'var(--surface-0)', backgroundColor: ' #001E4A'}}type="button" label="Clear search" className={`align-items-center justify-content-center m-2`} onClick={()=>onClear()}/>
            <Button type="button" label="Filter" icon="pi pi-filter-fill"  className={`p-button-outlined align-items-center justify-content-center m-2 ${tableStyles.button}`} onClick={()=>setVisibleFilter(true)}/>
            <Button type="button" label="Export" icon="pi pi-file" className={`p-button-outlined align-items-center justify-content-center m-2 ${tableStyles.button}`} />
          </div>
        </div>
        {/* <DataTable
          removableSort={true}
          header={renderHeaderTable()}
          rows={selectedEntries}
          paginator={true}
          value={spacesUsersInfo}
          responsiveLayout="scroll"
          className={`spaces ${tableStyles.table}`}
          emptyMessage="No Spaces-Users found."
          // paginatorTemplate={template}
          paginatorClassName={`grid ${tableStyles.paginator}`}
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate={"Showing {first} to {last} of {totalRecords}"}
          // footer={renderFooterTable()}
        >
          <Column header= "Username" field="username" sortable/>
          <Column header= "Picture" field="" sortable/>
          <Column header= "E-mail" field="email" sortable/> */}
          {/* <Column header= "Id" body={(id) => {return id.code + "-" + id.roomID}} sortable/> */}
          {/* <Column header= "Status" field="emailVerified" bodyStyle={(item:UserForm)=> {return item.emailValidate}}/> */}
          {/* <Column header="Last Login" body={(item)=>{return item.logRecent?.length > 0 && (item.logRecent[0].date && item.logRecent[0].time) ? moment(`${item.logRecent[0].date} ${item.logRecent[0].time}`, 'DD/MM/YYYY x').format('YYYY-MM-DD hh:mm:ss') :""}} /> */}
          {/* <Column header= "Privated" body={(spaces) => {return ( spaces.isPrivate == true ? <FontAwesomeIcon icon={faCircleDot}/> : <FontAwesomeIcon icon={faCircle}/> )}} />
          <Column header= "Login required" body={(spaces) => {return (spaces.loginRequired == true ? <FontAwesomeIcon icon={faCircleDot}/> : <FontAwesomeIcon icon={faCircle}/> )}} /> */}
          {/* <Column header="" style={{textAlign: 'center'}} body={(item) => {
              const actionItems = [
              // {
              //     label: 'Edit',
              //     command: () => {
              //       window.location.href=`/#/${item.id}/edit`

              //     }
              // },
              {
                  label: 'Delete',
                  command:()=>{
                    
                  }
              }
              ]
              return (
              // <SplitButton model={actionItems} label="View" className={`mr-2 mb-2 ${tableStyles.btnContainer}`} menuButtonClassName={`p-button-text`} buttonClassName={`p-button-text`}/>
              <SplitButton model={actionItems} label="Edit" className={`mr-2 mb-2 ${tableStyles.btnContainer}`} menuButtonClassName={`p-button-text`} buttonClassName={`p-button-text`} onClick={()=>{window.location.href=`/#/user/${item.id}/edit`}}/>
          )}
          }/> */}
        {/* </DataTable> */}
      </div>
    </>
  )
}

export default SpacesUsersManagement
