import styles from './ViewFAQs.module.scss'
import React, { useEffect, useState } from "react";
import FAQService from '../../service/FAQService';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { options } from 'preact';
import { localeOptions } from 'primereact/api';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import { hasBgRendering } from '@fullcalendar/react';
import { faHandLizard, faQ } from '@fortawesome/free-solid-svg-icons';
import _ from "lodash";
import { FAQ, FaqItems, FaqSection } from '../enum/FaqInterface';

export const ViewFAQs = ({spaceID}: any) => {
  const faqService = new FAQService();
  const [displayForm, setDisplayForm] = useState(false);
  const [displaySectionForm, setDisplaySectionForm] = useState(false);
  const [displayFaqForm, setDisplayFaqForm] = useState(false);
  const [sectionId, setSectionId] = useState<string>();
  const [FAQs, setFAQS] = useState<Partial<FAQ>>({})
  const [editFAQS, setEditFAQS] = useState<Partial<FAQ>>({})
  // const [FAQsStatus, setFAQsStatus] = useState<string>()
  const [addFAQs, setAddFAQs] = useState<Partial<FaqItems>>() 
  const [addSection, setAddSection] = useState<Partial<FaqSection>>()
  const [addQnA, setAddQnA] = useState<Partial<FaqItems>>()
  const [createFaq, setCreateFaq] = useState<Partial<FAQ>>()

  useEffect(()=>{
    faqService.getFaqFromSpace(spaceID).then(res => {
      setEditFAQS({
        id: `${res._id}`,
        title: `${res.title}`,
        entityId: `${res.entityId}}`,
        items: res.items
      })
      setFAQS({
        id: `${res._id}`,
        title: `${res.title}`,
        entityId: `${res.entityId}}`,
        items: res.items
      })
    })
  }, [])

  const FAQsCardTemplate = (options: any) => {
    const toggleIcon = options.collapsed ? 'pi pi-plus' : 'pi pi-plus';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;
    const header = `${options.props.header}`
    return (
      <div style={{backgroundColor: '#f8f9fa'}} className={className}>
        <span className={titleClassName}>
          {header}
        </span>
        <button className={options.togglerClassName} onClick={options.onTogglerClick} >
          <span className={toggleIcon}/>
          <Ripple />
        </button>
      </div>
    )
  }

  const handleAddFAQ = () => {
    faqService.createQuestionAndAnswer(sectionId!, addQnA!)
    setDisplayForm(false)
  }

  const handleAddSections = () => {
    faqService.createNewSection(FAQs.id!, addSection!)
    setDisplaySectionForm(false)
  }

  const handleUpdateFAQs = () => {
    faqService.updateFaqFromFaqId(FAQs.id!, FAQs)
  }

  const handleCreateFAQ = () => {
    faqService.createNewFaq(spaceID, createFaq!)
    setDisplayFaqForm(false)
    console.log("create new FAQ with :")
  }

  return (
    <div className={`${styles.faqsBox}`}>
      {
        FAQs.id == "undefined" ? 
        <div className={`flex flex-column ${styles.faqsContent}`}>
          <p>There currently no FAQ available</p>
          <Button label="Add Faq" className={`flex justify-content-center flex-wrap ${styles.button}`} onClick={() => setDisplayFaqForm(true)} />
        </div>
        :
        <div className={`flex flex-column ${styles.faqsContent}`}>
          <div className={`field align-items-center justify-content-center ${styles.inputField}`}>
            <label className="p-label block">Title *</label>
            <InputText className={`w-full ${styles.inputText}`} required id="title" value={FAQs.title} onChange={(e) => {setFAQS({...FAQs, title: e.target.value})}}/>
          </div>
          <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
            <label className="p-label block">Button ID *</label>
            <InputText className={`w-full ${styles.inputText}`} required id="buttonID" value={FAQs.entityId} onChange={(e) => {setFAQS({...FAQs, entityId: e.target.value})}}/>
          </div>
          <div className={`${styles.faqsSection}`}>
            {
              editFAQS && editFAQS.items &&
              editFAQS.items.map(item => (
                <>
                  <Panel header={item.section} headerTemplate={FAQsCardTemplate} toggleable onToggle={(e)=> {setDisplayForm(true); setSectionId(item.sectionId)}} className={`${styles.faqsCard}`}>
                    <Accordion className={`${styles.faqsHolder}`}>
                      {
                        item.list.map(faq => ( 
                          <AccordionTab header={`Question: ${faq.question}`} className={`${styles.faqList}`}>
                            <p>Answer: {faq.answer}</p>
                          </AccordionTab>
                        ))
                      }
                    </Accordion>
                  </Panel>
                </>
              ))
            }
          </div>
          <Button label="Add new section" className={`flex justify-content-center flex-wrap ${styles.button}`} onClick={() => setDisplaySectionForm(true)} />
          { !_.isEqual(editFAQS, FAQs) && <Button label="Save Data" className={`flex justify-content-center flex-wrap mt-3 ${styles.button}`} onClick={handleUpdateFAQs} />}
        </div>
      }
      <Dialog header="Add new question" visible={displayForm} style={{ width: '50vw' }} onHide={() => setDisplayForm(false)}>
        <div className={`${styles.addCouponForm}`}>
          <div className={`flex flex-column ${styles.formContainer}`}>
            <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
              <label className="p-label block">Question</label>
              <div className="p-inputgroup">
                <InputText className={``} id={`Question`}
                  value={addQnA?.question} 
                  onChange={(e) => 
                    setAddQnA({...addQnA, question: e.target.value})
                  }
                  required
                />
              </div>
            </div>
            <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
              <label className="p-label block">Answer</label>
              <div className="p-inputgroup">
                <InputText className={``} id={`answer`}
                  value={addQnA?.answer} 
                  onChange={(e) => 
                    setAddQnA({...addQnA, answer: e.target.value})
                  }
                  required
                />
              </div>
            </div>
            <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
              <label className="p-label block">Number</label>
              <div className="p-inputgroup">
                <InputText className={``} id={`qna-number`}
                  value={addFAQs?.number} 
                  onChange={(e) => 
                    setAddQnA({...addQnA, number: e.target.value})
                  }
                  required
                />
              </div>
            </div>
            <Button type="submit" label="Submit" icon="pi pi-check" onClick={() => {handleAddFAQ()}}/>
          </div>
        </div>
      </Dialog>
      <Dialog header="Add new FAQ section" visible={displaySectionForm} style={{ width: '50vw' }} onHide={() => setDisplaySectionForm(false)}>
        <div className={`${styles.addCouponForm}`}>
          <div className={`flex flex-column ${styles.formContainer}`}>
            <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
              <label className="p-label block">section</label>
              <div className="p-inputgroup">
                <InputText className={``} id={`section-name`}
                  value={addSection?.section} 
                  onChange={(e) => 
                    setAddSection({...addSection, section: e.target.value})
                  }
                  required
                />
              </div>
            </div>
            <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
              <label className="p-label block">section ID</label>
              <div className="p-inputgroup">
                <InputText className={``} id={`section-id`}
                  value={addSection?.sectionId} 
                  onChange={(e) => 
                    setAddSection({...addSection, sectionId: e.target.value})
                  }
                  required
                />
              </div>
            </div>
            <Button type="submit" label="Submit" icon="pi pi-check" onClick={handleAddSections}/>
          </div>
        </div>
      </Dialog>
      <Dialog header="Create new FAQ" visible={displayFaqForm} style={{ width: '50vw' }} onHide={() => setDisplayFaqForm(false)}>
        <div className={`${styles.addCouponForm}`}>
          <div className={`flex flex-column ${styles.formContainer}`}>
            <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
              <label className="p-label block">title</label>
              <div className="p-inputgroup">
                <InputText className={``} id={`section-name`}
                  value={addSection?.section} 
                  onChange={(e) => 
                    setCreateFaq({...createFaq, title: e.target.value})
                  }
                  required
                />
              </div>
            </div>
            <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
              <label className="p-label block">entity Id</label>
              <div className="p-inputgroup">
                <InputText className={``} id={`section-name`}
                  value={addSection?.section} 
                  onChange={(e) => 
                    setCreateFaq({...createFaq, entityId: e.target.value})
                  }
                  required
                />
              </div>
            </div>
            <Button type="submit" label="Submit" icon="pi pi-check" onClick={handleCreateFAQ}/>
          </div>
        </div>
      </Dialog>
    </div>
  )
}