import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from 'react';
import styles from "./userEditFrame.module.scss"
import UsersManagementForm from "../form/UserManagementForm";
import { checkStrings, emailValidate } from "../../utilities/Functions";
import CustomerService from "../../service/CustomerService";
import { Toast } from "primereact/toast";
import { setTimeout } from 'timers';
import { UserForm } from "../enum/userForm";
import CreateUser from "./CreateUser";
import InviteUser from "./InviteUser";
import globalStyles from '../pageStyle.module.scss';
import BreadcrumbComponent from "../items/BreadcrumbComponent";
// interface userCreateForm {
//   name: string,
//   firstName: string,
//   lastName: string,
//   email: string,
//   status: boolean,
//   password: string,
//   repassword: string,
//   avatar: File | string,
// }

const CreateUserFrame = () => {
  const customersService = new CustomerService();
  const [userCreatedIndex, setUserCreatedIndex] = useState("create")

  return (<>
  <div className={`flex flex-column ${styles.editFrame}`}>
      <div className={`card ${styles.editCard}`}>
        <div className={`grid ${globalStyles.header} ${styles.header} mx-1 mb-5`}>
              <div className={`col-12 p-0 m-0`}>
                <Button type="button" label="Back to users list" className={`align-items-center justify-content-center mx-0 ${styles.returnButton}`} 
                    onClick={()=>{window.location.href = "#/users/"}}
                />
              </div>
            <div className={`col-12`}>
                <h1>USERS</h1>
                <p>You can manage all the user data in this page easier.</p>
            </div>
            <BreadcrumbComponent/>
        </div>
        <div className={`${globalStyles.editBodyWrapper}`}>
          <div className={` flex justify-content-between flex-wrap ${globalStyles.content} ${styles.header}`}>
            <div className={`card flex justify-content-center flex-wrap ${styles.buttonGroup} `}>
              <Button type='submit' label="Create User" 
                className={`align-items-center justify-content-center m-2 ${styles.headerButton}`}
                onClick={()=>setUserCreatedIndex("create")}/>
              <Button type='submit' label="Invite User" 
                className={`align-items-center justify-content-center m-2 ${styles.headerButton}`}
                onClick={()=>setUserCreatedIndex("invite")}/>
            </div>
          </div>
          <div className={`${styles.editCardContent}`}>
            {userCreatedIndex == "create" && <CreateUser/>}
            {userCreatedIndex == "invite" && <InviteUser/>}
          </div>
        </div>
      </div>
    </div>
    {/* <div className={`grid ${styles.header} mx-1 mb-5`}>
        <div className={`col`}>
          <h1>USERS</h1>
          <p>You can manage all the user data in this page easier.</p>
        </div>
    </div>
    <div className={`card ${styles.createSpaceForm}`}>
        
    </div> */}
  </>
  )
}

export default CreateUserFrame