import { faCircle, faCircleDot, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import SpacesService from "../service/SpacesService";
import Filter from "./form/Filter";
import Search from "./form/Search";
import styles from './Streaming.module.scss'
import tableStyles from './tableStyle.module.scss';
import globalStyles from './pageStyle.module.scss';
import { ExportToFileComponent } from "./items/ExportToFile";
import { Any } from "@react-spring/types";

interface streamingEntity {
  name: string;
  description: string;
  id: string;
}

const Streaming = () => {
  const accessToken = localStorage.getItem("token")
  const realmId = localStorage.getItem("realmId")
  const spacesService = new SpacesService();
  const [spacesInfo, setSpacesInfo] = useState<Array<any>>([])
  const [streamEntity, setStreamEntity] = useState<Array<streamingEntity>>([])
  const toast = useRef<Toast>(null);
  const [selectedFilter, setSelectedFilter] = useState()
  const [visibleFilter, setVisibleFilter] = useState(false)
  const [visibleExport, setVisibleExport] = useState(false)

  const filterOptions = [
    {
      label:"Test1",
      value:'ClassRoom',
    },
    {
      label:"Test2",
      value:'Gallery',
    },
  ]

  useEffect(() => {
    if (accessToken)
      spacesService.getSpacesInfoByRealm(accessToken, realmId!).then(res => setSpacesInfo(res.spaces))
      
  },[])

  const [selectedSearch, setSelectedSearch] = useState('')
  const EntriesOptions = [
    10,
    50,
    100,
  ]
  const [selectedEntries, setSelectedEntries] = useState(10)

  // console.log(spacesInfo  )

  useEffect(()=>{
    if(selectedSearch!=''){
      spacesService.searchSpacesInRealm(selectedSearch, realmId!).then(res=>{setSpacesInfo(res.spaces)});
    }else{
      if (accessToken)
        spacesService.getSpacesInfoByRealm(accessToken, realmId!).then(res => setSpacesInfo(res.spaces))
    }
  },[selectedSearch])

  const onClear = () => {
    setSelectedFilter(undefined)
    setSelectedSearch('')
  }

  const renderHeaderTable = () => {
    return (
        <div className="flex flex-wrap align-content-center justify-content-between align-items-center">
          <div className={`col flex flex-wrap align-items-center align-content-center`}>
            <p className={`flex-initial flex align-items-center justify-content-center align-content-center ${tableStyles.entries}`}>
              Show
              <Dropdown className={`mx-1 flex-initial flex align-items-center justify-content-center align-content-center `} options={EntriesOptions} value={selectedEntries} onChange={(e:{value:any})=>setSelectedEntries(e.value)}/>
              Entries
            </p>
          </div>
          <Search onSearch={setSelectedSearch} searchValue={selectedSearch}/>
        </div>
    )
  }

  const exportData = () => {
    let tempVar = [] as Array<streamingEntity>
    spacesInfo.map((spacesInfo) => {
      let temp = {} as streamingEntity
      temp.id = spacesInfo.code + "-" + spacesInfo.roomID
      temp.description = spacesInfo.description
      temp.name = spacesInfo.name
      tempVar.push(temp)
    })
    setStreamEntity(tempVar)
    setVisibleExport(true)
  }

  return(
    <>
      <div className={`${globalStyles.header}`}>
        <div className={``}>
          <h1>Streaming</h1>
          <p></p>
        </div>
      </div>
      <div className={`${globalStyles.bodyWrapper}`}>
        <div className={`card ${globalStyles.content}`}>
          <Toast ref={toast}/>
          <Filter
            fields={
              {
                selectedValue:selectedFilter,
                setSelectedValue:setSelectedFilter,
                label:"Status",
                options:filterOptions
              }
            }
            setVisible={setVisibleFilter} visible={visibleFilter}
          />
          <div className={`flex justify-content-between flex-wrap ${styles.streamHeader}`}>
            <h3 className=" align-items-center justify-content-center font-bold m-2">Streaming List</h3>
            <div className={`flex justify-content-between flex-wrap card-container purple-container ${styles.buttonGroup}`}>
              <Button style={{ color: 'var(--surface-0)', backgroundColor: ' #001E4A'}}type="button" label="Clear search and filter" className={`align-items-center justify-content-center m-2`}  onClick={()=>onClear()}/>
              <Button type="button" label="Filter" icon="pi pi-filter-fill"  className={`p-button-outlined align-items-center justify-content-center m-2 ${styles.button}`} onClick={()=>setVisibleFilter(true)}/>
              <Button type="button" label="Export" icon="pi pi-file" className={`p-button-outlined align-items-center justify-content-center m-2 ${styles.button}`} 
                onClick={()=>{exportData()}}
              />
              {spacesInfo && <ExportToFileComponent info={streamEntity} isDisplayed={visibleExport} setIsDisplayed={(value:any)=>setVisibleExport(value)}/>}
            </div>
          </div>
          <DataTable 
            header={renderHeaderTable()}
            rows={selectedEntries}
            paginator={true} 
            value={spacesInfo}
            responsiveLayout="scroll"
            emptyMessage="No Streaming found."
            className={`${styles.stream}`}
            paginatorClassName={`grid ${tableStyles.paginator}`}
            paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
            currentPageReportTemplate={"Showing {first} to {last} of {totalRecords}"}
            >
            <Column header= "Space" field="name" sortable/>
            <Column header= "Description" field="description" sortable/>
            <Column header= "Id" body={(id) => {return id.code + "-" + id.roomID}} sortable/>
            <Column style={{textAlign: 'center'}} body={(items) => {
              return (
              <Button label="Stream" className={`p-button-outlined ${styles.streamButton}`} onClick={()=>{
                window.location.href=`/#/streaming-view/${items.code + "-" + items.roomID}/`
              }}/>
            )}
            }/>
          </DataTable>
        </div>
      </div>
    </>
  )
}

export default Streaming