import { useHistory, useLocation } from 'react-router-dom';
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import CustomerService from './service/CustomerService';
import SquidexService from './service/SquidexService';

import CustomStyle from './CustomStyle.module.scss';
const AppTopbar = (props: any) => {
	const userName = localStorage.getItem("username")
	const id = localStorage.getItem("user")
	const customerService = new CustomerService
	const squidexService = new SquidexService
	const [customerProfileImage, setCustomerProfileImage] = useState<string>()
	const history = useHistory();
	const location = useLocation(); 
	const [pathName, setPathName] = useState<string>()
	const [scrolY, setScrolY] = useState<number>(0)
	useEffect(()=>{
        let pathNames = location.pathname
        setPathName(pathNames)
    },[location.pathname])

	useEffect(() => {
		const handleScroll = (event:any) => {
		  setScrolY(window.scrollY)
		};
	
		window.addEventListener('scroll', handleScroll);
	
		return () => {
		  window.removeEventListener('scroll', handleScroll);
		};
	  }, []);

    const goDashboard = () => {
        history.push('/')
    }

	const onTopbarItemClick = (event: any, item: any) => {
		if (props.onTopbarItemClick) {
			props.onTopbarItemClick({
				originalEvent: event,
				item: item
			});
		}
	}

	useEffect(()=>{
		customerService.getUser(id!).then(res => setCustomerProfileImage(res.imgUrl))
	},[])

	let topbarClass = classNames('topbar-menu fadeInDown', { 'topbar-menu-active': props.topbarMenuActive })
	let horizontalIcon = (props.layoutMode === 'horizontal') &&
		<button type="button" className="p-link topbar-logo" onClick={goDashboard}>
			<img alt="logo" src="assets/layout/images/our3d-logo-1.png" />
			<span className="app-name">OUR3DSPACE</span>
		</button>;

	const handleLogOut = () => {
		localStorage.removeItem('token')
		history.push("/login")
	}

	return (
		<div className={`layout-topbar justify-content-between align-items-center h-auto ${(pathName != "/" && scrolY < 80) ? CustomStyle.topBarWrapper : CustomStyle.topBarWrapperDashBoard}`}>
			{horizontalIcon}
			<img alt="logo" src="assets/layout/images/our3d-logo-1.png" className="mobile-logo" />

			<button type="button" className="p-link menu-btn" onClick={props.onMenuButtonClick}>
				<i className="pi pi-bars"></i>
			</button>

			<button type="button" className="p-link topbar-menu-btn" onClick={props.onTopbarMobileMenuButtonClick}>
				<i className="pi pi-user"></i>
			</button>

			<div className={`layout-topbar-menu-wrapper ${CustomStyle.topBarMenuWrapper}`}>
				<ul className={`${topbarClass} flex flex-wrap justify-content-between align-items-center`}>
					<li 
					className={`m-0 ${classNames('search-item', { 'active-topmenuitem': props.activeTopbarItem === 'search' })} ${CustomStyle.topBarMenuItem}`}
						onClick={(e) => onTopbarItemClick(e, 'search')}>
						<span className={`p-float-label p-input-icon-left w-24rem min-h-full ${CustomStyle.inputWrapper}`}>
							<i className="topbar-icon pi pi-search"></i>
							<InputText id="search" type="text" placeholder="Search" className={`w-full ${CustomStyle.input}`} />
						</span>
					</li>
					<li 
					className={`flex-1 flex flex-wrap justify-content-end p-0 m-0 ${classNames('search-item', { 'active-topmenuitem': props.activeTopbarItem === 'notifications' })} ${CustomStyle.topBarMenuItem}`}
					>
						<button type="button" className="p-link w-auto flex-none mr-5" onClick={(e) => onTopbarItemClick(e, 'notifications')}>
							<i className="topbar-icon pi pi-bell" style={{fontSize:20}}></i>
							<span className="topbar-badge animated rubberBand">4</span>
							<span className="topbar-item-name">Notifications</span>
						</button>
						<ul className={`${classNames({ 'fadeInDown': !props.isMobile() })} ${CustomStyle.profileMenu}`}>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-sliders-h"></i>
									<span>Pending tasks</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-calendar"></i>
									<span>Meeting today at 3pm</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-download"></i>
									<span>Download documents</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-ticket"></i>
									<span>Book flight</span>
								</button>
							</li>
						</ul>
					</li>
					<li 
					className={`flex flex-wrap justify-content-end p-0 m-0 ${classNames('search-item', { 'active-topmenuitem': props.activeTopbarItem === 'profile' })} ${CustomStyle.topBarMenuItem}`}
					>
						<button type="button" className={`p-link justify-content-end align-items-center text-right w-auto m-0 p-0 flex flex-wrap`} onClick={(e) => onTopbarItemClick(e, 'profile')}>
							<span className="topbar-item-name profile-name mr-2"></span>
							<span className={`profile-name mr-2`} >{userName}</span>
							<span className={`profile-image-wrapper ${CustomStyle.imgWrapper}`} >
								<img style={{borderRadius: "100px"}} className={`w-full h-full`}src={(!customerProfileImage || customerProfileImage == "undefined") ? "assets/layout/images/avatar.png" : `${customerProfileImage}`} alt="avatar" />
							</span>
						</button>
						<ul className={`${classNames({ 'fadeInDown': !props.isMobile() })} ${CustomStyle.profileMenu}`}>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-user"></i>
									<span>Profile</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-lock"></i>
									<span>Privacy</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-cog"></i>
									<span>Settings</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link" onClick={handleLogOut}>
									<i className="pi pi-sign-out"></i>
									<span>Logout</span>
								</button>
							</li>
						</ul>
					</li>
					{/* <li className={classNames('profile-item', { 'active-topmenuitem': props.activeTopbarItem === 'profile' })}>
						<button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'profile')}>
							<span className="profile-image-wrapper">
								<img style={{borderRadius: "100px"}} 
								src={customerProfileImage === undefined ? `assets/layout/images/avatar.png` : `${customerProfileImage}`}
								alt="avatar" />
							</span>
							<span className="topbar-item-name profile-name">{userName}</span>
						</button>
						<ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-user"></i>
									<span>Profile</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-lock"></i>
									<span>Privacy</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-cog"></i>
									<span>Settings</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link" onClick={handleLogOut}>
									<i className="pi pi-sign-out"></i>
									<span>Logout</span>
								</button>
							</li>
						</ul>
					</li>
					
					<li className={classNames({ 'active-topmenuitem': props.activeTopbarItem === 'messages' })}>
						<button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'messages')}>
							<i className="topbar-icon pi pi-envelope animated swing"></i>
							<span className="topbar-badge animated rubberBand">5</span>
							<span className="topbar-item-name">Messages</span>
						</button>
						<ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
							<li role="menuitem">
								<button type="button" className="topbar-message p-link">
									<img src="assets/layout/images/avatar1.png" width="35" alt="avatar1" />
									<span>Give me a call</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="topbar-message p-link">
									<img src="assets/layout/images/avatar2.png" width="35" alt="avatar2" />
									<span>Sales reports attached</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="topbar-message p-link">
									<img src="assets/layout/images/avatar3.png" width="35" alt="avatar3" />
									<span>About your invoice</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="topbar-message p-link">
									<img src="assets/layout/images/avatar2.png" width="35" alt="avatar2" />
									<span>Meeting today at 10pm</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="topbar-message p-link">
									<img src="assets/layout/images/avatar4.png" width="35" alt="avatar4" />
									<span>Out of office</span>
								</button>
							</li>
						</ul>
					</li>
					<li className={classNames({ 'active-topmenuitem': props.activeTopbarItem === 'notifications' })}>
						<button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'notifications')}>
							<i className="topbar-icon pi pi-bell"></i>
							<span className="topbar-badge animated rubberBand">4</span>
							<span className="topbar-item-name">Notifications</span>
						</button>
						<ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-sliders-h"></i>
									<span>Pending tasks</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-calendar"></i>
									<span>Meeting today at 3pm</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-download"></i>
									<span>Download documents</span>
								</button>
							</li>
							<li role="menuitem">
								<button type="button" className="p-link">
									<i className="pi pi-ticket"></i>
									<span>Book flight</span>
								</button>
							</li>
						</ul>
					</li>
					<li className={classNames('search-item', { 'active-topmenuitem': props.activeTopbarItem === 'search' })}
						onClick={(e) => onTopbarItemClick(e, 'search')}>
						<span className="p-float-label p-input-icon-left">
							<i className="topbar-icon pi pi-search"></i>
							<InputText id="search" type="text" placeholder="Search" />
						</span>
					</li> */}
				</ul>
			</div>
		</div>
	);

}

export default AppTopbar;
