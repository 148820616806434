// import axios from "axios";
import { API_BASE_URL } from "../constants";
import { axiosClient } from "../utilities/CustomAxios";

export default class RealmsService {
    getRealmsByUser(userId: String) {
        return axiosClient.get(`${API_BASE_URL}/realms/user/${userId}`)
            .then(response => response.data)
            .catch(err => {
                console.log("get realms by user error", err)
            })
    }

    getRealmLogUser(realmId: string, userId: string) {
        return axiosClient.get(`${API_BASE_URL}/logs/realms/${realmId}/users/${userId}`)
            .then(response => response.data)
            .catch(err => {
                console.log("get realm log user error", err)
            });
    }

    getUserPointHistory(realmId: string, userId: string) {
        return axiosClient.get(`${API_BASE_URL}/points/${realmId}/users/${userId}/history`)
            .then(response => response.data)
            .catch(err => {
                console.log("get user point history error", err)
            });
    }
}