import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomerService from "../../service/CustomerService";
import { RouteIdParams } from "../enum/routerParams";
import { UserRealmForm } from "../enum/userForm";
import EditUser from "./EditUser";
import EditUserRealm from "./editUserRealm";
import styles from "./userEditFrame.module.scss"
import globalStyles from '../pageStyle.module.scss';
import BreadcrumbComponent from "../items/BreadcrumbComponent";
import { LogRealmUsers } from "./LogRealmUsers";

export const EditUserFrame = () => {
    const [editIndex, setEditIndex] = React.useState("user")
    let {id} = useParams<RouteIdParams>();
    const [data, setData] = React.useState<UserRealmForm>()
    const customersService = new CustomerService();
    const realmId = localStorage.getItem("realmId");
    const toast = React.useRef<Toast>(null);
    useEffect(()=>{

        (async ()=>{
          await customersService.getUserByRealm(realmId!, id).then((res:UserRealmForm)=>{
              setData(res)
          }).catch(err=>{
              console.error(err)
          })
        })()
    },[])

    return (
    <div className={`flex flex-column ${styles.editFrame}`}>
        <Toast ref={toast}/>
        <div className={`card ${styles.editCard}`}>
          <div className={`grid ${globalStyles.header} ${styles.header} mb-5`}>
              <div className={`col-12 p-0 m-0`}>
                <Button type="button" label="Back to users list" className={`align-items-center justify-content-center mx-0 ${styles.returnButton}`} 
                    onClick={()=>{window.location.href = "#/users/"}}
                />
              </div>
              <div className={`col-12`}>
                  <h1>USERS</h1>
                  <p>You can manage all the user data in this page easier.</p>
              </div>
              <BreadcrumbComponent/>
              {/* <Button type="button" label="Back to users list" className={`align-items-center justify-content-center ${styles.returnButton}`} 
                  onClick={()=>{window.location.href = "#/users/"}}
              /> */}
          </div>
          <div className={`${globalStyles.editBodyWrapper}`}>
            <div className={`${globalStyles.content} card ${styles.header} flex-1 grid justify-content-between`}>
              <div className={"col-12"}>
                <h1>Edit User Data</h1>
              </div>
              <div className={"flex col-12 flex-wrap justify-content-between"}>
                <div className={`card flex flex-wrap ${styles.buttonGroup}`}>
                  <Button type='submit' label="User Info" disabled={editIndex == "user"}
                    className={`align-items-center justify-content-center m-2 px-2 ${styles.headerButton}`}
                    onClick={()=>setEditIndex("user")}/>
                  <Button type='submit' label="Skin" disabled={editIndex == "skin"}
                    className={`align-items-center justify-content-center m-2 px-2 ${styles.headerButton}`}
                    onClick={()=>setEditIndex("skin")}/>
                  <Button type='submit' label="Items" disabled={editIndex == "items"}
                    className={`align-items-center justify-content-center m-2 px-2 ${styles.headerButton}`}
                    onClick={()=>setEditIndex("items")}/>
                  <Button type='submit' label="Logs" disabled={editIndex == "logs"}
                    className={`align-items-center justify-content-center m-2 px-2 ${styles.headerButton}`}
                    onClick={()=>setEditIndex("logs")}/>
                </div>
                {/* <div className={`flex flex-wrap ${styles.buttonGroup}`}>
                  <Button type="button" label="Back to users list" className={`align-items-center justify-content-center ${styles.returnButton}`} 
                      onClick={()=>{window.location.href = "#/users/"}}
                  />
                </div> */}
                
              </div>
              
              <div className={`col-12`}>
                {editIndex == "user" && <EditUser realmId={realmId!} userId={id} data={data!}/>}
                {editIndex == "skin" && <EditUserRealm data={data!}/>}
                {editIndex == "logs" && <LogRealmUsers data={data!} userId={id} realmId={realmId!}  />}
                {editIndex == "items" && <></>}
              </div>
            </div>
          </div>
        </div>
    </div>

    )    
}  