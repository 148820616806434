import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import styles from './VoucherManagement.module.scss'
import globalStyles from '../pageStyle.module.scss';
import tableStyles from '../tableStyle.module.scss';
import { SplitButton } from 'primereact/splitbutton';
import moment from 'moment';

interface Voucher {
  voucher_name: string,
  voucher_code: string,
  remaining_voucher: number | null,
  start_date: Date | Date[],
  end_date: Date | Date[],
}

const VoucherManagement = () => {
  const [voucher, setVoucher] = useState([
    {
      voucher_name: 'Starbuck',
      voucher_code: 'MGCY8U2LY7989',
      remaining_voucher: 1000,
      startDate: new Date(),
      endDate: new Date(),
    },
  ])
  // const [addNewCoupon, setAddNewCoupon] = useState<Partial<Voucher>>({})
  const toast = useRef<Toast>(null);
  const [displayForm, setDisplayForm] = useState(false);
  const [selectedEntries, setSelectedEntries] = useState(10);

  useEffect(() => {
    // get coupon data
    // setCoupon(testData)
  },[])

  const EntriesOptions = [
    10,
    50,
    100,
  ]

  const renderHeaderTable = () => {
    return (
        <div className="flex flex-wrap align-content-center justify-content-between align-items-center">
          <div className={`col flex flex-wrap align-items-center align-content-center`}>
            <p className={`flex-initial flex align-items-center justify-content-center align-content-center ${tableStyles.entries}`}>
              Show
              <Dropdown className={`mx-1 flex-initial flex align-items-center justify-content-center align-content-center `} options={EntriesOptions} value={selectedEntries} onChange={(e:{value:any})=>setSelectedEntries(e.value)}/>
              Entries
            </p>
          </div>
        </div>
    )
  }

  return(
    <>
      <div className={` ${globalStyles.header} `}>
        <div className={``}>
          <h1>FAQs List</h1>
          <p></p>
        </div>
      </div>
      <div className={`${globalStyles.bodyWrapper}`}>
      <div className={`card ${globalStyles.content}`}>
        <Toast ref={toast}/>
        <div className={`flex justify-content-between flex-wrap ${styles.header}`}>
          <h3 className=" align-items-center justify-content-center font-bold m-2">Voucher List</h3>
          <div className={`flex justify-content-between flex-wrap card-container purple-container ${styles.buttonGroup}`}>
            <Button onClick={() => window.location.href=`/#/voucher-create`}  label="Add Voucher" icon="pi pi-plus" iconPos="right"/>
          </div>
        </div>
        <DataTable 
          header={renderHeaderTable()}
          rows={selectedEntries}
          paginator={true} 
          value={voucher}
          responsiveLayout="scroll"
          emptyMessage="No voucher found."
          className={`${styles.spaces}`}
          paginatorClassName={`grid ${tableStyles.paginator}`}
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate={"Showing {first} to {last} of {totalRecords}"}
          >
          <Column header= "Voucher Name" field="voucher_name" />
          <Column header= "Voucher Code" field="voucher_code" />
          <Column header= "Remaining Vouchers" field="remaining_voucher" />
          <Column header= "Start Date" body={(items) => { return ( moment(items.start_date).format('DD-MM-YYYY') )}}/>
          <Column header= "End Date" body={(items) => { return ( moment(items.end_date).format('DD-MM-YYYY') )}}/>
          <Column header= "Action" body={(items) => {
            const actionItems = [
              {  
                label: 'Edit',
                command: () => {
                  window.location.href=`/#/voucher-edit/${items.voucher_code}`
                }
              },
              { 
                label: 'Delete',
                command: () => {
                  window.location.href=`/#/voucher-items/${items.voucher_code}`
                }
              }
            ]
            return (
            <SplitButton model={actionItems} label="View" menuButtonClassName={`p-button-text`} buttonClassName={`p-button-text`} className={`${styles.splitButton}`}
              onClick={(e)=>{window.location.href=`/#/voucher-items/${items.voucher_code}`}}
            />
            )}
          }/>
        </DataTable>
      </div>
      </div>
    </>
  )
}

export default VoucherManagement

