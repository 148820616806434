import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import CustomerService from "../service/CustomerService";
import Filter from "./form/Filter";
// import * as style from "./"
import globalStyles from './pageStyle.module.scss';
import tableStyles from './tableStyle.module.scss';
import '../../node_modules/primeflex/primeflex.css'
import { Dropdown } from "primereact/dropdown";
import Search from "./form/Search";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
// import { UserForm } from "./enum/userForm";
import RolesService from "../service/RolesService";
import { permissionsInterface, roleInterface } from "./enum/roleInterface";
import { error } from "console";
import { ExportToFileComponent } from "./items/ExportToFile";

const RolesManagement = () => {
  const accessToken = localStorage.getItem("token")
  const realmId = localStorage.getItem("realmId")
  const rolesService = new RolesService();
  const [roleInfo, setRoleInfo] = useState([])
  const toast = useRef<Toast>(null);
  const [selectedFilter, setSelectedFilter] = useState()
  const [expandedRows, setExpandedRows] = useState<any | never>([]);
  const [selectedSearch, setSelectedSearch] = useState('')
  const [visibleExport, setVisibleExport] = useState(false)

  
  useLayoutEffect(() => {
    if (accessToken){
      rolesService.getAllRolesByRealm(realmId!).then((res:any)=>{
        setRoleInfo(res)
      }).catch(err=>{
        // console.log(err.response?.status)
        window.location.href = '/#/error-page/'+ (err.response?.status || err.message)
      //   confirmDialog({
      //     message: err.response.data?.status?.message || err.message,
      //     header: 'ERROR',
      //     icon: 'pi pi-exclamation-triangle',
      //     // accept: () => returnPage(),
      //     // reject: () => returnPage(),
      //     onHide: () => returnPage(),
      //     rejectClassName:'hidden',
      //     acceptLabel: 'OK',
      // });
      })
        // customersService.getCustomers().then((res)=>{setCustomerInfo(res)});
    }
  },[])

  const EntriesOptions = [
    10,
    50,
    100,
  ]
  const [selectedEntries, setSelectedEntries] = useState(10)


  const renderHeaderTable = () => {
    return (
        <div className="flex flex-wrap align-content-center justify-content-between align-items-center">
          <div className={`col flex flex-wrap align-items-center align-content-center`}>
            <p className={`flex-initial flex align-items-center justify-content-center align-content-center ${tableStyles.entries}`}>
              Show
              <Dropdown className={`mx-1 flex-initial flex align-items-center justify-content-center align-content-center `} options={EntriesOptions} value={selectedEntries} onChange={(e:{value:any})=>setSelectedEntries(e.value)}/>
              Entries
            </p>
          </div>
          <Search onSearch={setSelectedSearch} searchValue={selectedSearch}/>
        </div>
    )
  }

  const onClear = () => {
    setSelectedFilter(undefined)
    setSelectedSearch('')
  }

  const deleteItem = (item:roleInterface) =>{
    rolesService.deleteRoleInRealm(realmId!, item._id!).then(res=>{
      if(!(res?.status?.code)){
        rolesService.getAllRolesByRealm(realmId!).then((res:any)=>{
          setRoleInfo(res)
        })
      }
      confirmDialog({
        message:res?.status?.message || "Delete Successfully",
        icon: 'pi pi-exclamation-triangle',
        position:'top',
        // acceptLabel:"",
        acceptClassName:'hidden',
        rejectLabel:"Ok",
      })
    }).catch(err=>{
      console.log("Error when delete role",err)

      confirmDialog({
        message: "An Error occurred when deleting role. Please try again later.",
        icon: 'pi pi-exclamation-triangle',
        position:'top',
        // acceptLabel:"",
        acceptClassName:'hidden',
        rejectLabel:"Ok",
      })
    })
  }

  const onDelete = (item:roleInterface) => {
    confirmDialog({
        message: 'Do you want to delete this role?',
        // header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        position:'top',
        className:`${tableStyles.dialog}`,
        acceptLabel:'Delete Data',
        rejectLabel:'Cancel',
        accept:()=>{
          deleteItem(item)
        }
        // accept,
        // reject
    });
};


  useEffect(()=>{
    try{
      if(selectedSearch!=''){
        rolesService.searchRole(realmId!, selectedSearch).then(res=>{
          setRoleInfo(res)
        })
      }else{
        rolesService.getAllRolesByRealm(realmId!).then((res:any)=>{
          setRoleInfo(res)
        })
      }
    }catch(err){
      console.log("ERROR when search: ",err)
    }
  },[selectedSearch])
  
  return(
    <>
      <div className={`grid ${globalStyles.header}`}>
        <ConfirmDialog breakpoints={{'960px': '75vw', '640px': '100vw'}} className={`${tableStyles.dialog}`}/>
        <div className={`col-12 sm:col-3 pr-8`}>
          <h1>Roles</h1>
          <p>You can manage all the roles data in this page easier.</p>
        </div>
        <div className={`col-12 flex flex-wrap align-items-center justify-content-end`}>
          <Button onClick={()=>{window.location.href = '/#/role/create'}} label="New Role" icon="pi pi-plus" iconPos="left" className={`p-button-raised p-button-text px-3 py-3 ${globalStyles.button}`}/>
        </div>
      </div>
      <div className = {`col-12 ${globalStyles.bodyWrapper}`} >
        <div className={`card ${globalStyles.content}`}>
          <Toast ref={toast}/>
          <div className={`flex justify-content-between flex-wrap ${tableStyles.header}`}>
            <h3 className=" align-items-center justify-content-center font-bold m-2">Roles List</h3>
            <div className={`flex justify-content-between flex-wrap card-container purple-container ${tableStyles.buttonGroup}`}>
              <Button style={{ color: 'var(--surface-0)', backgroundColor: ' #001E4A'}}type="button" label="Clear search" className={`align-items-center justify-content-center m-2`} onClick={()=>onClear()}/>
              <Button type="button" label="Export" icon="pi pi-file" className={`p-button-outlined align-items-center justify-content-center m-2 ${tableStyles.button}`} onClick={()=>{
                setVisibleExport(true)
              }}/>
              {roleInfo && <ExportToFileComponent info={roleInfo} isDisplayed={visibleExport} setIsDisplayed={(value:any)=>setVisibleExport(value)}/>}
            </div>
          </div>
          <DataTable
            removableSort={true}
            header={renderHeaderTable()}
            rows={selectedEntries}
            paginator={true}
            value={roleInfo}
            responsiveLayout="scroll"
            className={`spaces ${tableStyles.table}`}
            emptyMessage="No Role(s) found."
          >
            <Column header="Name" field="name" sortable={true}/>
            <Column header="" style={{textAlign: 'end'}} body={(item) => {
                const actionItems = [
                  {
                    label:"Delete",
                    command:()=>{
                      onDelete(item)
                    },
                  },
                // {
                //     label: 'Edit',
                //     command: () => {
                //       window.location.href=`/#/${item.id}/edit`

                //     }
                // },
                // {
                //     label: 'Delete',
                //     command:()=>{
                //       deleteItem(item)
                //       // showDeleteUserAlert(item.id)
                //     }
                // }
                ]
                return (
                // <SplitButton model={actionItems} label="View" className={`mr-2 mb-2 ${tableStyles.btnContainer}`} menuButtonClassName={`p-button-text`} buttonClassName={`p-button-text`}/>
                <SplitButton model={actionItems} label="Edit" className={`mr-2 mb-2 ${tableStyles.btnContainer}`} menuButtonClassName={`p-button-text`} buttonClassName={`p-button-text`} onClick={()=>{window.location.href=`/#/role/${item._id}/edit`}}/>
                // <Button 
                // label="View" 
                // className={`mr-2 mb-2 p-button-text ${tableStyles.btnContainer}`}
                // // menuButtonClassName={`p-button-text`} 
                // // buttonClassName={`p-button-text`}
                // onClick={()=>{window.location.href=`/#/role/${item.name}/edit`}} />
            )}
            }/>
          </DataTable>
        </div>
      </div>
    </>
  )
}

export default RolesManagement
