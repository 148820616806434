import axios from 'axios'
import { API_BASE_URL } from '../constants';
import { axiosClient } from '../utilities/CustomAxios';

export default class PermissionsService {
    getAllPermissions(){
        return axios.get(`${API_BASE_URL}/permissions`,{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'x-api-token': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then((response) => response.data);
    }

    getPermissionsByUser(){
        return axios.get(`${API_BASE_URL}/user/permissions/`,{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(res=> res.data);
    }
}