import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from 'react';
import styles from "./userStyles.module.scss"
import UsersManagementForm from "../form/UserManagementForm";
import { checkStrings, emailValidate } from "../../utilities/Functions";
import CustomerService from "../../service/CustomerService";
import { Toast } from "primereact/toast";
import { setTimeout } from 'timers';
import { errorsUserInterface, UserForm } from "../enum/userForm";
// interface userCreateForm {
//   name: string,
//   firstName: string,
//   lastName: string,
//   email: string,
//   status: boolean,
//   password: string,
//   repassword: string,
//   avatar: File | string,
// }


const CreateUser = () => {
  const realmId = localStorage.getItem("realmId");
  const accessToken = localStorage.getItem("token");
  const toast = useRef<Toast>(null);
  const customersService = new CustomerService();
  const [userCreateForm, setUserCreateForm] = React.useState<UserForm>({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: false,
    password:'',
    repassword:'',
    avatar: undefined,
    role:undefined,
  })
  const [errors, setErrors] = useState<errorsUserInterface>({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    emailVerified: "",
    password:"",
    repassword:"",
    role:"",
    avatar:"",
  })

  const handleSubmit = (inputData:UserForm) => {
    // const entries = data.entries();
    //   for (let entry of entries) {
    //   const key = entry[0];
    //   const val = entry[1];
    //   console.log(key, val);
    // }

    let err = {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      emailVerified: "",
      password:"",
      repassword:"",
      role:"",
      avatar:""
    }
    let error = [];

    let nameErr = checkStrings(inputData.username, 'username', true);
    err = {...err, username: nameErr || ""}
    if(nameErr!==null){
      error.push(nameErr)
    }
    
    let passErr = checkStrings(inputData.password!, 'password', true, true, false);
    err = {...err, password: passErr || ""}
    if(passErr!==null){
      error.push(passErr)
    }

    let repassErr = checkStrings(inputData.repassword!, 're-password', true, true, false);
    err = {...err, repassword: repassErr||""}
    if(repassErr!==null){
      error.push(repassErr)
    }
    
    if(inputData.repassword !== inputData.password && repassErr == null){
      err ={...err, repassword: 'Password and Re-password are not the same'}
      error.push('Password and Re-password are not the same')
    }

    let fnameErr = checkStrings(inputData.firstName||"", 'first name', true);
    err = {...err, firstName: fnameErr || ""}
    if(fnameErr!==null){
      error.push(fnameErr)
    }

    let lnameErr = checkStrings(inputData.lastName||"", 'last name', true);
    err = {...err, lastName: lnameErr || ""}
    if(lnameErr!==null){
      error.push(lnameErr)
    }

    let emailErr = checkStrings(inputData.email, 'email', true) || emailValidate(inputData.email);
    err = {...err, email: emailErr || ""}
    if(emailErr!==null){
      error.push(emailErr)
    }
    // let statusErr = checkStrings(inputData.status, 'status', false);
    // if(statusErr!==null){
    //   error.push(statusErr)
    // }
    setErrors(err)
    if(error.length>0){
      toast.current?.show(error.map(i=>{
        return {severity:'error', summary:'Error Message', detail:i, life: 3000, closable:false}
      }))
      return
    }
    const FormData = global.FormData;
    let formData = new FormData()
    // console.log(typeof inputData.avatar == 'string' ? "":inputData.avatar?.name)
    if(inputData.avatar){
      formData.append('profilePic', inputData.avatar || '', typeof inputData.avatar == 'string' ? undefined : inputData.avatar?.name)
    }
    // formData.append('profilePic', inputData.avatar || '', typeof inputData.avatar == 'string' ? undefined : inputData.avatar?.name)
    formData.append('email', inputData.email)
    formData.append('firstName', inputData.firstName!)
    formData.append('username', inputData.username)
    formData.append('lastName', inputData.lastName!)
    formData.append('password', inputData.password!)
    formData.append('assetSet', "default")
    formData.append('assetPath', "")
    formData.append('texturePath', "")
    formData.append('emailVerified', inputData.emailVerified!.toString())
    formData.append('role', inputData.role)
    if(!inputData.emailVerified){
      formData.append('requiredActions', 'VERIFY_EMAIL')
    }

    customersService.createUserInRealm(realmId!,formData).then(res=>{
      if(res?.status?.code != 200){
          toast.current?.show({severity:'error', summary: 'Error Message', detail:res.status?.message, life: 3000, closable:false})
        }else if(res?.status?.code == 200){
          const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
            window.location.href="/#/users"
        }, 3500);
          toast.current?.show({severity:'success', summary: 'Create User Successfull', detail:res.status?.message, life: 3000, closable:false})
          clearTimeout(timer);
        }
      }).catch(err=>{
        console.log(err)
        toast.current?.show({severity:'error', summary: 'Error Message', detail:err.status?.message, life: 3000, closable:false})
      });
    
    // customersService.createUser(formData).then(res=>{
    //   if(res?.status?.code != 200){
    //     toast.current?.show({severity:'error', summary: 'Error Message', detail:res.status?.message, life: 3000, closable:false})
    //   }else if(res?.status?.code == 200){
    //     const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
    //       window.location.href="/#/users"
    //   }, 3500);
    //     toast.current?.show({severity:'success', summary: 'Create User Successfull', detail:res.status?.message, life: 3000, closable:false})
    //     clearTimeout(timer);
    //   }
    // }).catch(err=>{
    //   console.log(err)
    //   toast.current?.show({severity:'error', summary: 'Error Message', detail:err.status?.message, life: 3000, closable:false})
    // })

    // customersService.createUser({
    //   username: inputData.name,
    //   firstName: inputData.firstName,
    //   lastName: inputData.lastName,
    //   email: inputData.email,
    //   status: inputData.status,
    //   password:  inputData.password,
    //   profilePic: inputData.avatar!,
    // }).then(res=>{
    //   if(res?.status?.code != 200){
    //     toast.current?.show({severity:'error', summary: 'Error Message', detail:res.status?.message, life: 3000, closable:false})
    //   }else if(res?.status?.code == 200){
    //     const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
    //       window.location.href="/#/users"
    //   }, 3500);
    //     toast.current?.show({severity:'success', summary: 'Create User Successfull', detail:res.status?.message, life: 3000, closable:false})
    //     clearTimeout(timer);
    //   }
    // }).catch((err)=>{
    //   console.log(err)
    //   toast.current?.show({severity:'error', summary: 'Error Message', detail:err.status?.message, life: 3000, closable:false})
    // })
  }

  return (<>
    <Toast ref={toast} />
    <div className={`card ${styles.createSpaceForm}`}>
        <div className={`flex justify-content-between flex-wrap ${styles.header}`}>
            <h3 className=" align-items-center justify-content-center font-bold">Create New User</h3>
        </div>
        <UsersManagementForm data={userCreateForm} handleSubmit={handleSubmit} isCreated={true} errors={errors}/>
    </div>
  </>
  )
}

export default CreateUser