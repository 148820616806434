import { ChangeEvent, Suspense, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Html, useGLTF } from "@react-three/drei";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import styles from "./ModelView.module.scss";

type ModelViewProps = {
  path: string;
}

const ModelView = () => {
  const [fileUpload, setFileUpload] = useState<string>()
  
  useEffect(() => {
    console.log(fileUpload)
  },[fileUpload])

  function handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
    if (!event || !event.target || !event.target.files) return 
    console.log(typeof(event.target.files[0]))    
    const file = event.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    setFileUpload(fileUrl);
  }

  function Model({path}: ModelViewProps) {
    const result = useGLTF(path, true, undefined, (loader) => {
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath('/draco-gltf/')
      loader.setDRACOLoader(dracoLoader)
    })
    return (
      <>
        <primitive object={result.scene} />
      </>
    )
  }

  return (
    <div className={`${styles.modelView}`}>
      <div className={`${styles.canvasView} card`}>
        <Canvas dpr={[1, 2]}  camera={{fov: 45}}>
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 5]} />
          <pointLight position={[-10, -10, -10]} />
          {
          fileUpload && 
          <Suspense fallback={null}>
            <Model path={fileUpload}/>
          </Suspense>
          }
          <gridHelper />
          <OrbitControls />
        </Canvas>
      </div>
      <div className={`${styles.uploadButton}`}>
        <input className={`p-inputtext`} type='file' accept=".gltf" onChange={(e) => handleFileUpload(e)}/>
      </div>
    </div>
  )
}

export default ModelView