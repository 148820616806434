import _ from "lodash";
import { RealmsUsersInterface } from "../components/enum/realmInterface";
import { folderInterFace, imageSquidexInterface } from "../components/enum/SquidexInterfaces";
import { UserForm } from "../components/enum/userForm";
import { peopleId, specialCharTester } from "../constants";
import SquidexService from "../service/SquidexService";

export function  checkStrings (targetString:string, field:string, required:boolean, noSpace?:boolean, noSpecialChar?:boolean, min?:number, max?:number) {

    if (required && (_.isEmpty(targetString) ||  targetString.trim() === '')) {
      return `${field} is required!`;
    }
    if (noSpace && targetString.indexOf(' ') > 0) {
      return `${field} not allow to use space character!`;
    }
    if (noSpecialChar && specialCharTester.test(targetString)) {
      return `${field} not allow to use special character!`;
    }
    if (min && targetString.length < min) {
      return `${field} length must greater than or equal ${min}!`;
    }
    if (max && targetString.length > max) {
      return `${field} length must less than or equal ${max}!`;
    }
    return null;
  }
  
  export function emailValidate (text:string) {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      // console.log("Email is Not Correct");
      return 'Email is Not Correct';
    }
    else {
      return null;
      // console.log("Email is Correct");
    }
  }

export const generateUUID = (length: (number | any)) => { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  var result = '';
  if (length)
      for (var i = 0; i < length; i++) result += 'x';
  else
      result = 'xxxxxxxxxxxxxx';
  return result.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export function instanceOfUserForm(object: any): object is UserForm {
  return 'username' in object;
}

export function instanceOfImageSquidexInterface(object: any): object is imageSquidexInterface {
  return 'fileName' in object && 'fileType' in object && 'slug' in object && "_links" in object;
}

export function removeExtension(filename: string) {
  return filename.substring(0, filename.lastIndexOf('.')) || filename;
}


export const getAssetSetFolder = async (assetFolderId:string="",folderName:string = "") =>{
  const squdiexsService = new SquidexService();
  let res = await squdiexsService.getAssetsFolders((assetFolderId != "") ? assetFolderId : peopleId).then(folders=>{
      // return folders;
      return _.find(folders?.items, (folder:folderInterFace)=>{
          return folder.folderName == ((folderName != "") ? folderName : localStorage.getItem("assetSet"))
      })
  })
  
  return res;
}
