import React, { useEffect, useState } from 'react';
import { Route, withRouter, useLocation, Redirect, useHistory, HashRouter as Router, Switch } from 'react-router-dom';
import App from "./App";
import { Login } from "./pages/Login";
import { Error } from "./pages/Error";
import { NotFound } from "./pages/NotFound";
import { Access } from "./pages/Access";
import CustomerService from './service/CustomerService';
import UserInvitationUpdate from './components/users/UserInvitationUpdate';
import RealmsSelector  from './pages/RealmsSelector';

const AppWrapper = (props: any) => {
	let location = useLocation();
	const [isAuthenticate, setIsAuthenticate] = useState(localStorage.getItem('token') ? true : false);
	const [accessToken, setAccessToken] = useState<String | undefined>()
	const [userId, setUserId] = useState<String | undefined>()
	const [org, setOrg] = useState<String | undefined>()
	const customerService = new CustomerService();
	// const token = localStorage.getItem('token');

	const history = useHistory()

	useEffect(() => {
		const token = localStorage.getItem('token');
		window.scrollTo(0, 0)
		if(!['/invitation-letter'].includes(props.location.pathname)){
			if (token) {
				/// Validate Token		
				customerService.getUserAuthenticate(token).then(res => {
					setAccessToken(token)
					setIsAuthenticate(true);
				}).catch(error => 
					{
						if (error) {
							localStorage.removeItem("token")
							history.push("/login")
						}
					}
					)
			} else {
				history.push("/login")
			}
		}
	}, [location]);

	switch (props.location.pathname) {
		case "/realm-selection":
			return <Route path="/realm-selection" component={()=><RealmsSelector isAuthenticate={isAuthenticate} setRealmId={setOrg}/>} />;
		case "/login":
			return <Route path="/login" component={() => <Login setIsAuthenticate={setIsAuthenticate} setAccessToken={setAccessToken} setUserId={setUserId}/>} />
		case "/error":
			return <Route path="/error" component={Error} />
		case "/notfound":
			return <Route path="/notfound" component={NotFound} />
		case "/access":
			return <Route path="/access" component={Access} />
		case "/invitation-letter":
			return <Route path="/invitation-letter" component={()=><UserInvitationUpdate />}/>
		default:
			return <App/>;
	}

}

export default withRouter(AppWrapper);