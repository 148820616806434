import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { RefObject, useLayoutEffect, useRef, useState, useEffect } from "react";
import tableStyles from './tableStyle.module.scss';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { UserForm } from "../enum/userForm";
import { Checkbox } from "primereact/checkbox";
import { CSVLink } from "react-csv";

interface ExportToFileComponent_interface {
    info: {
        [key in keyof UserForm | any]: any;
       }[];
    isDisplayed: boolean;
    setIsDisplayed:Function;
    fileName?:string
} 

export const convertBySelectField = (selectedFields:String[]=[], fields:String[]=[], info:{
    [key in keyof UserForm | any]: any;
   }[]) =>{
    if(!(selectedFields?.length > 0)){
        return []
    }
    if(selectedFields === fields){
        return info
    }

    // if(selectedFields){
        return info.map((i:any)=>{
            return selectedFields.reduce((obj, val) => {
                return { ...obj, [val as string]: i[val as keyof typeof info[0]]}
            }, {}) 
        })
    // }
}


export const ExportToFileComponent =({info=[], isDisplayed = false, setIsDisplayed, fileName ="Download"}: ExportToFileComponent_interface)=>{
    const [ exportInfoType, setExportInfoType ] = useState("CSV")
    const [ convertedInfo, setConvertedInfo ] = useState<Array<any>>([])
    const [errors, setErrors] = useState("");
    const [ fieldsSelected, setFieldsSelected ] = useState<Array<string>>([])
    const [ fields, setFields ] = useState<Array<string>>([])
    const [ temp, setTemp ] = useState<typeof info[0]>(info[0]||undefined)
    // const [displayResponsive, setDisplayResponsive] = useState(isDisplayed);
    // function exportUserInfo(userInfo: any) {
    // const fileData = JSON.stringify(info);
    // const blob = new Blob([fileData], { type: "text/plain" });
    // const url = URL.createObjectURL(blob);
    // const link = document.createElement("a");
    // link.download = "user-info.json";
    // link.href = url;
    // link.click();
    // }

    useEffect(()=>{
        if(info?.length<1){
            return
        }
        if(!temp){
            setTemp(info[0])
        }
        setFields(Object.keys(temp||info[0]).filter((i)=>!i.startsWith("_")));
        setFieldsSelected(Object.keys(temp||info[0]).filter((i)=>!i.startsWith("_")))
    },[temp, info])
    const renderFooter = () => {
        return (
            <div>
                <Button label="No" onClick={() => {setIsDisplayed(false)}} className="p-button-text" />
                {exportInfoType == "CSV" ?
                    <CSVLink filename={`${fileName ?? "Download"}.csv`} data={convertedInfo ?? info}>
                        <Button label="Yes" autoFocus />
                    </CSVLink>
                    
                :
                    <Button label="Yes" onClick={() => {exportToXLSX()}} autoFocus />
                }
            </div>
        );
    }

    const exportToXLSX = ()=>{
        // let convertedInfo = convertBySelectField()
        if(!convertedInfo){
            return
        }
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        // const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(convertedInfo);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, (fileName??"Download") + fileExtension);
    // }
    }

    const onFieldChange = (e: { value: any, checked: boolean }) => {
        let _selectedFields = [...fieldsSelected];

        if (e.checked) {
            _selectedFields.push(e.value);
        }
        else {
            for (let i = 0; i < _selectedFields.length; i++) {
                const selectedField = _selectedFields[i];

                if (selectedField === e.value) {
                    _selectedFields.splice(i, 1);
                    break;
                }
            }
        }
        setConvertedInfo(convertBySelectField(_selectedFields,fields,info))
        setFieldsSelected(_selectedFields);
    }
    
    return (<>
        {/* <Button type="button" label="Export" icon="pi pi-file" className={`p-button-outlined align-items-center justify-content-center m-2 ${tableStyles.button}`} /> */}
        <Dialog header="Export to File" footer={renderFooter()} visible={isDisplayed} onHide={()=>setIsDisplayed(false)} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}}>
            <div className={`field align-items-center justify-content-center `}>
                <label className="p-label block">Select Export File Type</label>
                <div className="p-field-radiobutton mb-2 justify-content-center align-items-center">
                    <RadioButton className={`mr-1`} inputId="CSV" name="extention" value="CSV" onChange={(e) => setExportInfoType("CSV")} checked={exportInfoType === 'CSV'} />
                    <label htmlFor="CSV">CSV</label>
                </div>
                <div className="p-field-radiobutton mb-2 justify-content-center align-items-center">
                    <RadioButton className={`mr-1`} inputId="XLS" name="extention" value="XLSX" onChange={(e) => setExportInfoType("XLSX")} checked={exportInfoType === 'XLSX'} />
                    <label htmlFor="XLSX">XLSX</label>
                </div>
            </div>
            <div className={`field align-items-center justify-content-center `}>
                <label className="p-label block">Select Exported Field(s)</label>
                {fields.map((field, index) =>{
                    return(
                        <div key={`${field}-${index}`} className="p-field-checkbox my-2">
                            <Checkbox className={`mr-3`} inputId={`${field}-${index}`} name="field" value={field} onChange={onFieldChange} checked={fieldsSelected.some((item) => item === field)} />
                            <label htmlFor={`${field}-${index}`}>{field}</label>
                        </div>
                    )
                })}
            </div>
        </Dialog>
    </>
    )

}