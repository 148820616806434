import { StringLiteralLike } from "typescript";

export const MESH_TYPE = {
    SCREEN: 'screen',
    FLOOR: 'floor',
    BUTTON: 'button',
    MOUNT: 'mount',
    ZONE: 'zone',
    CLIPPLANE: "clippingPlane",
    SIGN: "sign",
    ANIMATED: "animated"
}

export const FALLBACK_URL = `https://myverse.co.id/`

export const MEDIA_FALLBACK_ID = `d032cc31-96a7-4fc9-aa2a-4418bc053cd8`

export const MESH_SIDE = {
    DOUBLE: 2,
    FRONT: 1,
    BACK: 0
}

export const BIN_SIZE = 10;
export const INTERACT_DISTANCE = 40;

export const AXIS = {
    X: 'x',
    Y: 'y',
    Z: 'z'
}

export const MEDIA_TYPE = {
    IMAGE: "image",
    EXTERNAL_IMAGE: "external_image",
    VIDEO: "video",
    PNG: "png",
    MP4: "mp4"
}

export const MEDIA_SOURCE = {
    TWITTER: "Twitter",
    INSTAGRAM: "Instagram",
    ADS: "Ads"
}

export const MESH_ACTION = {
    URL: "openURL",
    POPUP_IMG: "popup_img",
    POPUP_VIDEO: "popup_video",
    POPUP_FAQ: "popup_FAQ",
    CLAIMPOINT: "claimPoint",
    GAME: "openGame",
    FETCH_MEDIA: "fetch_external_media"
}

export type DEFAULT_ANIM_PATH_INTERFACE = {
    surge:string,
    default:string
}

export const DEFAULT_ANIM_PATH = {
    surge: "https://content-sea.fromlabs.com/api/assets/virtualspace/d5db7d7a-d7ac-4cd5-afec-5bae747f02c3/idle.fbx",
    default: "https://content-sea.fromlabs.com/api/assets/virtualspace/b1532dbe-4c92-4e16-9cb3-466916b1a749/idle.fbx"
}