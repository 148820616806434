import axios from 'axios'
import { spaceUserInterfaces } from '../components/enum/SpaceUserInterface';
import { API_BASE_URL } from '../constants';
import { axiosClient } from '../utilities/CustomAxios';

export default class SpacesUsersService {
    
    getLinkedUser(spaceId:string | String, token: string){
        return axios.get(`${API_BASE_URL}/space/${spaceId}/linked-users`,{
            headers:{
                "authorization" : `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    getSpaceLinkedUser(userId:string, token:string){
        return axios.get(`${API_BASE_URL}/user/linked-spaces`,{
            headers:{
                'authorization': `Bearer ${token}`,
                userid: userId
            }
        }).then(res => res.data)
    }

    getSpaceUserByIds(spaceId:string, userId:string, token:string){
        return axios.get(`${API_BASE_URL}/user/space/${spaceId}/${userId}`,{
            headers:{
                'authorization' : `Bearer ${token}`,
                userId: userId
            }
        }).then(res=>{
            return res.data
        })
    }

    updateSpaceUser(token:string, inputData:spaceUserInterfaces){
        return axios.put(`${API_BASE_URL}/user/space/update`,{
            ...inputData, 
            spaceId:inputData.space
        }, {
            headers:{
                'authorization' : `Bearer ${token}`,
                'Content-Type' : 'application/json',
                'userid':inputData.userId,
            }
        }).then(res=> res.data);
    }
}
