import { Card } from "primereact/card";
import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CustomerService from "../../service/CustomerService";
import { RouteIdParams } from "../enum/routerParams";

type routeInvitation={
    realmId:string;
    userId:string;
    status:string;
}


export default function UserInvitationUpdate () {
    const customersService = new CustomerService;
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const location = useLocation()
    let query = new URLSearchParams(location.search)
    const realmId = query.get("realmId");
    const sid = query.get("sid");
    const status = query.get("status")
    // console.log(location, query.get)
    useLayoutEffect(()=>{
        if( status == "accept"){
            customersService.updateInvitationLetter(realmId!, sid!, true).then(res=>{

            }).catch(err=>{

            })
            setIsLoading(false)
        }else if(status == "decline"){
            customersService.updateInvitationLetter(realmId!, sid!, false).then(res=>{

            }).catch(err=>{

            })
            setIsLoading(false)
        }
    },[])

    return(
        <div className="login-body w-full flex flex-wrap flex-column justify-content-center align-items-center">
			<div className="p-fluid w-9 h-full flex flex-wrap flex-column my-5">
                {!isLoading && <Card className="px-5 text-center">
                    <h3>Invitation Letter </h3>
                    <p>You {status} the invitation letter</p>
                    {status == "accept" && <a href="">Go to ...</a>}
                </Card>}
            </div>
        </div>
    )
}