// import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import RealmsService from "../../service/RealmsServcie";
import { UserRealmForm } from "../enum/userForm";
import { ActionInterface, PointLogInterface, RealmLogInterface } from "../enum/realmInterface"
import { DataTable, DataTablePFSEvent, DataTableRowToggleParams } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import styles from '../SpacesManagement.module.scss'
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from "primereact/api";
import Paginator from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";

type LogRealmUsersProps = {
    data: UserRealmForm;
    userId: string;
    realmId: string;
}

export const LogRealmUsers = (props: LogRealmUsersProps) => {
    const [userInfo, setUserInfo] = useState<UserRealmForm>(props.data);
    const [realmLog, setRealmLog] = useState<Array<RealmLogInterface>>();
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [filters, setFilters] = useState<any>({
        "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [actionsFilter, setActionsFilter] = useState<any>({
        "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "details.roomID": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "actionType": { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [inputValue, setInputValue] = useState<string>("");
    // const [totalPointsOnDate, setTotalPointsOnDate] = useState<number>(0);
    // const toast = useRef<Toast>(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [actionsList, setActions] = useState<Array<string>>(["join", "leave"]);
    const isMounted = useRef(false);
    const realmsService = new RealmsService();

    useEffect(() => {
        setUserInfo(props.data)
    }, [props.data]);

    useEffect(() => {
        (async () => {
            isMounted.current = true;
            let realmLog: Array<RealmLogInterface> = await realmsService.getRealmLogUser(props.realmId, props.userId);
            let pointLog: Array<PointLogInterface> = await realmsService.getUserPointHistory(props.realmId, props.userId);
            realmLog.forEach((log: RealmLogInterface) => {
                let filtered = pointLog.filter((point: PointLogInterface) => {
                    return point.date === log.date;
                });
                let total = 0;
                filtered.forEach((point: PointLogInterface) => { total += point.point; })
                log.points = [...filtered];
                log.totalPoints = total;
                log.actions.forEach(action => {
                    if (!actionsList.includes(action.actionType)) {
                        setActions([...actionsList, action.actionType]);
                    }
                })
            })
            realmLog.sort((a, b) => { return new Date(b.date).getTime() - new Date(a.date).getTime() });
            setRealmLog(realmLog);
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const timeField = (rowData: ActionInterface) => {
        let date = new Date(rowData.time);
        return date.toTimeString();
    }

    const actionField = (rowData: ActionInterface) => {
        return <span className={`customer-badge`}
            style={{ backgroundColor: rowData.actionType === "join" ? "#9CCC65" : "#EF5350" }}>
            {rowData.actionType}
        </span>;
    }

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setInputValue(value);
        setFilters({
            "global": { value: value, matchMode: FilterMatchMode.CONTAINS }
        });
    }

    const onActionFilterChange = (event: DataTablePFSEvent) => {
        let value = event.value;
        setActionsFilter({
            ...actionsFilter,
            "actionType": { value: value, matchMode: FilterMatchMode.EQUALS }
        });
    }

    const actionItemTemplate = (option: string) => {
        return <span className={`customer-badge`} style={{ backgroundColor: option === "join" ? "#9CCC65" : "#EF5350" }}>{option}</span>;
    }

    const actionRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <Dropdown value={options.value} options={actionsList} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={actionItemTemplate} placeholder="Select an Action" className="p-column-filter" showClear />
    }

    const onRowToggle = (event: DataTableRowToggleParams) => {
        setExpandedRows(event.data);
    }

    const rowExpansionTemplate = (rowData: RealmLogInterface) => {
        const logColumns = [
            {
                field: "actionType", header: "Action", sortable: false, body: actionField, filter: true, filterElement: actionRowFilterTemplate
            },
            { field: "time", header: "Time", body: timeField, sortable: true, filter: false },
            { field: "details.roomID", header: "Room ID", sortable: false, filter: true, filterPlaceholder: "Search by RoomID" },
        ]

        const pointColumns = [
            { field: "count", header: "Count", sortable: true, filter: false },
            { field: "point", header: "Point", sortable: true, filter: false },
            { field: "time", header: "Time", body: timeField, sortable: true, filter: false },
            { field: "source.name", header: "Source", sortable: true, filter: false },
            { field: "sourceId", header: "Source ID", sortable: false, filter: false },
        ]

        const dynacmicLogColumns = logColumns.map((col, i) => {
            return <Column {...col} />;
        });

        const dynacmicPointColumns = pointColumns.map((col, i) => {
            return <Column {...col} />;
        });

        return (
            <>
                <h3 className="align-items-center justify-content-center font-bold m-2">Join Log</h3>
                <DataTable value={rowData.actions}
                    showGridlines stripedRows paginator
                    rowsPerPageOptions={[10, 20, 50]}
                    first={first}
                    rows={rows}
                    paginatorTemplate={paginatorTemplate}
                    responsiveLayout="scroll"
                    paginatorClassName="justify-content-start"
                    filters={actionsFilter}
                    onFilter={onActionFilterChange}
                >
                    {dynacmicLogColumns}
                </DataTable>
                <h3 className="align-items-center justify-content-center font-bold m-2">Point Log</h3>
                <DataTable value={rowData.points}
                    showGridlines stripedRows paginator
                    rowsPerPageOptions={[10, 20, 50]}
                    first={first}
                    rows={rows}
                    paginatorTemplate={paginatorTemplate}
                    responsiveLayout="scroll"
                    paginatorClassName="justify-content-start"
                >
                    {dynacmicPointColumns}
                </DataTable>
            </>
        );
    }

    const paginatorTemplate: Paginator.PaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown',
        FirstPageLink: (options: Paginator.PaginatorFirstPageLinkOptions) => {

        },
        PrevPageLink: (options: Paginator.PaginatorPrevPageLinkOptions) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Previous</span>
                    <Ripple />
                </button>
            )
        },
        NextPageLink: (options: Paginator.PaginatorNextPageLinkOptions) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Next</span>
                    <Ripple />
                </button>
            )
        },
        PageLinks: (options: Paginator.PaginatorPageLinksOptions) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        LastPageLink: (options: Paginator.PaginatorLastPageLinkOptions) => {
        },
        RowsPerPageDropdown: (options: Paginator.PaginatorRowsPerPageDropdownOptions) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: Paginator.PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            )
        },
        JumpToPageInput: (options: Paginator.PaginatorJumpToPageInputOptions) => {

        }
    };

    return (
        <div className={`card p-5 grid justify-content-between ${styles.createSpaceForm}`}>
            <div className={`col-12 flex justify-content-between flex-wrap`}>
                <div className="align-items-center justify-content-center m-2">
                <h3 className="font-bold">User Realm Log</h3>
                <h4 className="font-bold">{userInfo.user.display_name || userInfo.user.username}</h4>
                
                </div>
                <div className={`flex align-items-center align-content-center justify-content-between flex-wrap card-container purple-container ${styles.buttonGroup}`}>
                    <Button style={{ color: 'var(--surface-0)', backgroundColor: ' #001E4A' }} type="button" label="Clear search" className={`align-items-center justify-content-center m-2`} onClick={() => { setFilters(undefined); setInputValue("") }} />
                    <Button type="button" label="Export" icon="pi pi-file" className={`p-button-outlined align-items-center justify-content-center m-2 ${styles.button}`} />
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={inputValue} onChange={onFilterChange} placeholder="Search" />
                    </span>
                </div>
            </div>
            {(realmLog && realmLog.length > 0)
                ?
                <DataTable
                    value={realmLog}
                    expandedRows={expandedRows}
                    onRowToggle={onRowToggle}
                    rowExpansionTemplate={rowExpansionTemplate}
                    onPage={(e) => { setFirst(e.first); setRows(e.rows) }}
                    dataKey="date"
                    filters={filters}
                    showGridlines stripedRows paginator
                    rowsPerPageOptions={[10, 20, 50]}
                    first={first}
                    rows={rows}
                    currentPageReportTemplate="{first} - {last} of {totalRecords}"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    paginatorClassName="justify-content-end"
                    responsiveLayout="scroll"
                    className={`col-12`}
                >
                    <Column expander style={{ width: '3em' }} />
                    <Column sortable field="date" header="Date" />
                    <Column sortable field="actions" header="Actions" body={(rowData: RealmLogInterface) => { return <div>{rowData.actions.length}</div> }} />
                    <Column field="points" header="Points Logs" body={(rowData: RealmLogInterface) => { return <div>{rowData.points?.length}</div> }} />
                    <Column sortable field="totalPoints" header="Total Points Earned" />
                </DataTable>
                :
                <p>No log found</p>
            }
        </div>
    )
};