import { configureStore } from "@reduxjs/toolkit"
import { persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import counterReducer from "./counter"
import spaceReducer from "./spaces"
import persistStore from "redux-persist/es/persistStore";

const spaceConfig = {
  key: 'space',
  storage
}

const spaceInfoReducer = persistReducer(spaceConfig, spaceReducer)

export const store = configureStore ({
  reducer: {
    counter: counterReducer,
    spaceInfo: spaceInfoReducer,
  },
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch