import { memo, useEffect, useLayoutEffect } from "react";
import * as THREE from "three";
import { useGLTF, useTexture } from "@react-three/drei";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three-stdlib";
import { useLoader } from "@react-three/fiber";

type ModelProps = {
    modelFilePath:string;
    textureFilePath?:string;
    animationFilePath?:string;
    pose?:number;
    scale?:number;
    rotation?:{
        x:number,
        y:number,
        z:number
    };
    position?:{
        x:number,
        y:number,
        z:number
    };
    [key: string]:any;
}

const CharacterModelGLTF = ({
    modelFilePath, 
    textureFilePath="", 
    animationFilePath="", 
    scale=1, 
    rotation={x:0,y:0,z:0},
    position={x:0,y:0,z:0},
    ...props }:ModelProps) =>{

    const gltf = useGLTF(modelFilePath, true, undefined, (loader) => {
        // loader.manager.setURLModifier((url:string)=>{
        //     let testUrl = url.substring(url.lastIndexOf('/')+1);
        //     console.log("https://content-sea.fromlabs.com/api/assets/virtualspace/" + testUrl.toLowerCase().replaceAll(/_|%20/g,"-"))
        //     if([".bin", ".png", '.jpg'].some(element=>testUrl.includes(element))){
        //         return "https://content-sea.fromlabs.com/api/assets/virtualspace/" + testUrl.toLowerCase().replaceAll(/_|%20/g,"-")
        //     }
        //     return url
        // })
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('/draco-gltf/')
        loader.setDRACOLoader(dracoLoader)
    })


    useEffect(()=>{
        // gltf.scene.scale.x
        gltf.scene.scale.set(scale * gltf.scene.scale.x, scale * gltf.scene.scale.y, scale * gltf.scene.scale.z)
        gltf.scene.rotateX(rotation.x)
        gltf.scene.rotateY(rotation.y)
        gltf.scene.rotateZ(rotation.z)
        gltf.scene.position.set(position.x, position.y, position.z)
    },[])
    return(
        <>
            <primitive object={gltf.scene} />
        </>
    )
}

export default memo(CharacterModelGLTF);