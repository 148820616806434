import { FormEventHandler, useEffect, useRef, useState } from "react";
import styles from './userManagementForm.module.scss';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { SQUIDEX_APP } from "../../constants";
import RolesService from "../../service/RolesService";
import { UserRealmForm, UserForm, errorsUserInterface } from "../enum/userForm";
import {roleInterface} from "../enum/roleInterface";
import _ from "lodash";

type formProp = {
    data?:UserForm;
    handleSubmit:Function;
    isCreated:boolean;
    errors?:errorsUserInterface;
}

const UsersManagementForm = ({data, handleSubmit, isCreated = false, errors}:formProp) =>{
    const [disabled, setDisabled] = useState(false)
    const rolesService = new RolesService()
    const [inputData, setInputData] = useState<UserForm>(data || {
        username:"",
        firstName:"",
        lastName:"",
        password:"",
        repassword:"",
        email:"",
        emailVerified:false,
        avatar: undefined,
        imgObj:undefined,
        role: undefined,
    })
    const fileUploadRef = useRef(null);

    const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
    const [uploadOptions, setUploadOptions] = useState({icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'hidden custom-upload-btn p-button-success p-button-rounded p-button-outlined'});
    const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'hidden custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};
    const [role, setRole] = useState()
    const statusOptions = [
        {
            label:"Unverified Email",
            value: false,
        },
        {
            label:"Verified Email",
            value: true,
        },
    ]

    const realmId = localStorage.getItem('realmId')

    const onSubmit = async () =>{
        // setDisabled(true)
        await handleSubmit(inputData)
        setDisabled(false)
        // setDisabled(false)
    }

    const cancelForm = () =>{
        setInputData({
            username: '',
            firstName: '',
            lastName: '',
            password: '',
            repassword: '',
            email: '',
            emailVerified: false,
            avatar: undefined,
            imgObj:undefined,
            role: undefined,
        });
        window.location.href="/#/users"
    }


    useEffect(()=>{
        !_.isEqual(data,inputData) && setInputData(data || {
            username:"",
            firstName:"",
            lastName:"",
            password:"",
            repassword:"",
            email:"",
            emailVerified:false,
            avatar: undefined,
            imgObj:undefined,
            role:undefined,
        })
    },[data])

    const itemTempFileUpload = (file:any, props:any)=>{
        if(inputData && inputData.avatar && typeof inputData.avatar === 'string'){
            return(
            <div className={`flex flex-wrap w-100 align-content-center align-items-center`}>
                <div className="col-1">
                    <img style={{width:'100%'}} src={`${process.env.SQUIDEX_URL}/api/assets/${SQUIDEX_APP}/${inputData.avatar}`}/>
                </div>
                <div className="col justify-content-left">
                    <p style={{textAlign:'left'}}>{inputData.imgObj?.fileName}</p>
                </div>
                <div className="col-1">
                    <Button onClick={()=>{
                        // FileUpload.prototype.clear()
                        // props.onRemove()
                        setInputData({...inputData, avatar: undefined})
                    }} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined" type='button' />
                </div>
            </div>
            )
        }else{
            return(
                <div className={`flex flex-wrap w-100 justify-content-center align-content-center align-items-center`}>
                    <div className="col-1">
                        <img style={{width:'100%'}} src={file?.objectURL || ''}/>
                    </div>
                    <div className={`justify-content-left col`}>
                        <p style={{textAlign:'left'}}>{file?.name || ''}</p>
                    </div>

                    {(file || inputData?.avatar) && <div className="col-1">
                        <Button onClick={()=>{
                            props.onRemove()
                            setInputData({...inputData, avatar: undefined})
                        }} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined" type='button' />
                    </div>}
                </div>
            )
        }
    }

    useEffect(() => {
        (async ()=>{
            let roleRes = await rolesService.getAllRolesByRealm(realmId!).then(res=> {
                let reconstruct = res.map((i:roleInterface)=> {return {label:i.name, value:i._id}})
                let roleFind:roleInterface = _.find(res, r=>r.name=="visitor")
                if(!(inputData.role) && roleFind){
                    setInputData({...inputData, role: roleFind._id})
                }
                return reconstruct
            })
            setRole(roleRes)
        })()
    },[])

    return(
        <form>
            <div className={`flex flex-column ${styles.formField}`}>
                    <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                        <label className="p-label block">Username *</label>
                        <div className="p-inputgroup">
                            <InputText disabled={!isCreated || disabled} required id="user_name" value={inputData.username} name="name" onChange={(e) => {
                                setInputData({...inputData, username: e.target.value})
                            }}/>
                        </div>
                        <small className="p-error block">{errors?.username}</small>
                    </div>
                {isCreated && <>    
                    <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                        <label className="p-label block">Password *</label>
                        <div className="p-inputgroup">
                            <InputText disabled={disabled} required id="password" type={'password'} value={inputData.password} name="password" onChange={(e) => {
                                setInputData({...inputData, password: e.target.value})
                            }}/>
                        </div>
                        <small className="p-error block">{errors?.password}</small>
                    </div>
                    <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                        <label className="p-label block">Confirm password *</label>
                        <div className="p-inputgroup">
                            <InputText disabled={disabled} required id="repassword" type={'password'} value={inputData.repassword} name="repassword" onChange={(e) => {
                                setInputData({...inputData, repassword: e.target.value})
                            }}/>
                        </div>
                        <small className="p-error block">{errors?.repassword}</small>
                    </div>
                    <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                        <label className="p-label block">First Name *</label>
                        <div className="p-inputgroup">
                            <InputText disabled={disabled} required id="first_name" value={inputData.firstName} name="name" onChange={(e) => {
                                setInputData({...inputData, firstName: e.target.value})
                            }}/>
                        </div>
                        <small className="p-error block">{errors?.firstName}</small>
                    </div>
                    <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                        <label className="p-label block">Last Name *</label>
                        <div className="p-inputgroup">
                            <InputText disabled={disabled} required id="last_name" value={inputData.lastName} name="name" onChange={(e) => {
                                setInputData({...inputData, lastName: e.target.value})
                            }}/>
                        </div>
                        <small className="p-error block">{errors?.lastName}</small>
                    </div>
                </>}
                <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                    <label className="p-label block">Email *</label>
                    <div className="p-inputgroup">
                        <InputText disabled={disabled} required id="user_email" type={'email'} value={inputData.email} name="email" onChange={(e) => {
                            setInputData({...inputData, email: e.target.value})
                        }}/>
                    </div>
                    <small className="p-error block">{errors?.email}</small>
                </div>
                <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                    <label className="p-label block">Status </label>
                    <div className="p-inputgroup">
                        <Dropdown disabled={disabled} required name="status" options={statusOptions} value={inputData.emailVerified} onChange={(e:{value:any})=>{{
                            setInputData({...inputData, emailVerified:e.value})
                        }}}/>
                    </div>
                    <small className="p-error block">{errors?.emailVerified}</small>
                </div>
                {role && <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                    <label className="p-label block">Role </label>
                    <div className="p-inputgroup">
                        <Dropdown disabled={disabled} required name="Role" options={role} value={inputData.role} onChange={(e:{value:any})=>{{
                            setInputData({...inputData, role:e.value})
                        }}}/>
                    </div>
                    <small className="p-error block">{errors?.role}</small>
                </div>}
                {inputData.avatar && <></>}
                <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                    <label className='p-label block'>Profile Picture *</label>
                    <FileUpload disabled={disabled} name="profile" ref={fileUploadRef} multiple={false} accept="image/*" maxFileSize={1000000} customUpload={true}
                    chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
                    onClear={()=>setInputData({...inputData, avatar: undefined})}
                    onSelect={(e) => {e.files?.length>0 && setInputData({...inputData, avatar: e.files[0]})}} 
                    itemTemplate={(e, props)=>itemTempFileUpload(e, props)}
                    emptyTemplate={()=>itemTempFileUpload(undefined, undefined)}
                    />
                </div>
                <small className="p-error block">{errors?.avatar}</small>
            </div>
            {!_.isEqual(data, inputData) && <div className={`flex`}>
                {/* <Button style={{color:'var(--text-color)'}} label="Cancel" className="p-button-outlined mr-2" onClick={()=>{cancelForm()}}/> */}
                <Button disabled={disabled} type='button' label={isCreated?"Add user data":"Update user data"} className={`flex-1 ${styles.btn}`} onClick={()=>{onSubmit()}}/>
            </div>}
        </form>
    )
}

export default UsersManagementForm;