import { useState } from "preact/hooks";
import { InputText } from "primereact/inputtext";
import { ChangeEvent } from "react";
import styles from './search.module.scss'
type SearchProps = {
    onSearch: Function,
    searchValue: string,
}

const Search = ({ onSearch, searchValue }: SearchProps) => {
    return (
        <span className={`p-input-icon-left ${styles.searchContainer}`}>
            <i className="pi pi-search" />
            <InputText value={searchValue} onChange={(e) => { onSearch(e.target.value) }} placeholder="Search" />
        </span>
    )
}

export default Search;