import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import styles from './editSpace.module.scss'
import React, { useEffect, useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import SpacesService from "../../service/SpacesService";
import { generateUUID } from "../../utilities/Functions";
import { SpaceForm } from "../enum/SpaceInterface";
import { InputSwitch } from "primereact/inputswitch";
import _ from "lodash";

interface spaceId {id: string}

export const EditSpace = ({id}: spaceId) => {
  const spacesService = new SpacesService
  const [space, setSpace] = React.useState<Partial<SpaceForm>>({})
  const [editSpace, setEditSpace] = React.useState<Partial<SpaceForm>>({})
  const fileUploadRef = useRef(null);

  const statusOption = [
    {label: 'Active', value: 'Active'},
    {label: 'UnActive', value: 'UnActive'}
  ]
  
  const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
  const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
  const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

  useEffect(()=>{
    spacesService.getSpaceById(id).then(res =>{
      setSpace(
        {
          space_name: `${res.name}`,
          description: `${res.description}`,
          status: ``,
          banner: {},
          room_id: `${res.roomID}`,
          post_processing: res.postProcessing,
          login_required: res.loginRequired, 
          call_login_required: res.callLoginRequired,
          chat_login_required : res.chatLoginRequired,
          npcs: res.npcs,
          is_private: res.isPrivate
        }
      )
      setEditSpace(
        {
          space_name: `${res.name}`,
          description: `${res.description}`,
          status: ``,
          banner: {},
          room_id: `${res.roomID}`,
          post_processing: res.postProcessing,
          login_required: res.loginRequired, 
          call_login_required: res.callLoginRequired,
          chat_login_required : res.chatLoginRequired,
          npcs: res.npcs,
          is_private: res.isPrivate
        }
      )
    })
  },[])

  const generatingRoomID = () => {
    setEditSpace({
      ...editSpace,
      room_id: generateUUID(20)
    })
  }

  const handleSubmit = () => {
    spacesService.updateSpace(editSpace, id).then(res => {
      
    })
  }

  return (
    <React.Fragment>
      <div className={`${styles.editSpace}`}>
        <div className={`flex justify-content-between flex-wrap ${styles.header}`}>
          <h3 className=" align-items-center justify-content-center font-bold">Edit Space</h3>
        </div>
        <form onSubmit={handleSubmit} className={'flex flex-column'}>
          <div className={`flex flex-column ${styles.formField}`}>
            <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
              <label className="p-label block">Space Name *</label>
              <div className="p-inputgroup">
                <InputText placeholder="eg: Chick-fil-A" className={`${styles.inputText}`} required id="spacename" value={editSpace.space_name} onChange={(e) => setEditSpace({...editSpace, space_name: e.target.value})}/>
              </div>
            </div>
            <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
              <label className="p-label block">Description *</label>
              <div className="p-inputgroup">
              <InputText placeholder="eg: Chicken nugget" className={`${styles.inputText}`} required id="subspacename" value={editSpace.description} onChange={(e) => setEditSpace({...editSpace, description: e.target.value})}/>
              </div>
            </div>
            <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
              <label className="p-label block">Room ID *</label>
              <div className={`p-inputgroup ${styles.idGenerateInput}`}>
                <InputText disabled placeholder="eg: Chicken nugget" className={`${styles.inputText}`} required id="subspacename" value={editSpace.room_id} onChange={(e) => setEditSpace({...editSpace, room_id: e.target.value})}/>
                <div className={`${styles.idGenerateBtn}`}>
                  <Button type="button" icon="pi pi-undo" className="p-button-warning" onClick={generatingRoomID}/>
                </div>
              </div>
            </div>
            <div className={`field align-items-center justify-content-center my-3 ${styles.optionBtnGroup}`}>
              <div className="flex flex-wrap justify-content-between">
                <div className={`flex flex-wrap w-18rem card p-4 border-round ${styles.switchSelect} `}>
                  <label className="p-label block">Login Require ? *</label>
                  <div className="p-inputgroup">
                    <InputSwitch checked={editSpace.login_required} onChange={(e) => setEditSpace({...editSpace, login_required: e.target.value})} />
                  </div>
                </div>
                <div className={`flex flex-wrap w-18rem card p-4 border-round ${styles.switchSelect} `}>
                  <label className="p-label block">Privated ? *</label>
                  <div className="p-inputgroup">
                    <InputSwitch checked={editSpace.is_private} onChange={(e) => setEditSpace({...editSpace, is_private: e.target.value})} />
                  </div>
                </div>
                <div className={`flex flex-wrap w-18rem card p-4 border-round ${styles.switchSelect} `}>
                  <label className="p-label block">Npcs ? *</label>
                  <div className="p-inputgroup">
                    <InputSwitch checked={editSpace.npcs} onChange={(e) => setEditSpace({...editSpace, npcs: e.target.value})} />
                  </div>
                </div>
                <div className={`flex flex-wrap w-18rem card p-4 border-round ${styles.switchSelect} `}>
                  <label className="p-label block">Post Processing ? *</label>
                  <div className="p-inputgroup">
                    <InputSwitch checked={editSpace.post_processing} onChange={(e) => setEditSpace({...editSpace, post_processing: e.target.value})} />
                  </div>
                </div>
                <div className={`flex flex-wrap w-18rem card p-4 border-round ${styles.switchSelect} `}>
                  <label className="p-label block">Call Login Required ? *</label>
                  <div className="p-inputgroup">
                    <InputSwitch checked={editSpace.call_login_required} onChange={(e) => setEditSpace({...editSpace, call_login_required: e.target.value})} />
                  </div>
                </div>
                <div className={`flex flex-wrap w-18rem card p-4 border-round ${styles.switchSelect} `}>
                  <label className="p-label block">Chat Login Required ? *</label>
                  <div className="p-inputgroup">
                    <InputSwitch checked={editSpace.chat_login_required} onChange={(e) => setEditSpace({...editSpace, chat_login_required: e.target.value})} />
                  </div>
                </div>
              </div>
            </div>
            <div className={`field align-items-center justify-content-center my-3 ${styles.dropDownField}`}>
              <label className="p-label block">Status *</label>
              <div className={`p-inputgroup`}>
                <Dropdown placeholder="Select status" className={`${styles.inputText}`} value={editSpace.status} options={statusOption} 
                onChange={(e) => 
                  {
                    setEditSpace({...editSpace, status: e.target.value})
                }} optionLabel="label" editable />
              </div>
            </div>
            {/* {editSpace.banner && <div className={`field align-items-center justify-content-center my-3 ${styles.uploadFile}`}>Q</div>} */}
            <div className={`field align-items-center justify-content-center my-3 ${styles.uploadFile}`}>
              <label className='p-label block'>Banner Picture *</label>
              <FileUpload ref={fileUploadRef} multiple accept="image/*" maxFileSize={1000000} customUpload={true} className={`${styles.fileUpload}`}
              chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} uploadHandler={(e) => {setEditSpace({...editSpace, banner: e})}} />
            </div>
          </div>
          { !_.isEqual(space, editSpace) && <Button type="submit" label="Save Data" className={`flex justify-content-center flex-wrap ${styles.button}`} />}
        </form>
      </div>
    </React.Fragment>
  )
}