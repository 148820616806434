import { useEffect, useState } from "react";
import styles from './userManagementForm.module.scss';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { SQUIDEX_APP, SQUIDEX_URL } from "../../constants";
import { Column } from 'primereact/column';
import { permissionsInterface, roleInterface, roleManageData } from "../enum/roleInterface";
import PermissionsService from "../../service/PermissionsService";
import { DataTable } from "primereact/datatable";
import { Checkbox } from "primereact/checkbox";
import _ from "lodash";

type formProp = {
    data:roleInterface;
    handleSubmit:Function;
    isCreated:boolean;
}

const RolesManagementForm = ({data, handleSubmit, isCreated = false}:formProp) =>{
    const [disabled, setDisabled] = useState(false)
    const [inputData, setInputData] = useState<roleInterface>({
        name:"",
        permissions:[]
    })
    const [permissions, setPermissions] = useState<Array<roleManageData>>([
    ])
    let columnSet =[
        {field:'name', header:"Name"},
        {field:'write', header:'Write'},
        {field:'read', header:'Read'},
        {field:'delete', header:'Delete'},
        
    ]
    const permissionService = new PermissionsService()
    const onSubmit = async () =>{
        setDisabled(true)
        await handleSubmit(inputData)
        setDisabled(false)
        // setDisabled(false)
    }

    const cancelForm = () =>{
        setInputData({
            name: '',
            permissions:[]
        });
        window.location.href="/#/roles"
    }


    useEffect(()=>{
        (async ()=>{
            await permissionService.getAllPermissions().then(res=>{
                let reconstructRes:Array<roleManageData> = [];
                for (let i = 0; i < res.length; i++) {
                    const element = res[i];
                    if(element.isAssignable){
                        let permissionName = element.name.split('.')
                        let foundE = reconstructRes.find((e)=>e?.name == permissionName[0])
                        if(!foundE){
                            reconstructRes.push({name:permissionName[0] || '', data: { [permissionName[(permissionName.length - 1)]]: !(data?.permissions ? data.permissions.findIndex(e=>e?.name == element?.name)<0  : true) }})
                        }else{

                            foundE.data = {...foundE.data, [permissionName[permissionName.length - 1]]: !(data?.permissions ? data.permissions.findIndex(e=>e?.name == element?.name)<0 : true) }
                        }
                    }
                }
                setPermissions(reconstructRes)
            })

        })()
        data !== inputData && setInputData(data? data:{
            name:"",
            permissions:[]
        })
    },[data])

    const changePermissions = (col:any, permissionName:string ,isChecked:boolean) => {
        let input = inputData
        let newPermissions:Array<roleManageData> = []
        let inputPerFound = inputData.permissions.findIndex(inputPermission=> inputPermission?.name === `${col.name}.${permissionName}`)
        if(inputPerFound<0 && isChecked){
            input.permissions.push({name: `${col.name}.${permissionName}`})
        }else if(inputPerFound>-1 && !isChecked){
            input.permissions.splice(inputPerFound,1)
        }
        for (let i = 0; i < permissions.length; i++) {
            const permission = permissions[i];
            if(permission == col){
                permission.data = {...permission.data, [permissionName]:isChecked}
            }
            newPermissions.push(permission)
        }
        setPermissions(newPermissions)
        setInputData(input)
    }

    const dynamicColumns = columnSet.map((col) => {
        if(col.field == 'name'){
            return(
                <Column key={col.field} field={col.field} header={col.header} style={{width:'70%'}}/>
            )
            // return rowData.name
        }else{
            return <Column key={col.field} field={col.field} header={col.header} style={{width:'10%'}}
            body={(rowData)=>{return !_.isUndefined(rowData.data[col.field]) && <Checkbox disabled={disabled} checked={rowData.data[col.field]} onChange={e=>{changePermissions(rowData, col.field, e.checked)}} />}}
            />
        }
        
    });

    return(
        <form className={`mx-5`}>
            <div className={`flex flex-column ${styles.formField}`}>
                <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                    <label className="p-label block">Role Name *</label>
                    <InputText style={{width:'100%'}} disabled={disabled} required id="name" type={'text'} value={inputData.name} name="name" onChange={(e) => {
                            setInputData({...inputData, name: e.target.value})
                    }}/>
                </div>
                <h2>Permissions:</h2>
                <div className="mt-2 mb-5">
                    <DataTable
                    value={permissions}
                    className="p-datatable-customers"
                    responsiveLayout="scroll"
                    >
                        {dynamicColumns}
                    </DataTable>
                </div>
            </div>
            <div className={`${styles.buttonGroup} flex justify-content-end `}>
                <Button style={{color:'var(--text-color)'}} label="Cancel" className="p-button-outlined mr-2" onClick={()=>{cancelForm()}}/>
                <Button disabled={disabled} type='button' label={isCreated?"Add role":"Update role data"} className={`ml-2 ${styles.btn}`} onClick={()=>{onSubmit()}}/>
            </div>
        </form>
    )
}

export default RolesManagementForm;