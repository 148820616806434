

// const FaqsView = () => {
//     return (
//     <div className="" >
//       FAQ VIEW
//     </div>
//   )
// }

// export default FaqsView

import { faCircle, faCircleDot, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import SpacesService from "../service/SpacesService";
import FAQService from '../service/FAQService';
import Filter from "./form/Filter";
import Search from "./form/Search";
import styles from './FaqsView.module.scss'
import globalStyles from './pageStyle.module.scss';
import tableStyles from './tableStyle.module.scss';

import { AppDispatch, RootState } from "../redux/store";
import { useSelector, useDispatch } from 'react-redux'
import spaces, { getSpaceInfoByRealm } from "../redux/spaces";
import { SpaceForm } from "./enum/SpaceInterface";
import { AnyARecord } from "dns";

interface faqSection {
  sectionTitle: string;
  sectionId: string;
  faqsNumber: number;
}

const FaqsView = () => {
  const accessToken = localStorage.getItem("token")
  const realmId = localStorage.getItem("realmId")
  const spacesService = new SpacesService();
  const faqService = new FAQService()
  const toast = useRef<Toast>(null);
  const [selectedFilter, setSelectedFilter] = useState()
  const [visibleFilter, setVisibleFilter] = useState(false)
  const [sectionList, setSectionList] = useState<Array<faqSection>>([])
  const [sectionListFiltered, setSectionListFiltered] = useState<Array<faqSection>>([])

  const space = useSelector( (state: RootState) => state.spaceInfo.spaces )

  useEffect(() => {
    let sectionArray:Array<faqSection> = []
    space && space.map((spaceInfo: any) => {
      faqService.getFaqFromSpace(spaceInfo._id).then(res => {
        res.items?.map((faq: any) => {
          let sectionItem:faqSection = {
            "sectionTitle": faq.section,
            "sectionId": faq.sectionId,
            "faqsNumber": faq.list.length,
          }
          sectionArray.push(sectionItem)
        })
      })
    })
    setSectionList(sectionArray)
  },[])

  const [selectedSearch, setSelectedSearch] = useState('')
  const EntriesOptions = [
    10,
    50,
    100,
  ]
  const [selectedEntries, setSelectedEntries] = useState(10)

  useEffect(()=>{
    if(selectedSearch!=''){
      setSectionListFiltered(sectionList.filter(section => section.sectionTitle === selectedSearch))
    }
  },[selectedSearch])

  const onClear = () => {
    setSelectedSearch('')
  }

  const renderHeaderTable = () => {
    return (
        <div className="flex flex-wrap align-content-center justify-content-between align-items-center">
          <div className={`col flex flex-wrap align-items-center align-content-center`}>
            <p className={`flex-initial flex align-items-center justify-content-center align-content-center ${tableStyles.entries}`}>
              Show
              <Dropdown className={`mx-1 flex-initial flex align-items-center justify-content-center align-content-center `} options={EntriesOptions} value={selectedEntries} onChange={(e:{value:any})=>setSelectedEntries(e.value)}/>
              Entries
            </p>
          </div>
          <Search onSearch={setSelectedSearch} searchValue={selectedSearch}/>
        </div>
    )
  }
  
  return(
    <>
      <div className={` ${globalStyles.header} `}>
        <div className={``}>
          <h1>FAQs List</h1>
          <p></p>
        </div>
      </div>
      <div className={`${globalStyles.bodyWrapper}`}>
      <div className={`card ${globalStyles.content}`}>
        <Toast ref={toast}/>
        <div className={`flex justify-content-between flex-wrap ${styles.faqsHeader}`}>
          <h3 className=" align-items-center justify-content-center font-bold m-2">FAQs List</h3>
          <div className={`flex justify-content-between flex-wrap card-container purple-container ${styles.buttonGroup}`}>
            <Button style={{ color: 'var(--surface-0)', backgroundColor: '#001E4A'}} type="button" label="Clear search" className={`align-items-center justify-content-center m-2`}  onClick={()=>onClear()}/>
          </div>
        </div>
        { sectionListFiltered &&
          <DataTable 
          header={renderHeaderTable()}
          rows={selectedEntries}
          paginator={true} 
          value={selectedSearch ? sectionListFiltered : sectionList}
          responsiveLayout="scroll"
          emptyMessage="No Spaces found."
          className={`${styles.faqs}`}
          paginatorClassName={`grid ${tableStyles.paginator}`}
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate={"Showing {first} to {last} of {totalRecords}"}
          >
          <Column header= "Title" field="sectionTitle" sortable/>
          <Column header= "Section ID" field="sectionId" sortable/>
          <Column header= "Owner" field="faqsNumber" sortable/>
        </DataTable>
        }
      </div>
      </div>
    </>
  )
}

export default FaqsView