import React, { useState, useRef, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { Link, Route, RouteProps, Switch, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppConfig from './AppConfig';
import AppBreadcrumb from "./AppBreadcrumb";

import PrimeReact from 'primereact/api';
import { Tooltip } from "primereact/tooltip";

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './App.scss';
import PermissionRoute from './components/PermissionRoute';
import SpacesManagement from './components/SpacesManagement';
import {FormTest} from './components/form/FormTest';
import { EditSpace } from './components/space/editSpace';
import { CreateSpace } from './components/space/createSpace';
import UsersManagement from './components/UsersManagement';
import { EditFrame } from './components/space/spaceEditFrame';
import CreateUser from './components/users/CreateUser';
import EditUser from './components/users/EditUser';
import Streaming from './components/Streaming';
import { SpaceStreaming } from './components/space/SpaceStreaming';
import RolesManagement from './components/RolesManagement';
import EditRole from './components/roles/EditRole';
import ErrorPages from './components/ErrorPage';
import CreateRole from './components/roles/CreateRole';
import SpacesUsersManagement from './components/SpacesUsersManagement';
import CreateSpacesUsers from './components/spacesUsers/CreateSpaceUser';
import EditSpacesUsers from './components/spacesUsers/EditSpaceUser';
import { permissionsInterface } from './components/enum/roleInterface';
import PermissionsService from './service/PermissionsService';
// import FAQ from './components/ModelView';
import ModelView from './components/ModelView';
import { EditUserFrame } from './components/users/EditUserFrame';
import VoucherManagement from './components/voucher/VoucherManagement';
import { VoucherItemsList } from './components/voucher/VoucherItemsList';
import { CreateVoucher } from './components/voucher/CreateVoucher';
import { EditVoucher } from './components/voucher/EditVoucher';
// import { TieredMenu } from 'primereact/tieredmenu';
import CreateUserFrame from './components/users/CreateUserFrame';
import { CountTest } from './components/CountTest';
import FaqsView from './components/FaqsView';
import Game from './components/gamification/GameManagement';
import GameEdit from './components/gamification/EditGame';
import styles from './App.module.scss'
import LeaderBoard from './components/gamification/Leaderboard';

// interface Props extends RouteProps {
//     isAuthenticate: boolean;
//     accessToken: String | undefined;
// }

const App = () => {

    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [layoutMode, setLayoutMode] = useState('static');
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [themeColor, setThemeColor] = useState('deeppurple');
    const [layoutColor, setLayoutColor] = useState('deeppurple');
    const [darkMenu, setDarkMenu] = useState(false);
    // const [inputStyle, setInputStyle] = useState('outlined');
    const inputStyle = 'outlined'
    const [ripple, setRipple] = useState(true);
    const [colorMode, setColorMode] = useState('light');

    const sidebar = useRef<any>(null);
    const copyTooltipRef = useRef<any>();
    const location = useLocation<any>();

    PrimeReact.ripple = true;


    let menuClick: boolean = false;
    let menuButtonClick: boolean = false;
    let topbarMenuClick: boolean = false;
    let topbarMenuButtonClick: boolean = false;
    let sidebarTimeout: any = null;

    const menu = [
        {
            label: 'Favorites', icon: 'pi pi-fw pi-home',
            items: [
                { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
            ]
        },
        {
            label:"Administrator",
            items:[
                {label:"Users Management", icon:'pi pi-fw pi-user', to:'/users'},
                {label:"Roles Management",icon:'pi pi-fw pi-briefcase', to:'/roles'},
                // {label:"Spaces Users Management",icon:'pi pi-fw pi-box', to:'/spaces-users'},
            ]
        },
        {
            label:"Spaces", icon:'pi pi-fw pi-user',
            items:[
                {label:"Spaces Management", icon:'pi pi-fw pi-globe', to:'/spaces-management'},
                {label:"Streaming", icon:'pi pi-stop-circle', to:'/streaming'},
                {label: "FAQs", icon:'pi pi-question-circle', to:'/faqs'},
            ]
        },
        {
            label:"Gamification", icon:'pi-th-large',
            items:[
                {label:"Game", to:'/game'},
                {label: "Leaderboard", to:'/leaderboard'},
            ]
        },
        {
            label: "Model",
            items: [
                {label:"View", to:'/model-view'}
            ]
        },
        // {
        //     label: "Test",
        //     items: [
        //         {label:"Test", to:'/app-test'}
        //     ]
        // },
        {
            label: "Other",
            items: [
                {label:"Voucher",icon:'pi pi-fw pi-ticket' ,to:'/voucher'}
            ]
        }
    ];

    const [permissions, setPermissions]=useState<Array<permissionsInterface>>([])
    const permissionsService = new PermissionsService()
    useEffect(()=>{
        (async()=>{
            permissionsService.getAllPermissions().then()
        })()
    },[])


    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        changeStyleSheetUrl('layout-css', layoutColor, 'layout');
        changeStyleSheetUrl('theme-css', colorMode, 'theme');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // const onInputStyleChange = (inputStyle: string) => {
    //     setInputStyle(inputStyle);
    // }

    // app config setting
    const onRipple = (e: any) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onLayoutModeChange = (mode: string) => {
        setLayoutMode(mode);
    }

    const onDarkMenuChange = (mode: string) => {
        if (mode === 'dark') {
            setDarkMenu(true)
        }
        else {
            setDarkMenu(false);
        }
    }

    const onColorModeChange = (mode: string) => {
        setDarkMenu(mode === 'dark')
        setColorMode(mode);

        let element = document.getElementById('theme-css');
        let urlTokens = element?.getAttribute('href')?.split('/');
        if (urlTokens) {
            urlTokens[urlTokens.length - 1] = 'theme-' + mode + '.css';
            let newURL = urlTokens.join('/');

            replaceLink(element, newURL);
        }
    }
    //

    const onWrapperClick = () => {
        if (!menuClick && !menuButtonClick && mobileMenuActive) {
            setMobileMenuActive(false)
        }

        if (!topbarMenuClick && !topbarMenuButtonClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isOverlay()) {
                setMenuActive(false)
            }
        }

        menuClick = false;
        menuButtonClick = false;
        topbarMenuClick = false;
        topbarMenuButtonClick = false;
    }

    const onTopbarItemClick = (event: any) => {
        topbarMenuClick = true;
        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null)
        else
            setActiveTopbarItem(event.item)
        event.originalEvent.preventDefault();

    }

    const onMenuButtonClick = (event: Event) => {
        menuButtonClick = true;

        if (isMobile()) {
            setMobileMenuActive(prevState => !prevState);
        }

        event.preventDefault();
    }

    const onTopbarMobileMenuButtonClick = (event: Event) => {
        topbarMenuButtonClick = true;
        setTopbarMenuActive(prevState => !prevState)
        event.preventDefault();
    }

    const onToggleMenuClick = () => {
        setLayoutMode(prevState => prevState !== 'static' ? 'static' : 'overlay')
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onSidebarMouseEnter = () => {
        if (layoutMode !== 'horizontal') {
            setMenuActive(true);
        }
        if (sidebarTimeout) {
            clearTimeout(sidebarTimeout);
        }
        addClass(sidebar.current, 'layout-sidebar-active');
    }

    const onSidebarMouseLeave = () => {
        if (layoutMode !== 'horziontal') {
            setMenuActive(false);
        }
        sidebarTimeout = setTimeout(() => {
            removeClass(sidebar.current, 'layout-sidebar-active');
        }, 0);
    }

    const addClass = (element: any, className: string) => {
        if (element) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        }
    }

    const removeClass = (element: any, className: string) => {
        if (element) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    const onRootMenuItemClick = (event: any) => {
        setMenuActive(prevState => !prevState)
    }

    const onMenuItemClick = (event: any) => {
        if (!event.item.items && isHorizontal()) {
            setMenuActive(false)
        }

        if (!event.item.items && isMobile()) {
            setMobileMenuActive(false);
            setMenuActive(false);
            onSidebarMouseLeave();
        }
    }

    const changeTheme = (event: string) => {
        setThemeColor(event);

        const themeLink = document.getElementById('theme-css');
        const themeHref = 'assets/theme/' + event + '/theme-' + colorMode + '.css';
        replaceLink(themeLink, themeHref);
    }

    const changeLayout = (event: string) => {
        setLayoutColor(event)

        const layoutLink = document.getElementById('layout-css');
        const layoutHref = 'assets/layout/css/layout-' + event + '.css';
        replaceLink(layoutLink, layoutHref);
    }

    const changeStyleSheetUrl = (id: string, value: string, prefix: string) => {
        let element = document.getElementById(id);
        let urlTokens = element?.getAttribute('href')?.split('/');
        if (urlTokens) {
            urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
            let newURL = urlTokens.join('/');
            replaceLink(element, newURL);
        }
    }

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    const replaceLink = (linkElement: any, href: string) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    const isMobile = () => {
        return window.innerWidth <= 1024;
    }

    const isOverlay = () => {
        return layoutMode === 'overlay';
    }

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    }

    let wrapperClass = classNames('layout-wrapper', {
        'layout-wrapper-static': layoutMode === 'static',
        'layout-wrapper-active': mobileMenuActive,
        'layout-menu-horizontal': layoutMode === 'horizontal',
        // 'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple
    });

    let sidebarClassName = classNames("layout-sidebar", { 'layout-sidebar-dark': darkMenu });

    return (
        <div 
        className={wrapperClass} 
        onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div ref={sidebar} className={`${styles.sideBar} ${sidebarClassName}`}
                onClick={onSidebarClick}
                onMouseEnter={onSidebarMouseEnter} onMouseLeave={onSidebarMouseLeave}
            >

                <div className={`sidebar-logo ${styles.sideBarLogo}`}>
                    <Link to={"/"}>
                        <button className="p-link">
                            {/* <span className="app-name">OUR3D.SPACE</span> */}
                            <img alt="logo" src="assets/layout/images/our3d-logo-2.png" style={{padding: '9px'}} />
                            <img alt="logo" src="assets/layout/images/our3d-logo-1.png" />
                        </button>
                    </Link>
                    {/* <button className="p-link sidebar-anchor" title="Toggle Menu" onClick={onToggleMenuClick}> </button> */}
                </div>

                <div className={`layout-menu-container ${styles.layoutMenuContainer}`}>
                    <AppMenu model={menu} onRootMenuItemClick={onRootMenuItemClick} layoutMode={layoutMode}
                        active={menuActive} onMenuItemClick={onMenuItemClick} />
                </div>
            </div>
            <div className="layout-main">
                <AppTopbar layoutMode={layoutMode} activeTopbarItem={activeTopbarItem} onTopbarItemClick={onTopbarItemClick}
                    onMenuButtonClick={onMenuButtonClick} onTopbarMobileMenuButtonClick={onTopbarMobileMenuButtonClick}
                    topbarMenuActive={topbarMenuActive} isMobile={isMobile} />

                {/* <AppBreadcrumb /> */}
                <div className="layout-content">
                    <Switch>

                        {/* ADMINISTRATOR */}
                        <PermissionRoute path="/users" component={UsersManagement} />
                        <PermissionRoute path="/spaces-users" component={SpacesUsersManagement} />
                        <PermissionRoute path="/user/create" component={CreateUserFrame} />
                        <PermissionRoute path="/user/:id" component={EditUserFrame}/>
                        <PermissionRoute path="/roles" component={RolesManagement} />
                        <PermissionRoute path="/role/:name/edit" component={EditRole} />
                        <PermissionRoute path="/role/create" component={CreateRole} />

                        {/* SPACES */}
                        <PermissionRoute path="/spaces-management" component={SpacesManagement} />
                        <PermissionRoute path="/create-new-space" component={CreateSpace}/>
                        <PermissionRoute path="/space/:id" component={EditFrame} />
                        <PermissionRoute path="/streaming" component={Streaming} />
                        <PermissionRoute path="/streaming-view/:id" component={SpaceStreaming} />
                        <PermissionRoute path="/form-test" component={FormTest} />
                        <PermissionRoute path="/faqs" component={FaqsView} />

                        {/* MODEL */}
                        <PermissionRoute path="/model-view" component={ModelView} />

                        {/* GAMIFICATION */}
                        <PermissionRoute path="/game" component={Game}/>
                        <PermissionRoute path="/game_edit" component={GameEdit} />
                        <PermissionRoute path="/leaderboard" component={LeaderBoard} />

                        {/* OTHER */}
                        <PermissionRoute path="/voucher" component={VoucherManagement} />
                        <PermissionRoute path="/voucher-create" component={CreateVoucher} />
                        <PermissionRoute path="/voucher-items/:code" component={VoucherItemsList} />
                        <PermissionRoute path="/voucher-edit/:code" component={EditVoucher} />

                        {/* <PermissionRoute path="/app-test" component={CountTest}/> */}

                        <Route path={'/error-page/:errorCode'} component={ErrorPages}/>
                    </Switch>
                </div>

                {/* <AppConfig inputStyle={inputStyle} 
                // onInputStyleChange={onInputStyleChange}
                    rippleEffect={ripple} onRippleEffect={onRipple}
                    layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange}
                    darkMenu={darkMenu} onDarkMenuChange={onDarkMenuChange}
                    layoutColor={layoutColor} changeLayout={changeLayout}
                    themeColor={themeColor} changeTheme={changeTheme}
                    colorMode={colorMode} onColorModeChange={onColorModeChange} /> */}

                <AppFooter />

                {mobileMenuActive && <div className="layout-main-mask"></div>}
            </div>
        </div>
    );

}

export default App;
