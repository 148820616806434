import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect, useRef, useState } from "react";
import styles from "./EditVoucher.module.scss"

interface CreateVoucher {
  name: string;
  point_exchange: number;
  code: string;
  limit_voucher: number;
  start_date: Date | Date[];
  end_date: Date | Date[];
  description: string;
  image: object;
}

interface RouteParams { code: string };

export const EditVoucher = () => {
  const fileUploadRef = useRef(null);
  const [displayForm, setDisplayForm] = useState(false);
  const [editVoucher, setEditVoucher] = useState<Partial<CreateVoucher>>({})


  const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
  const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
  const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

  useEffect(() => {
    //loadData and setEditVouchers
    setEditVoucher({
      name: `chick-fil-A`,
      point_exchange: 12,
      code: `CHICK50COUPON`,
      limit_voucher: 12,
      start_date: new Date(),
      end_date: new Date(),
      description: `2 for 1 ringit`,
    })
  }, [])

  const HandleSubmit = () => {
    // call update API
    setDisplayForm(false)
  }
  
  return(
    <div className={`${styles.editVoucher}`}>
      <div className={`grid mx-1 mb-5`}>
        <div className={`col`}>
          <h1>Space</h1>
          <p>You can manage all the space data in this page easier</p>
        </div>
      </div>
      <div className={`card`}>
        <div className={`flex justify-content-between flex-wrap ${styles.header}`}>
          <h3 className=" align-items-center justify-content-center font-bold">Edit Voucher</h3>
          <Button type="button" label="Back to space list" className={`align-items-center justify-content-center ${styles.returnButton}`} 
            onClick={()=>{window.location.href = "#/voucher/"}}
          />
        </div>
        <div className={`content p-fluid grid field ${styles.formField}`}>
          <div className="col-6 mb-3">
            <label>Voucher Name *</label>
            <div className="p-inputgroup">
              <InputText className={`${styles.inputBox}`} id={`name`} placeholder="StarBuck"
                value={editVoucher.name} 
                onChange={(e) => {setEditVoucher({...editVoucher, name: e.target.value});}}
                required
              />
            </div>
          </div>
          <div className="col-6 mb-3">
            <label>Point Exchange *</label>
            
            <div className="p-inputgroup">
              <InputNumber className={`${styles.inputBox}`} id={`point_exchange`} useGrouping={false}
                value={editVoucher.point_exchange} 
                allowEmpty={false}
                onChange={(e) => {setEditVoucher({...editVoucher, point_exchange: e.value!});}}
                required
              />
            </div>
          </div><div className="col-6 mb-3">
            <label className="label">Voucher Code *</label>
            <InputText className={`${styles.inputBox}`} id={`code`} placeholder="StarBuck"
              value={editVoucher.code} 
              onChange={(e) => {setEditVoucher({...editVoucher, code: e.target.value});}}
              required
            />
          </div>
          <div className="col-6 mb-3">
            <label>Limit Voucher *</label>
            <InputNumber className={`${styles.inputBox}`} id={`limit_voucher`} useGrouping={false} 
              value={editVoucher.limit_voucher} 
              allowEmpty={false}
              onChange={(e) => {setEditVoucher({...editVoucher, limit_voucher: e.value!});}}
              required
            />
          </div>
          <div className="col-6 mb-3">
            <label className="label">Start Date *</label>
            <Calendar className={`${styles.inputBox}`} id={`start`}
                value={editVoucher.start_date} 
                onChange={(e) => {
                  setEditVoucher({...editVoucher, start_date: e.target.value ?? new Date()});
                }}
                required
              />
          </div>
          <div className="col-6 mb-3">
            <label>End Date *</label>
            <Calendar className={`${styles.inputBox}`} id={`end_date`}
              value={editVoucher.end_date} 
              onChange={(e) => {
                setEditVoucher({...editVoucher, end_date: e.target.value ?? new Date()});
              }}
              required
            />
          </div>
          <div className="col-12 mb-3">
            <label>Description *</label>
            <div className="p-inputgroup">
              <InputTextarea className={`${styles.inputBox}`} id={`name`} placeholder="StarBuck" rows={5} cols={30}
                value={editVoucher.description} 
                onChange={(e) => {setEditVoucher({...editVoucher, description: e.target.value});}} 
                required
              />
            </div>
          </div>
          <div className="col-12 mb-3">
            <label>Voucher Image *</label>
            <FileUpload ref={fileUploadRef} chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
              multiple accept="image/*" maxFileSize={1000000} customUpload={true} className={`${styles.fileUpload}`}
              uploadHandler={(e) => {setEditVoucher({...editVoucher, image: e});}}
            />
          </div>
        </div>
        <div className={`${styles.buttonGroup} flex justify-content-end flex-wrap`}>
          <Button label="Cancel" className={`p-button-outlined align-items-center justify-content-center m-2 ${styles.cancelButton}`} 
            onClick={()=>{window.location.href = "#/voucher/"}}
          />
          <Button label="Save Data" className={`p-button-secondary align-items-center justify-content-center m-2 ${styles.saveButton}`}
            onClick={() => {setDisplayForm(true)}}
          />
        </div>
      </div>
      <Dialog className={`${styles.confirmSaveVoucher}`} visible={displayForm} style={{ width: '30vw' }} onHide={() => setDisplayForm(false)}>
          <div className={`${styles.confirmContent}`}>
            <p>Are you sure you want to save this data into the system?</p>
          </div>
          <div className={`${styles.buttonGroup}`}>
            <Button label="Cancel" className={`p-button-outlined align-items-center justify-content-center m-2 ${styles.cancelButton}`} 
              onClick={()=>{setDisplayForm(false)}}
            />
            <Button label="Save Data" className={`align-items-center justify-content-center m-2 ${styles.saveButton}`}
              onClick={() => {HandleSubmit()}}
            />
          </div>
      </Dialog>
    </div>
  )
}