import { a, useSpring } from "@react-spring/three";
import { useAnimations, useFBX, useGLTF, useTexture } from "@react-three/drei";
import { useFrame, useGraph, useLoader } from "@react-three/fiber";
import { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { FBXLoader, SkeletonUtils } from "three-stdlib";
import * as THREE from "three";
import { Euler, Vector3 } from "three";
import { DEFAULT_ANIM_PATH, DEFAULT_ANIM_PATH_INTERFACE } from "../enum/meshes";
import _ from "lodash";

type ModelProps = {
    modelFilePath:string;
    textureFilePath?:string;
    animationFilePath?:string;
    pose?:number;
    scale?:number;
    rotation?:Euler;
    postion?:Vector3;
    [key: string]:any;
}


const CharacterModelFBX = ({modelFilePath, textureFilePath="", animationFilePath="", scale=0.02, position = new Vector3, rotation = new Euler, ...props}:ModelProps) =>{

    let fbx:THREE.Object3D = useFBX(modelFilePath);
    
    const assetSet:string = localStorage.getItem("assetSet")?.toString().toUpperCase() ?? "default"
    
    let aniKeys = Object.keys(DEFAULT_ANIM_PATH) as Array<keyof DEFAULT_ANIM_PATH_INTERFACE> 
    let animPath:string=animationFilePath;
    
    if(animPath == ""){
        aniKeys.map((i:keyof DEFAULT_ANIM_PATH_INTERFACE)=>{
            animPath= DEFAULT_ANIM_PATH[i == assetSet ? i : 'default']
        })
    }
    // const texture:THREE.Texture = useTexture(textureFilePath)
    // let objectFbx:THREE.Object3D;
    let fbxAnimation:THREE.Object3D = useFBX(animPath)
    // }
    // const animationActions: THREE.AnimationAction[] = []
    // let { animations } = fbxAnimation;
    // let {ref, names, actions} = useAnimations(fbxAnimation.animations);
    // console.log(actions)
    // console.log(fbx.animations,fbxAnimation.animations)
    // console.log("ACT:", fbx,actions)
    const mixer = new THREE.AnimationMixer(fbx)
    
    // const ani = useAnimations(fbx.animations)
    
    // Hover and animation-index states
    const [hovered, setHovered] = useState(false)
    const [index, setIndex] = useState(0)
    
    // let [mixer, setMixer] = useState<THREE.AnimationMixer>();
    
    useEffect(() => {
        // Reset and fade in animation after an index has been changed
        // actions[names[index]]?.reset().fadeIn(0.005).play()
        // // In the clean-up phase, fade it out
        // return () => {
        //     actions[names[index]]?.fadeOut(0.5)
        // }
        fbx.traverse((child: THREE.Object3D) => {
            let mesh = child as THREE.Mesh;
            if ( mesh?.isMesh) {
                mesh.castShadow = true;
                mesh.receiveShadow = true;
                if(textureFilePath!==""){
                    let TextureLoader = new THREE.TextureLoader();
                    TextureLoader.load(textureFilePath,(texture)=>{
                        mesh.material = new THREE.MeshBasicMaterial({map:texture}); 
                    })
                //     mesh.material = new THREE.MeshBasicMaterial({map:texture});
                }
            }
        })

    // fbx.scale.setScalar(0.01);
    // fbx.position.set(-0.05, -1, 0);
    }, [fbx])
    useFrame((state, delta) => {
        mixer.update(delta);
        // console.log(ca);
    });

    useEffect(() => {
        (()=>{
            // if(fbxAnimation){
                // let animation = useAnimations(animations, fbx);
                // let test = ;
                // console.log(test.getClip())
                // test.play()
                mixer.clipAction(fbxAnimation.animations[0]).reset().play()
                // console.log("ASDASD", mixer)
                // Reset and fade in animation after an index has been changed
                // actions["mixamo.com"]!.reset()?.fadeIn(0.5)?.play();
                // In the clean-up phase, fade it out
                return () => mixer.clipAction(fbxAnimation.animations[0]).fadeOut(0.5)
            // }
        })()
        // return()=>{ actions["mixamo.com"]!.fadeOut(0.5)}
        // console.log(actions["mixamo.com"])
    },[fbxAnimation,mixer])

    return(
        <group 
        // ref={ref} 
        scale={scale}
        position={position}
        rotation={rotation}
        {...props} 
        dispose={null}>
            {/* <primitive object={fbx} scale={0.01}/> */}
            <group
                onPointerOver={() => setHovered(true)}
                onPointerOut={() => setHovered(false)}
                // onClick={() => setIndex((index + 1) % names.length)}
                // rotation={[Math.PI / 2, 0, 0]}
                >
                    
                <primitive
                // ref={ref} 
                object={fbx} />
            </group>
            
        </group>
    )
}

export default memo(CharacterModelFBX);