import { faCircle, faCircleDot, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import SpacesService from "../../service/SpacesService";
import Filter from "../form/Filter";
import Search from "../form/Search";
import styles from './Gaming.module.scss'
import globalStyles from '../pageStyle.module.scss';
import tableStyles from '../tableStyle.module.scss';

const LeaderBoard = () => {
  const accessToken = localStorage.getItem("token")
  const realmId = localStorage.getItem("realmId")
  const spacesService = new SpacesService();
  
  const toast = useRef<Toast>(null);

  const [selectedFilter, setSelectedFilter] = useState()
  const [visibleFilter, setVisibleFilter] = useState(false)
  const [selectedSearch, setSelectedSearch] = useState('')
  const [selectedEntries, setSelectedEntries] = useState(10)
  const EntriesOptions = [
    10,
    50,
    100,
  ]
  const filterOptions = [
    {
      label:"Test1",
      value:'ClassRoom',
    },
    {
      label:"Test2",
      value:'Gallery',
    },
  ]

  const [gameList, setGameList] = useState([])  

  //loading data into gamesInfo
  useEffect(() => {
    
  },[])

  // call search function to update the data
  useEffect(()=>{
    // search function
  },[selectedSearch])

  const onClear = () => {
    setSelectedFilter(undefined)
    setSelectedSearch('')
  }

  const renderHeaderTable = () => {
    return (
        <div className="flex flex-wrap align-content-center justify-content-between align-items-center">
          <div className={`col flex flex-wrap align-items-center align-content-center`}>
            <p className={`flex-initial flex align-items-center justify-content-center align-content-center ${tableStyles.entries}`}>
              Show
              <Dropdown className={`mx-1 flex-initial flex align-items-center justify-content-center align-content-center `} options={EntriesOptions} value={selectedEntries} onChange={(e:{value:any})=>setSelectedEntries(e.value)}/>
              Entries
            </p>
          </div>
          <Search onSearch={setSelectedSearch} searchValue={selectedSearch}/>
        </div>
    )
  }

  return(
    <>
      <div className={`${globalStyles.header}`}>
        <div>
          <h1>Gamification</h1>
          <p>you can manage all gamification data in this page easier</p>
        </div>
      </div>
      <div className={`${globalStyles.bodyWrapper}`}>
        <div className={`card ${globalStyles.content}`}>
          <Toast ref={toast}/>
          <Filter
            fields={
              {
                selectedValue:selectedFilter,
                setSelectedValue:setSelectedFilter,
                label:"Status",
                options:filterOptions
              }
            }
            setVisible={setVisibleFilter} visible={visibleFilter}
          />
          <div className={`flex justify-content-between flex-wrap ${styles.GameHeader}`}>
            <h3 className=" align-items-center justify-content-center font-bold m-2">Leaderboard</h3>
            <div className={`flex justify-content-between flex-wrap card-container purple-container ${styles.buttonGroup}`}>
              <Button style={{ color: 'var(--surface-0)', backgroundColor: ' #001E4A'}}type="button" label="Clear search and filter" className={`align-items-center justify-content-center m-2`}  onClick={()=>onClear()}/>
              <Button type="button" label="Filter" icon="pi pi-filter-fill"  className={`p-button-outlined align-items-center justify-content-center m-2 ${styles.button}`} onClick={()=>setVisibleFilter(true)}/>
              <Button type="button" label="Export" icon="pi pi-file" className={`p-button-outlined align-items-center justify-content-center m-2 ${styles.button}`} />
            </div>
          </div>
          <DataTable 
            header={renderHeaderTable()}
            rows={selectedEntries}
            paginator={true} 
            value={gameList}
            responsiveLayout="scroll"
            emptyMessage="No Data found."
            className={`${styles.game}`}
            paginatorClassName={`grid ${tableStyles.paginator}`}
            paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
            currentPageReportTemplate={"Showing {first} to {last} of {totalRecords}"}
            >
            <Column header= "Space" field="name" sortable/>
            <Column header= "Room" field="name" sortable/>
            <Column header= "Game" field="name" sortable/>
            <Column header= "User" field="name" sortable/>
          </DataTable>
        </div>
      </div>
    </>
  )
}

export default LeaderBoard