  import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import SpacesService from "../../service/SpacesService";
import styles from './Gaming.module.scss'
import globalStyles from '../pageStyle.module.scss';
import tableStyles from '../pageStyle.module.scss';
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";

interface gameId {
  id: string;
}

interface gameInfo {
  game_name: string;
  start_date: Date | Date[];
  end_date: Date | Date[];
}

const GameEdit = () => {
  const accessToken = localStorage.getItem("token")
  const realmId = localStorage.getItem("realmId")
  const spacesService = new SpacesService();

  const [displayForm, setDisplayForm] = useState(false);

  const [gamesInfo, setGamesInfo] = useState<Partial<gameInfo>>({})
  const [updatedGamesInfo, setUpdatedGamesInfo] = useState<Partial<gameInfo>>({})

  const confirm = () => {
    confirmDialog({
      message: 'Are you sure you want to edit this data into the system?',
      position: 'top',
      acceptLabel: 'Edit Data',
      rejectLabel: 'Cancel',
      accept: handleSubmit,
      reject: () => console.log("rejected")
    });
  }

  const handleSubmit = () => {
    //Submiting data from form
    console.log('Submited')
  }

  //loading data into gamesInfo using game ID
  useEffect(() => {
  
  },[])

  return(
    <>
      <div className={`${globalStyles.header}`}>
        <div>
          <h1>Gamification</h1>
          <p>you can manage all gamification data in this page easier</p>
        </div>
      </div>
      <div className={`${globalStyles.bodyWrapper}`}>
        <div className={`card ${globalStyles.content}`}>
          <div className={`flex justify-content-between flex-wrap ${styles.gameHeader}`}>
            <h3 className=" align-items-center justify-content-center font-bold m-2">Edit Game</h3>
            <div className={`flex justify-content-between flex-wrap card-container purple-container ${styles.buttonGroup}`}>
              <Button type="button" label="Back to Game List" icon="pi pi-file" className={`p-button-outlined align-items-center justify-content-center m-2 ${styles.button}`} onClick={(e)=>{window.location.href=`/#/game_edit/`}} />
            </div>
          </div>
          <form className={`${styles.gameEditBody}`} onSubmit={confirm}>
            <div className={`flex flex-column ${styles.formField}`}>
              <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
                <label className="p-label block">Game Name *</label>
                <div className="p-inputgroup">
                  <InputText placeholder="eg: PacMan" className={`${styles.inputText}`} required id="spacename" value={updatedGamesInfo.game_name} onChange={(e) => setUpdatedGamesInfo({...updatedGamesInfo, game_name: e.target.value})}/>
                </div>
              </div>
            </div>
            <div className={`${styles.calenderGroup}`}>
              <div className="grid">
                <div className={`col ${styles.calender} `}>
                  <label className="p-label block">Start Date *</label>
                  <div className="p-inputgroup pt-2">
                    <Calendar  id="basic" value={updatedGamesInfo.start_date} onChange={(e) => setUpdatedGamesInfo({...updatedGamesInfo, start_date:e.target.value ?? new Date()})}/>
                  </div>
                </div>
                <div className={`col ${styles.calender} `}>
                  <label className="p-label block">End Date *</label>
                  <div className="p-inputgroup pt-2">
                    <Calendar id="basic" value={updatedGamesInfo.end_date} onChange={(e) => setUpdatedGamesInfo({...updatedGamesInfo, end_date:e.target.value ?? new Date()})}/>
                  </div>
                </div>
              </div>
            </div>
            <div className={`flex justify-content-end flex-wrap pt-5 ${styles.saveButton}`}> 
              <Button type="submit" label="Save Data" className={` ${styles.button}`} />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default GameEdit