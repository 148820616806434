import Axios, { AxiosResponse } from "axios";

export const axiosClient = Axios.create();

axiosClient.interceptors.request.use(
    config => {
        const accessToken = localStorage.getItem('token');
        const realmId = localStorage.getItem('realmId');
        if (accessToken) {
            config.headers.common = {
                'Authorization': `Bearer ${accessToken}`,
                'x-api-token': 'Bearer ' + accessToken,
            };
            if (realmId)
                config.headers.common['x-realm-id'] = realmId
        }
        return config;
    },
    error => {
        Promise.reject(error.response || error.message);
    }
);

axiosClient.interceptors.response.use(
    (response: AxiosResponse) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
    }
);

