import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import tableStyles from '../tableStyle.module.scss';
import styles from './VoucherItemsList.module.scss';
import { useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";

interface RouteParams { code: string };

export const VoucherItemsList = () => {
  const [couponItems, setCouponItems] = useState<Array<string>>([])
  const [codeString, setCodeString] = useState<string>()
  const [displayForm, setDisplayForm] = useState(false);
  const toast = useRef<Toast>(null);
  let route = useParams<RouteParams>();
  
  useEffect(() => {
    //load coupon item List
  })

  const [selectedEntries, setSelectedEntries] = useState(10);
  const EntriesOptions = [
    10,
    50,
    100,
  ]

  const renderHeaderTable = () => {
    return (
        <div className="flex flex-wrap align-content-center justify-content-between align-items-center">
          <div className={`col flex flex-wrap align-items-center align-content-center`}>
            <p className={`flex-initial flex align-items-center justify-content-center align-content-center ${tableStyles.entries}`}>
              Show
              <Dropdown className={`mx-1 flex-initial flex align-items-center justify-content-center align-content-center `} options={EntriesOptions} value={selectedEntries} onChange={(e:{value:any})=>setSelectedEntries(e.value)}/>
              Entries
            </p>
          </div>
        </div>
    )
  }

  const footer = (
    <div>
        <Button type="submit" label="Submit" icon="pi pi-check" onClick={() => {HandleSplitString(codeString!)}} />
        <Button label="Cancel" icon="pi pi-times" onClick={() => setDisplayForm(false)} />
    </div>
  );

  const HandleSplitString = (string: string) => {
    // update code List for existing coupon
    setCouponItems(string.split("\n"))
    setDisplayForm(false)
  }


  return (
    <div className={`card `}>
      <Toast ref={toast}/>
      <div className={`flex justify-content-between flex-wrap ${styles.header}`}>
        <h3 className=" align-items-center justify-content-center font-bold m-2">Code List</h3>
        
        <div className={`flex justify-content-between flex-wrap card-container purple-container ${styles.buttonGroup}`}>
          <Button  label="Import code" icon="pi pi-plus" iconPos="right" onClick={() => {setDisplayForm(true)}}/>
        </div>
      </div>
      <DataTable 
        header={renderHeaderTable()}
        rows={selectedEntries}
        paginator={true} 
        value={couponItems}
        responsiveLayout="scroll"
        emptyMessage="No Code Availabel."
        className={`${styles.spaces}`}
        paginatorClassName={`grid ${tableStyles.paginator}`}
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate={"Showing {first} to {last} of {totalRecords}"}
        >
        <Column header="Code" body={(items) => { return (items)}}/>
      </DataTable>
      <Dialog header="Add new Coupon" visible={displayForm} style={{ width: '50vw' }} footer={footer} onHide={() => setDisplayForm(false)}>
        <div className={`${styles.addCouponForm}`}>
          <div className={`flex flex-column ${styles.formContainer}`}>
            <div className={`field align-items-center justify-content-center my-3 ${styles.inputField}`}>
              <label className="p-label block">Input Code*</label>
              <div className="p-inputgroup">
                <InputTextarea rows={15} cols={30} value={codeString} onChange={(e) => {setCodeString(e.target.value)}} />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
