
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import CustomerService from "../service/CustomerService";
import './SpacesManagement.module.scss';
import globalStyles from './pageStyle.module.scss';
import tableStyles from './tableStyle.module.scss';
import '../../node_modules/primeflex/primeflex.css'
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { UserForm } from "./enum/userForm";
import { ViewUsers_Table } from "./items/UsersTable";
import { RealmsUsersInterface } from "./enum/realmInterface";
import { instanceOfUserForm } from "../utilities/Functions";
import { ExportToFileComponent } from "./items/ExportToFile";

const UsersManagement = () => {
  const accessToken = localStorage.getItem("token")
  const customersService = new CustomerService();
  const [customerInfo, setCustomerInfo] = useState<Array<UserForm>>([])
  const toast = useRef<Toast>(null);
  const filterOptions = [
    {
      label: "Unverified Email",
      value: false,
    },
    {
      label: "Verified Email",
      value: true,
    },
  ]
  const [isDisplayed, setIsDisplayed] = useState(false)
  const [selectedSearch, setSelectedSearch] = useState('')

  const EntriesOptions = [
    10,
    50,
    100,
  ]
  const [selectedEntries, setSelectedEntries] = useState(10)
  const [visibleFilter, setVisibleFilter] = useState(false)
  const realmId = localStorage.getItem('realmId')
  
  const setUserInfo = (dataRes: Array<RealmsUsersInterface>) =>{
    let dataset:Array<UserForm> = []
    dataRes.map((info:RealmsUsersInterface)=>{
      if(instanceOfUserForm(info.user)){
        dataset.push(info.user)
      }
    })
    setCustomerInfo(dataset)
  }

  useEffect(() => {
    if (accessToken){
        customersService.getCustomersList(realmId!).then((res)=>{
          // setCustomerInfo(res)
          setUserInfo(res)
        });
    }
  },[])

  const onClear = () => {
    // setSelectedFilter(undefined)
    setSelectedSearch('')
  }

  useEffect(()=>{
    if(selectedSearch!=''){
      customersService.searchUsersInRealm(selectedSearch, realmId!).then(res=>{
        setUserInfo(res)
      });
    }else{
      customersService.getCustomersList(realmId!).then((res)=>{
        setUserInfo(res)
      });
    }
  },[selectedSearch])

  const deleteUser =(id: string) =>{
    customersService.deleteUserInRealm(realmId!, id).then((res)=>{
      if(!(res?.status?.code)){
        customersService.getCustomersList(realmId!).then((res)=>{
          setUserInfo(res)
        });
      }
      confirmDialog({
        message:res?.status?.message || "Remove Successfully",
        icon: 'pi pi-exclamation-triangle',
        position:'top',
        acceptClassName:'hidden',
        rejectLabel:"Ok",
      })
    }).catch(err=>{
      console.log("Error when remove user",err)

      confirmDialog({
        message: "An Error occurred when remove user. Please try again later.",
        icon: 'pi pi-exclamation-triangle',
        position:'top',
        acceptClassName:'hidden',
        rejectLabel:"Ok",
      })
    })
  }

  const showDeleteUserAlert = (id: string) => {
        confirmDialog({
            message: 'Do you want to remove this user?',
            icon: 'pi pi-exclamation-triangle',
            position:'top',
            className:`${tableStyles.dialog}`,
            acceptLabel:'Delete Data',
            rejectLabel:'Cancel',
            accept:()=>{
              deleteUser(id)
            }
        });
    };
  
  return(
    <>
      <div className={`grid ${globalStyles.header}`}>
        <ConfirmDialog className={`${tableStyles.dialog}`}/>
        <div className={`col-12 sm:col-3 pr-8`}>
          <h1>Users</h1>
          <p>You can manage all the user data in this page easier.</p>
        </div>
        <div className={`col-12 flex flex-wrap align-items-center justify-content-end`}>
          <Button onClick={()=>{window.location.href = '/#/user/create'}} label="New User" icon="pi pi-plus" iconPos="left" className={`p-button-raised p-button-text px-3 py-3 ${globalStyles.button}`}/>
        </div>
      </div>
      <div className={`col-12 ${globalStyles.bodyWrapper}`}>
        <div className={`card ${globalStyles.content}`}>
          <Toast ref={toast}/>
          <div className={`flex justify-content-between flex-wrap ${tableStyles.header}`}>
            <h3 className=" align-items-center justify-content-center font-bold m-2">Users List</h3>
            <div className={`flex justify-content-between flex-wrap card-container purple-container ${tableStyles.buttonGroup}`}>
              <Button style={{ color: 'var(--surface-0)', backgroundColor: ' #001E4A'}} type="button" label="Clear search" className={`align-items-center justify-content-center m-2`} onClick={()=>onClear()}/>
              <Button type="button" label="Export" icon="pi pi-file" className={`p-button-outlined align-items-center justify-content-center m-2 ${tableStyles.button}`} 
              onClick={()=>{
                setIsDisplayed(true)
              }}/>
              {customerInfo && <ExportToFileComponent info={customerInfo} isDisplayed={isDisplayed} setIsDisplayed={(value:any)=>setIsDisplayed(value)}/>}
            </div>
          </div>
          <ViewUsers_Table datas={customerInfo} deleteInfo={showDeleteUserAlert} selectedSearch={selectedSearch} setSelectedSearch={setSelectedSearch}/>
        </div>
      </div>
    </>
  )
}

export default UsersManagement
