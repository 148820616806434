import axios from "axios";
import { API_BASE_URL } from "../constants";
import { axiosClient } from "../utilities/CustomAxios";
import { SpaceForm } from "../components/enum/SpaceInterface";
export default class SpacesService {
  getSpacesInfo(token: String |string) {
    return axios.get(`${API_BASE_URL}/spaces`,
    { headers: {"Authorization" : `Bearer ${token}`} }
    ).then(res => res.data)
  }

  getSpacesInfoByRealm(token: String |string, realmId: String |string) {
    return axios.get(`${API_BASE_URL}/realms/${realmId}/spaces`,
    { headers: {"Authorization" : `Bearer ${token}`} }
    ).then(res => res.data)
  }

  getBuildingInfo(token: String) {
    return axios.get(`${API_BASE_URL}/buildings`,
    { headers: {"Authorization" : `Bearer ${token}`} }
    ).then(res => res.data)
  }

  searchSpaces(searchStr: string){
    return axiosClient.get(`${API_BASE_URL}/spaces/search/${searchStr}`).then(res=>{return res.data});
  }

  searchSpacesInRealm(searchStr: string, realmId: String |string){
    return axiosClient.get(`${API_BASE_URL}/realms/${realmId}/spaces/search/${searchStr}`).then(res=>{return res.data});
  }


  creatNewSpace(spaceData: Partial<SpaceForm>, userid: string){
    return axiosClient.post(`${API_BASE_URL}/spaces/save`,
    {
      "userId": userid,
      "name": spaceData.space_name,
      "description": spaceData.description,
      "building": spaceData.room,
      "code": spaceData.room_code
    } 
    ).then(res => res.data)
  }

  updateSpace(spaceData: Partial<SpaceForm>, spaceid: string){
    return axiosClient.put(`${API_BASE_URL}/spaces/update`,
    {
      "spaceId": spaceid,
      "name": spaceData.space_name,
      "description": spaceData.description,
      "roomID": spaceData.room_id,
      "postProcessing": spaceData.post_processing,
      "isPrivate": spaceData.is_private,
      "loginRequired": spaceData.login_required,
      "chatLoginRequired": spaceData.chat_login_required,
      "callLoginRequired": spaceData.call_login_required,
      "npcs": spaceData.npcs
    } 
    ).then(res => res.data)
  }

  getSpaceById(id: string) {
    return axiosClient.get(`${API_BASE_URL}/spaces/${id}`)
    .then(res => res.data)
  }

  deleteSpaceById(id: string){
    return axiosClient.delete(`${API_BASE_URL}/spaces/delete/${id}`)
    .then(res => console.log(res))
  }

  getUserSpaceById(spaceid: string){
    return axiosClient.get(`${API_BASE_URL}/user/space/${spaceid}`,{


    }).then(res => res.data)
  }

  getSpaceInstanceByInstanceID(instanceId:string){
    return axiosClient.get(`${API_BASE_URL}/instances/${instanceId}`).then(res => res.data)
  }
}