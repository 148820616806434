import moment from "moment"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Dropdown } from "primereact/dropdown"
import { SplitButton } from "primereact/splitbutton"
import React, { useState } from "react"
import { linkedUserInterface } from "../enum/SpaceUserInterface"
import { UserForm } from "../enum/userForm"
import Search from "../form/Search"
import tableStyles from '../tableStyle.module.scss';
interface tableUserInterface {
    datas: Array<UserForm>,
    deleteInfo: Function,
    selectedSearch: string,
    setSelectedSearch: Function
}

export const ViewUsers_Table = ({datas, deleteInfo, selectedSearch = "", setSelectedSearch}:tableUserInterface) =>{

    // const [selectedSearch, setSelectedSearch] = useState('')
    const [selectedEntries, setSelectedEntries] = useState(10)

    const EntriesOptions = [
        10,
        50,
        100,
    ]

    const renderHeaderTable = () => {
        return (
            <div className="flex flex-wrap align-content-center justify-content-between align-items-center">
              <div className={`col flex flex-wrap align-items-center align-content-center`}>
                <p className={`flex-initial flex align-items-center justify-content-center align-content-center ${tableStyles.entries}`}>
                  Show
                  <Dropdown className={`mx-1 flex-initial flex align-items-center justify-content-center align-content-center `} options={EntriesOptions} value={selectedEntries} onChange={(e:{value:any})=>setSelectedEntries(e.value)}/>
                  Entries
                </p>
              </div>
              <Search onSearch={setSelectedSearch} searchValue={selectedSearch}/>
            </div>
        )
    }

    const deleteUser = (id:string) =>{
        deleteInfo(id)
    }

    return(
        <React.Fragment>
            <DataTable
            removableSort={true}
            header={renderHeaderTable()}
            rows={selectedEntries}
            paginator={true}
            value={datas}
            responsiveLayout="scroll"
            className={`spaces ${tableStyles.table}`}
            emptyMessage="No Users found."
            paginatorClassName={`grid ${tableStyles.paginator}`}
            paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
            currentPageReportTemplate={"Showing {first} to {last} of {totalRecords}"}
            >
            <Column header= "Username" field="username" sortable/>
            <Column header= "First Name" field="firstName" sortable/>
            <Column header= "Last Name" field="lastName" sortable/>
            <Column header= "E-mail" field="email" sortable/>
            <Column header="" style={{textAlign: 'center'}} body={(item) => {
                const actionItems = [
                {
                    label: 'Remove',
                    command:()=>{
                        deleteUser(item.id)
                    }
                }
                ]
                return (
                <SplitButton model={actionItems} label="View" className={`mr-2 mb-2 ${tableStyles.btnContainer}`} menuButtonClassName={`p-button-text`} buttonClassName={`p-button-text`} onClick={()=>{window.location.href=`/#/user/${item.id}`}}/>
            )}
            }/>
            </DataTable>
        </React.Fragment>
    )
}