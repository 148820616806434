import React, { useLayoutEffect } from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"
import PermissionsService from "../service/PermissionsService"

// interface Props extends RouteProps {
//   isAuthenticate: boolean;
// }

const PermissionRoute = ({...routeProps}) => {

    const permissionsService = new PermissionsService();
    return <Route {...routeProps} />
}
  

export default PermissionRoute