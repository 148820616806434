import { Button } from "primereact/button";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SpacesService from "../../service/SpacesService";
import { ViewItems } from "../items/ViewItems";
import { EditSpace } from "./editSpace";
import styles from "./spaceEditFrame.module.scss"
import { Space3DView } from "./Space3DView";
import { ViewFAQs } from "./ViewFAQs";
import { ViewRegisterUser } from "./viewRegisterUser";
import globalStyles from '../pageStyle.module.scss';

interface spaceInfo {
  space_name: string,
  sub_space_name: string,
  status: string,
  banner: object,
}

interface RouteParams {id: string}

export const EditFrame = () => {
  const [editIndex, setEditIndex] = React.useState("space")
  let {id} = useParams<RouteParams>();

  return (<>
    <div className={`${globalStyles.header} ${styles.header}`}>
        <div className={`col`}>
          <h1>Spaces</h1>
          <p>You can manage all the space data in this page easier.</p>
        </div>
      </div>
    <div className={`flex flex-column ${styles.editFrame} ${globalStyles.editBodyWrapper}`}>
      <div className={`card ${styles.editCard} ${globalStyles.content}`}>
        <div className={`flex justify-content-between flex-wrap ${styles.header}`}>
          <div className={`card flex justify-content-center flex-wrap ${styles.buttonGroup} `}>
            <Button type='submit' label="Space" 
              className={`align-items-center justify-content-center m-2 ${styles.headerButton}`}
              onClick={()=>setEditIndex("space")}/>
            <Button type='submit' label="Tutorial" 
              className={`align-items-center justify-content-center m-2 ${styles.headerButton}`}
              onClick={()=>setEditIndex("tutorial")}/>
            {/* <Button type='submit' label="Registered User" 
              className={`align-items-center justify-content-center m-2 ${styles.headerButton}`}
              onClick={()=>setEditIndex("registered_user")}/> */}
            <Button type='submit' label="Items" 
              className={`align-items-center justify-content-center m-2 ${styles.headerButton}`}
              onClick={()=>setEditIndex("items")}/>
              <Button type='submit' label="FAQ" 
                className={`align-items-center justify-content-center m-2 ${styles.headerButton}`}
                onClick={()=>setEditIndex("faq")}/>
              <Button type='submit' label="3D" 
                className={`align-items-center justify-content-center m-2 ${styles.headerButton}`}
                onClick={()=>setEditIndex("space_view")}
              />
          </div>
          <Button type="button" label="Back to space list" className={`align-items-center justify-content-center ${styles.returnButton}`} 
            onClick={()=>{window.location.href = "#/spaces-management/"}}
          />
        </div>
        <div className={`${styles.editCardContent}`}>
          {editIndex == "space" && <EditSpace id={id}/>}
          {/* {editIndex == "registered_user" && <ViewRegisterUser spaceId={id} setUserId={()=>{}} setIsEdit={()=>{}}/>} */}
          {/* {editIndex == "items" && <ViewItems spaceId={id} setUserId={()=>{}} setIsEdit={()=>{}}/>} */}
          {editIndex == "faq" && <ViewFAQs spaceID={id}/>}
          {editIndex == "space_view" && <Space3DView id={id}/>}
        </div>
      </div>
    </div>
    </>
  )
}