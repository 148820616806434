import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import styles from "./filter.module.scss";

type filterValue = {
    label: String,
    value: any,
}
type filterField = {
    options:filterValue[],
    selectedValue:any,
    setSelectedValue: Function,
    label:String,
}
type filterProps = {
    // options: filterValue[],
    // selectedValue:any,
    // setSelectedValue: Function,
    fields: filterField[]|filterField,
    visible: boolean,
    setVisible:Function,
}
const Filter = ({fields, /*options, selectedValue, setSelectedValue, */ visible, setVisible}:filterProps)=>{
    
    const renderFooter = () => {
        return (
            <div>
                <Button className={`p-button-text`} label="Cancel" onClick={() => onHideCancel()} />
                <Button label="Apply" onClick={() => onHide()} autoFocus />
            </div>
        );
    }
    const onChange = (field:filterField, value:any) => {
        field.setSelectedValue(value)
    };

    const onHideCancel = ()=>{
        onHide();
        if(Array.isArray(fields)){
            fields.map(i=>i.setSelectedValue({
                label:"",
                value:"",
            }))
        }else{
            fields.setSelectedValue({
                label:"",
                value:"",
            })
        }
        // if(fields?.length && fields.length >0)
        // if(typeof fields == 'object'){
        //     fields.setSelectedValue({
        //         label:"",
        //         value:"",
        //     })
        // }else if(fields?.length>0){
        //     fields.map(i=>i.setSelectedValue({
        //         label:"",
        //         value:"",
        //     }))
        // }
        // setSelectedValue({
        //     label: "",
        //     value: "",
        // })
    };

    const onHide = ()=>{
        setVisible( false );
    }
    return(
        <Dialog className={`${styles.dialog}`} header={'Filter'} visible={visible} position="top" modal={true} draggable={false} resizable={false} onHide={()=>{onHideCancel()}} footer={renderFooter()} style={{width:'50vw'}}>
            { Array.isArray(fields) ? fields?.length> 0 && fields.map(i=>{
                return(
                <div>
                    <div>
                        <h6>{i.label}</h6>
                    </div>
                    <Dropdown placeholder={`Select ${i.label}`} style={{width:'100%', marginBottom:20}} value={i.selectedValue} options={i.options} onChange={(e:{value:any})=>onChange(i,e.value)} filterBy="label" filter showClear />
                </div>
                )
            }):
                <div>
                    <div>
                        <h6>{fields.label}</h6>
                    </div>
                    <Dropdown placeholder={`Select ${fields.label}`} style={{width:'100%', marginBottom:20}} value={fields.selectedValue} options={fields.options} onChange={(e:{value:any})=>onChange(fields,e.value)} filterBy="label" filter showClear />
                </div>
            }
        </Dialog>
    )
}

export default Filter;